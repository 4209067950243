import {
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  loading: false,
  onboarding: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchData({ commit }) {
    try {
      const resOnboarding = await this.$axios.$get('/onboarding/')
      commit('SET_ONBOARDING', resOnboarding.data)
      return resOnboarding
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload)
  }
}
