var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto flex w-full max-w-[360px] transform items-center overflow-hidden rounded border-2 p-[12px] text-white shadow-lg shadow-md",class:{
    'border-red-600 bg-red-50': _vm.color === 'danger',
    'border-green-200 bg-green-50': _vm.color === 'success',
    'border-yellow-500 bg-yellow-50': _vm.color === 'warning',
    'border-primary-900 bg-blue': _vm.color === 'info',
    'bg-gray-150 border-gray-400': !_vm.color,
    'cursor-pointer': _vm.callback
  },on:{"click":function($event){_vm.callback ? _vm.execCallback() : null}}},[_c('div',{staticClass:"rounded p-[6px]",class:{
      'bg-red': _vm.color === 'danger',
      'bg-green-400': _vm.color === 'success',
      'bg-yellow-400': _vm.color === 'warning',
      'bg-blue-400': _vm.color === 'info',
      'bg-gray-400': !_vm.color
    }},[(_vm.color === 'danger')?_c('SclvIcon',{staticClass:"text-[32px]",attrs:{"icon":"ic-toast-danger"}}):(_vm.color === 'success')?_c('SclvIcon',{staticClass:"text-[32px]",attrs:{"icon":"ic-toast-success"}}):(_vm.color === 'warning' || _vm.color === 'info')?_c('SclvIcon',{staticClass:"text-[32px]",attrs:{"icon":"ic-toast-info"}}):_c('SclvIcon',{staticClass:"text-black-200 text-[32px]",attrs:{"icon":"ic-toast-general"}})],1),_vm._v(" "),_c('div',{staticClass:"text-black-950 flex flex-1 items-center px-[16px]"},[_c('p',{staticClass:"whitespace-pre-line"},[_vm._v(_vm._s(_vm.message))])]),_vm._v(" "),_c('SclvButton',{staticClass:"text-[24px]",attrs:{"text":""},on:{"click":_vm.close}},[_c('SclvIcon',{attrs:{"icon":"times"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }