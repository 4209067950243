class Base64Encoder {
  constructor(salt = 'asecret') {
    this.salt = salt
  }

  encode(id) {
    const saltedId = id.toString() + this.salt
    return Buffer.from(saltedId)
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '')
  }

  decode(encoded) {
    const base64 = encoded.replace(/-/g, '+').replace(/_/g, '/')

    const decoded = Buffer.from(base64, 'base64').toString('utf-8')
    const unsalted = decoded.slice(0, -this.salt.length)
    return parseInt(unsalted, 10)
  }
}

export default new Base64Encoder('iyJEDNMzhAI')
