import { render, staticRenderFns } from "./NavbarMore.vue?vue&type=template&id=274f8389"
import script from "./NavbarMore.vue?vue&type=script&lang=js"
export * from "./NavbarMore.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__ejs@3._54tixbz6jkcfk44fmedkayj5tu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SclvIcon: require('/app/components/common/SclvIcon.vue').default})
