import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  loading: false,
  shipment: [],
  metaShipment: {},
  retrieveShipment: [],
  courier: [],
  courierServices: [],
  paymentMethod: [],
  shipmentStatuses: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { isFirst, ...query } = { isFirst: true }) {
    if (isFirst) {
      commit('SET_SHIPMENT', [])
    }
    const { page, pageSize, itemsLength } = state.metaShipment
    if (itemsLength === state.shipment?.length) {
      return state.shipment
    }
    const initQuery = initQueryParams({
      page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/shipment/?${queries}`
    try {
      const resShipment = await this.$axios.$get(url)
      const allShipment = [...state.shipment, ...resShipment.data.results]
      commit('SET_SHIPMENT', allShipment)
      const newMeta = decodeQueryData(resShipment.data.next?.split('?')?.[1])
      if (newMeta) {
        commit('SET_META_SHIPMENT', {
          itemsLength:
            resShipment.data.count || resShipment.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return allShipment
    } catch (error) {
      // console.error(error)
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  setShipment({ commit }, payload) {
    commit('SET_SHIPMENT', payload)
  },
  async fetchKillPagination({ commit }) {
    commit('SET_LOADING', true)
    const url = `/shipment/`
    try {
      const resShipmentList = await this.$axios.$get(url)
      commit('SET_SHIPMENT', [])
      commit('SET_META_SHIPMENT', {
        page: 1
      })
      return resShipmentList.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async retrieve({ commit }, idShipment) {
    commit('SET_LOADING', true)
    const url = `/shipment/${idShipment}/`
    try {
      const resShipmentList = await this.$axios.$get(url)
      commit('SET_RETRIEVE_SHIPMENT', resShipmentList.data)
      return resShipmentList.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchCourier({ commit }) {
    commit('SET_LOADING', true)
    const url = `/courier/`
    try {
      const resCourierList = await this.$axios.$get(url)
      commit('SET_COURIER', resCourierList.data.results)
      return resCourierList.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchCourierExpand({ commit }) {
    commit('SET_LOADING', true)
    const url = `/courier/expanded/?page_size=100`
    try {
      const resCourierList = await this.$axios.$get(url)
      commit('SET_COURIER', resCourierList.data.results)
      return resCourierList.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchPaymentMethodCod({ commit }) {
    commit('SET_LOADING', true)
    const url = `/payment/?method=cod`
    try {
      const resPaymentMethodList = await this.$axios.$get(url)
      commit('SET_PAYMENT_METHOD', resPaymentMethodList.data.results)
      return resPaymentMethodList.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchShipmentStatusByCourier({ commit }, courierId) {
    commit('SET_LOADING', true)
    const url = `/courier/${courierId}/shipment-status/`
    try {
      const resCourierList = await this.$axios.$get(url)
      commit('SET_SHIPMENT_STATUSES', resCourierList.data.shipment_status)
      return resCourierList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async getShippingCostList(_store, { ...payload }) {
    const { warehouse, destination, couriers, weight } = payload
    try {
      const url = `${this.$config.apiUrlV2}/shipping-cost/`
      return await this.$axios.$post(url, {
        warehouse_id: warehouse,
        location_id: destination,
        courier_codes: couriers,
        weight
      })
    } catch (error) {
      // console.error(error)
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async add({ commit }, payload) {
    commit('SET_LOADING', true)
    const url = `/shipment/`
    try {
      const {
        courier,
        services,
        isCod,
        isApi,
        jneApiKey,
        jneCustNo,
        jneCustNoCod,
        jneJtrCustNo,
        jneJtrCustNoCod,
        jneUsername,
        jntUsername,
        jntOrderApiKey,
        jntOrderKey,
        jntTrackKey,
        ninjaClientId,
        ninjaClientSecret,
        ninjaPrefix,
        codPaymentAccount,
        codServices,
        codFee,
        shipmentCustomers
      } = payload
      const resAddShipment = await this.$axios.$post(url, {
        courier,
        services,
        is_allow_cod: isCod,
        is_api: isApi,
        jne_api_key: jneApiKey,
        jne_cust_no: jneCustNo,
        jne_cust_no_cod: jneCustNoCod,
        jne_jtr_cust_no: jneJtrCustNo,
        jne_jtr_cust_no_cod: jneJtrCustNoCod,
        jne_username: jneUsername,
        jnt_username: jntUsername,
        jnt_order_api_key: jntOrderApiKey,
        jnt_order_key: jntOrderKey,
        jnt_track_key: jntTrackKey,
        ninja_client_id: ninjaClientId,
        ninja_client_secret: ninjaClientSecret,
        ninja_prefix: ninjaPrefix,
        cod_payment_account: codPaymentAccount,
        cod_services: codServices,
        cod_fee: codFee,
        shipment_customers: shipmentCustomers
      })
      return resAddShipment
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async edit({ commit }, { shipmentId, ...payload }) {
    commit('SET_LOADING', true)
    const url = `/shipment/${shipmentId}/`
    try {
      const {
        courier,
        services,
        isCod,
        isApi,
        jneApiKey,
        jneCustNo,
        jneCustNoCod,
        jneJtrCustNo,
        jneJtrCustNoCod,
        jneUsername,
        jntUsername,
        jntOrderApiKey,
        jntOrderKey,
        jntTrackKey,
        ninjaClientId,
        ninjaClientSecret,
        ninjaPrefix,
        codPaymentAccount,
        codServices,
        codFee,
        shipmentCustomers
      } = payload
      const resEditShipment = await this.$axios.$patch(url, {
        courier,
        services,
        is_allow_cod: isCod,
        is_api: isApi,
        jne_api_key: jneApiKey,
        jne_cust_no: jneCustNo,
        jne_cust_no_cod: jneCustNoCod,
        jne_jtr_cust_no: jneJtrCustNo,
        jne_jtr_cust_no_cod: jneJtrCustNoCod,
        jne_username: jneUsername,
        jnt_username: jntUsername,
        jnt_order_api_key: jntOrderApiKey,
        jnt_order_key: jntOrderKey,
        jnt_track_key: jntTrackKey,
        ninja_client_id: ninjaClientId,
        ninja_client_secret: ninjaClientSecret,
        ninja_prefix: ninjaPrefix,
        cod_payment_account: codPaymentAccount,
        cod_services: codServices,
        cod_fee: codFee,
        shipment_customers: shipmentCustomers
      })
      return resEditShipment
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete(store, shipmentId) {
    try {
      const resDeleteShipement = await this.$axios.$delete(
        `shipment/${shipmentId}/`
      )
      return resDeleteShipement.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
