import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  isEmpty,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  warehouse: [],
  category: [],
  inventoryHistory: [],
  metaInventoryHistory: {},
  isAllDataLoaded: false,
  loading: false,
  filter: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ state, commit, getters }, { query = {}, isFirst = false }) {
    try {
      commit('SET_LOADING', true)
      let queries = ''
      if (isFirst) {
        commit('SET_INVENTORY_HISTORY', [])
        commit('SET_META_INVENTORY_HISTORY', {})
      }
      const { page, pageSize } = state.metaInventoryHistory
      let fixedQuery = {
        page: isFirst ? 1 : page,
        page_size: isFirst ? 25 : pageSize
      }
      if (!isEmpty(query)) {
        fixedQuery = {
          ...fixedQuery,
          page: query.page || fixedQuery.page,
          page_size: query.page_size || fixedQuery.page_size,
          qty_type: query.qtyType,
          category: query.category,
          business_id: query.businessId,
          product_id: query.productId,
          variant_id: query.variantId,
          warehouse_id: query.warehouseId,
          created_since: query.createdSince,
          created_until: query.createdUntil,
          search: query.search
        }
      }
      const initQuery = initQueryParams(fixedQuery)
      queries = `?${encodeQueryData(initQuery)}`
      const url = `inventory-flow/${queries}`
      const resInventoryHistory = await this.$axios.$get(url)
      const allInventoryHistory = [
        ...state.inventoryHistory,
        ...resInventoryHistory.data.results
      ]
      commit('SET_INVENTORY_HISTORY', allInventoryHistory)
      let newMeta = {
        itemsLength:
          resInventoryHistory.data.count ||
          resInventoryHistory.data.results.length
      }
      const nextMeta = resInventoryHistory.data.next
        ? decodeQueryData(resInventoryHistory.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_INVENTORY_HISTORY', newMeta)
      return {
        totalCount: resInventoryHistory.data.count,
        currentData: allInventoryHistory
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  setIsAllDataLoaded({ commit }, value) {
    commit('SET_IS_ALL_DATA_LOADED', value)
  },
  async download(state, query) {
    try {
      let fixedQuery = ''
      if (!isEmpty(query)) {
        const qObject = {
          qty_type: query.qtyType,
          category: query.category,
          business_id: query.businessId,
          product_id: query.productId,
          variant_id: query.variantId,
          warehouse_id: query.warehouseId,
          created_since: query.createdSince,
          created_until: query.createdUntil,
          search: query.search,
          tz: query.tz
        }
        fixedQuery = `?${encodeQueryData(qObject)}`
      }
      const _url = `inventory-flow/download/${fixedQuery}`
      const resInventoryHistoryDownload = await this.$axios({
        url: _url,
        method: 'get',
        responseType: 'blob'
      })
      return resInventoryHistoryDownload
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchAllWarehouse({ commit }) {
    try {
      const res = await this.$axios.$get(`inventory-flow/warehouse/`)
      commit('SET_WAREHOUSE', res.data.results)
      return res.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async storeData(store, payload) {
    try {
      const { variant, warehouse, qty, reason, notes } = payload
      const res = await this.$axios.$post('/inventory/new/', {
        variant,
        warehouse,
        qty,
        reason,
        notes
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter)
  }
}
