import Vue from 'vue'
import VueQuillEditor, { Quill } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'emoji-picker-element'

Vue.use(VueQuillEditor)

const sizeStyle = Quill.import('attributors/style/size')
sizeStyle.whitelist = [
  '12px',
  '14px',
  '16px',
  '18px',
  '24px',
  '32px',
  '40px',
  '56px',
  '72px'
]
const parchment = Quill.import('parchment')
const parchmentConfig = {
  scope: parchment.Scope.BLOCK,
  whitelist: [
    '1.0',
    '1.2',
    '1.5',
    '1.6',
    '1.8',
    '2.001',
    '2.4',
    '2.8',
    '3.001',
    '4.001',
    '5.001'
  ]
}
const parchmentLineHeightClass = new parchment.Attributor.Class(
  'lineheight',
  'ql-line-height',
  parchmentConfig
)
const parchmentLineHeightStyle = new parchment.Attributor.Style(
  'lineheight',
  'line-height',
  parchmentConfig
)
const font = Quill.import('formats/font')
font.whitelist = [
  'system',
  'page',
  'roboto',
  'roboto-slab',
  'noto-serif',
  'playfair-display',
  'open-sans',
  'montserrat',
  'poppins',
  'alegreya-sans'
]
parchment.register(parchmentLineHeightClass)
parchment.register(parchmentLineHeightStyle)

// const customFontFamilyAttributor = new parchment.Attributor.Style(
//   'custom-family-attributor',
//   'font-family'
// )
const customSizeAttributor = new parchment.Attributor.Style(
  'custom-size-attributor',
  'font-size'
)
const customColorAttributor = new parchment.Attributor.Style(
  'custom-color-attributor',
  'color'
)

parchment.register(customSizeAttributor)
parchment.register(customColorAttributor)

const ListItemBlot = Quill.import('formats/list/item')

class CustomListItem extends ListItemBlot {
  optimize(context) {
    super.optimize(context)

    if (this.children.length >= 1) {
      const child = this.children.head
      const attributes = child?.attributes?.attributes

      if (attributes) {
        for (const key in attributes) {
          const element = attributes[key]
          const name = element.keyName
          const value = element.value(child.domNode)

          if (name === 'color') super.format('custom-color-attributor', value)
          // else if (name === 'font-family')
          //   super.format('custom-family-attributor', value)
          else if (name === 'font-size')
            super.format('custom-size-attributor', value)
        }
      } else {
        super.format('custom-color-attributor', false)
        // super.format('custom-family-attributor', false)
        super.format('custom-size-attributor', false)
      }
    }
  }
}

Quill.register(sizeStyle, true)
Quill.register(font, true)
Quill.register(CustomListItem, true)
// Quill.register('modules/emoji', quilEmoji)
