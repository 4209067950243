import {
  mapGettersHelper,
  mapMutationsHelper,
  initQueryParams,
  encodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  mailketings: [],
  mailketingsMeta: {
    page: 1,
    pageSize: 25
  },
  mailketingLists: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_MAILKETINGS', [])
      commit('SET_MAILKETINGS_META', {
        page: 1,
        pageSize: 25
      })
    }
    const { page, pageSize } = state.mailketingsMeta
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/mailketing-integration/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const allMailketings = [...state.mailketings, ...res.data.results]
      commit('SET_MAILKETINGS', allMailketings)
      const nextMeta = {
        page: page + 1,
        pageSize
      }
      commit('SET_MAILKETINGS_META', nextMeta)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/mailketing-integration/${id}`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllLists({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/mailketing-integration/${id}/lists`
      const res = await this.$axios.$get(url)
      commit('SET_MAILKETING_LISTS', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    try {
      const { identifier, apiToken } = payload
      const url = `${this.$config.apiUrlV2}/mailketing-integration/`
      const res = await this.$axios.$post(url, {
        identifier,
        api_token: apiToken
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    try {
      const { id, identifier, apiToken } = payload
      const url = `${this.$config.apiUrlV2}/mailketing-integration/${id}`
      const res = await this.$axios.$patch(url, {
        identifier,
        api_token: apiToken
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async sync({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/mailketing-integration/${id}/sync`
      const res = await this.$axios.$patch(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/mailketing-integration/${id}`
      await this.$axios.$delete(url)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
