import {
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  warehouses: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async search({ commit }, payload) {
    const {
      order,
      variants,
      weightBump,
      store,
      paymentMethod,
      destination,
      cancelToken
    } = payload
    try {
      const resSearch = await this.$axios.$post(
        '/order/asearch-courier-service/',
        {
          order,
          variants,
          store,
          weight_bump: weightBump,
          payment_method: paymentMethod,
          destination
        },
        {
          cancelToken
        }
      )
      commit('SET_WAREHOUSES', resSearch.data)
      return resSearch
    } catch (error) {
      if (error.response?.status === 404) {
        commit('SET_WAREHOUSES', [])
      }
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  resetWarehouses({ commit }) {
    commit('SET_WAREHOUSES', [])
  }
}
