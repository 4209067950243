const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['business'] = require('../middleware/business.js')
middleware['business'] = middleware['business'].default || middleware['business']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['permissionCheck'] = require('../middleware/permissionCheck.js')
middleware['permissionCheck'] = middleware['permissionCheck'].default || middleware['permissionCheck']

export default middleware
