var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.changeable)?_c('ValidationProvider',{ref:"provider",staticClass:"relative overflow-hidden",attrs:{"vid":_vm.vid,"name":_vm.name,"rules":_vm.rules,"immediate":_vm.immediate,"tag":"fieldset"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{ref:"fileInput",staticClass:"absolute top-0 w-full cursor-pointer pt-[100%] opacity-0",attrs:{"type":"file","name":"name","accept":"image/*"},on:{"change":_vm.onFileChange}}),_vm._v(" "),_c('Avatar',{staticClass:"text-black-900 bg-blue h-full w-full",staticStyle:{"background-size":"cover"},attrs:{"custom-style":{
      font: null,
      width: null,
      height: null,
      lineHeight: null,
      fontWeight: null,
      borderRadius: null,
      backgroundColor: null,
      color: null
    },"username":_vm.username,"src":_vm.img,"size":_vm.size,"color":null}}),_vm._v(" "),(
      (!_vm.hideMessage && !_vm.autoHideMessage) ||
      (_vm.autoHideMessage && errors.length > 0)
    )?_c('span',{staticClass:"break-word mt-[4px] block text-red-900"},[_vm._v("\n    "+_vm._s(errors[0] || ' ')+"\n  ")]):_vm._e()]}}],null,false,3378754412)}):_c('Avatar',{staticClass:"text-black-900 bg-blue",staticStyle:{"background-size":"cover"},attrs:{"custom-style":{
    font: null,
    width: null,
    height: null,
    lineHeight: null,
    fontWeight: null,
    borderRadius: null,
    backgroundColor: null,
    color: null
  },"src":_vm.img,"username":_vm.username,"size":_vm.size,"color":null,"parser":_vm.initialParser}})}
var staticRenderFns = []

export { render, staticRenderFns }