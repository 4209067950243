let fs = null
if (process.server) {
  fs = require('fs')
}

function getFileNames(fileName) {
  if (process.server) {
    // Make sure directories end with a slash
    const directory = `${fileName}/`

    let files = fs.readdirSync(directory)
    // Filter out directories
    files = files.filter((f) => !fs.lstatSync(directory + f).isDirectory())
    const filesNoExt = files.map((file) => file.replace('.svg', ''))
    return filesNoExt
  }
  return []
}

export const actions = {
  nuxtServerInit({ state, dispatch }, { req, $config, i18n }) {
    if ($config.envApp === 'development') {
      dispatch('common/setIcons', getFileNames('./assets/icons'))
    }
    i18n.setLocale('id')
  }
}
