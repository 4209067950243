export const rules = {
  password: /^(?=.*[a-zA-Z])(?=.*[\d])[\w\W]{8,32}$/,
  name: /^(?=.{2,}$)[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,

  // email: /^(?=[^@]*[a-z])([a-z0-9])(([a-z0-9])*([\._-])?([a-z0-9]))*@(([a-z0-9\-])+(\.))+([a-z]{2,4})+$/,
  email: /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,3}$/,
  phone: /^(?=.{4,15}$)[0-9]*$/,
  timerange: /^[0-2]\d:[0-5]\d-[0-2]\d:[0-5]\d$/,
  number: /^[+-]?\d*\.\d+$|^[+-]?\d+(\.\d*)?$/,
  // number: /^\d+$/,
  domain:
    /^(((?!-))(xn--)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/,
  subdomain: /[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?/,
  lowerAplha_num_dash: /^[0-9a-z_-]*$/
}

// export const messages = {
//   // Reusable Message
//   wrong_format: 'Format {_field_} tidak sesuai.',
//   required: (fieldName, { isHidden }) => {
//     if (!!isHidden && isHidden === 'hidden') {
//       return ' '
//     }
//     return `${fieldName} harus diisi.`
//   },
//   required_selection: '{_field_} harus dipilih',
//   // Unique Message
//   password:
//     'Password yang kuat setidaknya terdiri dari 8 karakter, maksimal 32 karakter, dan mencakup huruf dan angka.',
//   confirm_password: 'Konfirmasi password tidak sesuai.',
//   confirm_value: '{_field_} tidak sesuai.',
//   is_not: '{_field_} sudah ada.',
//   email_not_exist: 'Email yang kamu masukan sudah terdaftar.',
//   only_number: 'Field hanya bisa diisi dengan angka.',
//   wrong_subdomain: 'Format {_field_} tidak valid'
// }
