import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  pageTitle: '',
  pageTitleMobile: '',
  sidebarMini: false,
  sidebarKey: 0,
  mobileNav: false,
  mainHeaderShow: false,
  navbarShow: true,
  backBtnRoute: '/dashboard',
  businessAddModalShow: false,
  businessAccountPhoneShow: false,
  businessModalCancelShow: false,
  businessModalSuccessShow: false,
  invitationShow: false,
  desktopHeaderColor: '',
  formChanged: false,
  formChangedModalShow: false,
  formChangedNextObj: undefined,
  businessListSelectorShow: false,
  ip: null,
  referer: null,
  settingLoading: false,
  isRouterLoadingEnable: true,
  imageDeleteConfirmationShow: false,
  imageDeleteCb: () => false,
  imageToShow: '',
  imageViewerShow: false,
  colorPickerInit: false,
  currentColorPicker: '#000000',
  icons: [],
  hasLatestVer: false,
  tabPagePadding: 2
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  setIcons({ commit }, payload) {
    commit('SET_ICONS', payload)
  },
  setBackBtnRoute({ commit }, payload) {
    commit('SET_BACK_BTN_ROUTE', payload)
  },
  setBusinessModalCancel({ commit }, payload) {
    commit('SET_BUSINESS_MODAL_CANCEL_SHOW', payload)
  },
  setBusinessModalSuccess({ commit }, payload) {
    commit('SET_BUSINESS_MODAL_SUCCESS_SHOW', payload)
  },
  setPageTitle({ commit }, payload) {
    commit('SET_PAGE_TITLE', payload)
  },
  setPageTitleMobile({ commit }, payload) {
    commit('SET_PAGE_TITLE_MOBILE', payload)
  },
  setSidebarMini({ commit }, payload) {
    commit('SET_SIDEBAR_MINI', payload)
  },
  setMainHeaderShow({ commit }, payload) {
    commit('SET_MAIN_HEADER_SHOW', payload)
  },
  setDesktopHeaderColor({ commit }, payload) {
    commit('SET_DESKTOP_HEADER_COLOR', payload)
  },
  setNavbarShow({ commit }, payload) {
    commit('SET_NAVBAR_SHOW', payload)
  },
  setBusinessAddModalShow({ commit }, payload) {
    commit('SET_BUSINESS_ADD_MODAL_SHOW', payload)
  },
  setBusinessAccountPhoneShow({ commit }, payload) {
    commit('SET_BUSINESS_ACCOUNT_PHONE_SHOW', payload)
  },
  setInvitationShow({ commit }, payload) {
    commit('SET_INVITATION_SHOW', payload)
  },
  setFormChanged({ commit }, payload) {
    commit('SET_FORM_CHANGED', payload)
  },
  setFormChangedModalShow({ commit }, payload) {
    commit('SET_FORM_CHANGED_MODAL_SHOW', payload)
  },
  setFormChangedNextObj({ commit }, payload) {
    commit('SET_FORM_CHANGED_NEXT_OBJ', payload)
  },
  checkBusinessPhone({ dispatch }, currentBusiness) {
    if (
      !!currentBusiness &&
      currentBusiness?.invitationStatus !== 'invited' &&
      !currentBusiness?.businessPhone
    ) {
      dispatch('setBusinessAccountPhoneShow', true)
    } else {
      dispatch('setBusinessAccountPhoneShow', false)
    }
  },
  checkInvitation({ dispatch }, currentBusiness) {
    if (!!currentBusiness && currentBusiness?.invitationStatus === 'invited') {
      dispatch('setInvitationShow', true)
    }
  },
  checkNewVer({ commit }) {
    if (typeof window !== 'undefined') {
      try {
        this.$axios({
          method: 'GET',
          baseURL: '/',
          url: '/server/version-checker'
        }).then((res) => {
          // log check last
          console.log(res.data, localStorage.getItem('buildNumber'))
          if (
            localStorage.getItem('buildNumber') &&
            localStorage.getItem('buildNumber') !== res.data
          ) {
            commit('SET_HAS_LATEST_VER', true)
            console.warn(
              'Terdapat Scalev versi terbaru, tekan Shift + F5 / Cmd + Shift + R'
            )
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  },
  async fetchRaw({ commit }, url) {
    try {
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  setIp({ commit }, ip) {
    commit('SET_IP', ip)
  },
  setReferer({ commit }, referer) {
    commit('SET_REFERER', referer)
  },
  setBusinessListSelectorShow({ commit }, payload) {
    commit('SET_BUSINESS_LIST_SELECTOR_SHOW', payload)
  },
  setSettingLoading({ commit }, payload) {
    commit('SET_SETTING_LOADING', payload)
  },
  refreshSidebar({ state, commit }) {
    commit('SET_SIDEBAR_KEY', state.sidebarKey + 1)
  },
  setIsRouterLoadingEnable({ commit }, isLoading) {
    commit('SET_IS_ROUTER_LOADING_ENABLE', isLoading)
  },
  showImageDeleteConfirmation({ commit }, cb) {
    commit('SET_IMAGE_DELETE_CONFIRMATION_SHOW', true)
    commit('SET_IMAGE_DELETE_CB', cb)
  },
  hideImageDeleteConfirmation({ commit }, cb) {
    commit('SET_IMAGE_DELETE_CONFIRMATION_SHOW', false)
    commit('SET_IMAGE_DELETE_CB', () => false)
  },
  setImageDeleteConfirmationShow({ commit }, payload) {
    commit('SET_IMAGE_DELETE_CONFIRMATION_SHOW', payload)
  },
  setImageViewerShow({ commit }, payload) {
    commit('SET_IMAGE_VIEWER_SHOW', payload)
  },
  setImageToShow({ commit }, payload) {
    commit('SET_IMAGE_TO_SHOW', payload)
  },
  setColorPickerInit({ commit }, payload) {
    commit('SET_COLOR_PICKER_INIT', payload)
  },
  setCurrentColorPicker({ commit }, payload) {
    commit('SET_CURRENT_COLOR_PICKER', payload)
  },
  setTabPagePadding({ commit }, payload) {
    commit('SET_TAB_PAGE_PADDING', payload)
  }
}
