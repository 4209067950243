import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  mootaIntegrations: [],
  metaMootaIntegrations: {}
})

export const mutations = {
  ...mapMutationsHelper(state()),
  SET_IS_OPEN_BY_INDEX(state, { index, isOpen }) {
    state.stores[index].is_open = isOpen
  }
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_MOOTA_INTEGRATIONS', [])
    }

    const { page, itemsLength } = state.metaMootaIntegrations

    if (!isFirst && itemsLength === state.mootaIntegrations.length) {
      return state.mootaIntegrations
    }

    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: 25,
      ...query
    })

    const queries = encodeQueryData(initQuery)
    const url = `/moota-integration/?${queries}`

    try {
      const res = await this.$axios.$get(url)
      const allData = [...state.mootaIntegrations, ...res.data.results]
      commit('SET_MOOTA_INTEGRATIONS', allData)
      const newMeta = decodeQueryData(res.data.next?.split('?')?.[1])

      if (newMeta) {
        commit('SET_META_MOOTA_INTEGRATIONS', {
          itemsLength: res.data.count || res.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async sync({ commit }) {
    try {
      const res = await this.$axios.$post('/moota-integration/sync-webhook/')
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },
  async retrieve({ commit }, id) {
    try {
      const res = await this.$axios.$get(`/moota-integration/${id}/`)
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async fetchStatus({ commit }, id) {
    try {
      const res = await this.$axios.$get(
        `/moota-integration/${id}/check-status/`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async storeData({ commit }, payload) {
    try {
      const { token } = payload

      const res = await this.$axios.$post('/moota-integration/', {
        token
      })

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async update({ commit }, payload) {
    try {
      const { id, token } = payload

      const res = await this.$axios.$put(`/moota-integration/${id}/`, {
        token
      })

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async delete({ commit }, id) {
    try {
      await this.$axios.$delete(`/moota-integration/${id}/`)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  }
}
