import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => {
  return {
    isMultipleItemsFromMainFormItems: false,
    selectedItemFromMainFormItems: null,
    bundlesObjectsFromMainFormItems: [],
    variantsObjectsFromMainFormItems: [],
    storeFromMainForm: null,
    storeObjectFromMainForm: null,
    itemsFromMainFormItems: [],
    itemsTypeFromMainFormItems: 'variant',
    upsellTypeFromMainForm: 'variant',
    upsellBundleObjectFromMainForm: null,
    upsellVariantObjectFromMainForm: null
  }
}

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  setIsMultipleItemsFromMainFormItems({ commit }, payload) {
    commit('SET_IS_MULTIPLE_ITEMS_FROM_MAIN_FORM_ITEMS', payload)
  },

  setSelectedItemFromMainFormItems({ commit }, payload) {
    commit('SET_SELECTED_ITEM_FROM_MAIN_FORM_ITEMS', payload)
  },

  setBundlesObjectsFromMainFormItems({ commit }, payload) {
    commit('SET_BUNDLES_OBJECTS_FROM_MAIN_FORM_ITEMS', payload)
  },

  setVariantsObjectsFromMainFormItems({ commit }, payload) {
    commit('SET_VARIANTS_OBJECTS_FROM_MAIN_FORM_ITEMS', payload)
  },

  setStoreFromMainForm({ commit }, payload) {
    commit('SET_STORE_FROM_MAIN_FORM', payload)
  },

  setStoreObjectFromMainForm({ commit }, payload) {
    commit('SET_STORE_OBJECT_FROM_MAIN_FORM', payload)
  },

  setItemsFromMainFormItems({ commit }, payload) {
    commit('SET_ITEMS_FROM_MAIN_FORM_ITEMS', payload)
  },

  setItemsTypeFromMainFormItems({ commit }, payload) {
    commit('SET_ITEMS_TYPE_FROM_MAIN_FORM_ITEMS', payload)
  },

  setUpsellTypeFromMainForm({ commit }, payload) {
    commit('SET_UPSELL_TYPE_FROM_MAIN_FORM', payload)
  },

  setUpsellBundleObjectFromMainForm({ commit }, payload) {
    commit('SET_UPSELL_BUNDLE_OBJECT_FROM_MAIN_FORM', payload)
  },

  setUpsellVariantObjectFromMainForm({ commit }, payload) {
    commit('SET_UPSELL_VARIANT_OBJECT_FROM_MAIN_FORM', payload)
  }
}
