export const actions = {
  async prepareRegister({ commit }, payload) {
    try {
      const { affCode, metadata, metaEventName, ttEventName, eventId } = payload
      const url = `${this.$config.apiUrlV2}/fb-event/self`
      const res = await this.$axios.$post(url, {
        aff_code: affCode,
        metadata,
        meta_event_name: metaEventName,
        tt_event_name: ttEventName,
        event_id: eventId
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async register(store, payload) {
    try {
      const {
        fullname,
        password,
        email,
        phone,
        address,
        location,
        affCode,
        metadata
      } = payload
      const resRegister = await this.$axios.$post('/user/', {
        fullname,
        password,
        email,
        phone,
        address,
        location,
        aff_code: affCode,
        metadata
      })
      return resRegister
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async registerByInvitation(store, payload) {
    try {
      const { fullname, password, token, uid, affCode } = payload
      const resRegister = await this.$axios.$post(`/user/finish-creation/`, {
        fullname,
        password,
        token,
        uid,
        aff_code: affCode
      })
      return resRegister
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async inviteVerification({ rootState }, { token, buid }) {
    try {
      const resInviteVerif = await this.$axios.$post(
        `/business-user/accept-email/`,
        {
          buid,
          token
        }
      )
      return resInviteVerif
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async resetPassword(store, payload) {
    try {
      const { email } = payload
      const resResetPassword = await this.$axios.$post(
        '/user/reset-password/',
        {
          email
        }
      )
      return resResetPassword
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 400) {
        throw new Error('User dengan email yang diberikan belum terdaftar.')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async activate(store, payload) {
    try {
      const { uid, token } = payload
      const resActivate = await this.$axios.$post('/user/activation/', {
        uid,
        token
      })
      return resActivate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async newPassword(store, payload) {
    try {
      const { newPassword, uid, token } = payload
      const resNewPassword = await this.$axios.$post(
        '/user/reset-password-confirm/',
        {
          uid,
          token,
          new_password: newPassword
        }
      )
      return resNewPassword
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async newEmail(store, payload) {
    try {
      const { uid, token } = payload
      const resNewEmail = await this.$axios.$post('/user/confirm-new-email/', {
        uid,
        token
      })
      return resNewEmail
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
