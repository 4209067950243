//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  props: {
    pageTitle: {
      type: String,
      default: null
    },
    pageSubtitle: {
      type: String,
      default: null
    },
    pageTitleMobile: {
      type: String,
      default: null
    },
    backUrl: {
      type: String,
      default: '/dashboard'
    },
    px: {
      type: String,
      default: 'px-[40px]'
    },
    zIndex: {
      type: String,
      default: 'z-40'
    }
  },
  computed: {
    ...mapGetters('common', ['getMainHeaderShow'])
  }
}
