import {
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  loading: false,
  domain: [],
  metaDomain: {},
  retrieveDomain: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_DOMAIN', [])
      commit('SET_META_DOMAIN', {})
    }
    const { page, pageSize } = state.metaDomain
    // if (itemsLength === state.domain?.length) {
    //   return state.domain
    // }
    const initQuery = initQueryParams({
      page: page || 1,
      page_size: pageSize || 25,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/custom-domain/?${queries}`
    try {
      const resDomain = await this.$axios.$get(url)
      const allDomain = [...state.domain, ...resDomain.data.results]
      commit('SET_DOMAIN', allDomain)
      // const newMeta = decodeQueryData(resDomain.data.next?.split('?')?.[1])
      // if (newMeta) {
      //   commit('SET_META_DOMAIN', {
      //     itemsLength: resDomain.data.count || resDomain.data.results.length,
      //     page: newMeta.page - 0,
      //     pageSize: newMeta.page_size - 0
      //   })
      // }
      commit('SET_META_DOMAIN', {
        page: (page || 1) + 1,
        pageSize: pageSize || 25
      })
      return allDomain
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchRetrieve({ commit }, domainId) {
    commit('SET_LOADING', true)
    const url = `${this.$config.apiUrlV2}/custom-domain/${domainId}/`
    try {
      const resDomain = await this.$axios.$get(url)
      commit('SET_RETRIEVE_DOMAIN', resDomain.data)
      return resDomain.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchAndUpdate({ commit }) {
    commit('SET_LOADING', true)
    const url = `${this.$config.apiUrlV2}/custom-domain/`
    try {
      commit('SET_DOMAIN', [])
      commit('SET_META_DOMAIN', {
        page: 1
      })
      const resDomain = await this.$axios.$get(url)
      commit('SET_DOMAIN', resDomain.data.results)
      return resDomain.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async add({ commit }, payload) {
    commit('SET_LOADING', true)
    const url = `${this.$config.apiUrlV2}/custom-domain/`
    try {
      const { fullUrl } = payload
      const resAddDomain = await this.$axios.$post(url, {
        full_url: fullUrl
      })
      return resAddDomain.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    const { id, provider } = payload
    const url = `${this.$config.apiUrlV2}/custom-domain/${id}/`
    try {
      const res = await this.$axios.$patch(url, {
        provider
      })
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete(_, domainId) {
    try {
      const resDeleteDomain = await this.$axios.$delete(
        `${this.$config.apiUrlV2}/custom-domain/${domainId}/`
      )
      return resDeleteDomain.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
