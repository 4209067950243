import { Socket } from 'phoenix'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import { refreshToken } from './axios-interceptor'

export default (
  { $config, $cookies, store, app, $nuxt, redirect, $axios },
  inject
) => {
  const socketPlugins = {
    initSocket() {
      if (this.initialized) {
        return
      }
      const token = $cookies.get('at')
      const socketOptions = {
        params: {
          token
        }
      }
      const socket = new Socket($config.scWsUrl, socketOptions)
      if (socket) {
        this.socket = socket
        this.socketOptions = socketOptions
        this.initialized = true
      }
    },
    async updateTokenAndReconnect() {
      if (window.$nuxt.isOnline) {
        const res = await refreshToken($axios, $cookies, app.$auth, redirect)
        const token = res.access
        if (!token) {
          return
        }
        this.socketOptions.params.token = token.split(' ')[1]
        this.socket.disconnect()
        this.destroySocket()
        this.initSocket()
        this.socket.connect()
        this.setupSclvNotifications()
        this.socket.onOpen(() => {
          this.socket.off('onOpen')
          this.socket.onOpen(async () => {
            await this.updateTokenAndReconnect()
          })
        })
      }
    },
    initialized: false,
    destroySocket() {
      if (this.socket) {
        this.socket.disconnect()
        this.socket = undefined
        this.initialized = false
      }
    },
    setupSclvNotifications() {
      const audioNewOrder = new Audio(
        '/assets/audio/scalev-notif-new-order.mp3'
      )
      const audioConfirmed = new Audio('/assets/audio/confirmed_sound.mp3')
      const audioToConfirm = new Audio('/assets/audio/to_confirm_sound.mp3')

      const userChannel = this.socket.channel(
        `business_user:${app.$auth.user.account.id}`,
        {}
      )

      userChannel
        .join()
        .receive('ok', () => {})
        .receive('error', (resp) => {
          userChannel.leave()
        })

      userChannel.on('notification:new_order', (payload) => {
        store.dispatch('notification/newOrder')
        audioNewOrder.play()
        const allNotif = clone(store.getters['notification/getNotification'])
        allNotif.splice(0, 0, payload)
        store.dispatch('notification/setNotificationList', allNotif)
      })

      userChannel.on('notification:to_confirm', (payload) => {
        audioToConfirm.volume = 0.3
        audioToConfirm.play()
        const allNotif = clone(store.getters['notification/getNotification'])
        allNotif.splice(0, 0, payload)
        store.dispatch('notification/setNotificationList', allNotif)
      })

      userChannel.on('notification:confirmed', (payload) => {
        audioConfirmed.volume = 0.4
        audioConfirmed.play()
        const allNotif = clone(store.getters['notification/getNotification'])
        allNotif.splice(0, 0, payload)
        store.dispatch('notification/setNotificationList', allNotif)
      })

      userChannel.on('notification:payment_reminder', (payload) => {
        audioNewOrder.play()
        const allNotif = clone(store.getters['notification/getNotification'])
        allNotif.splice(0, 0, payload)
        store.dispatch('notification/setNotificationList', allNotif)
        app.$auth.fetchUser()
      })

      userChannel.on('notification:business_subscription', (payload) => {
        audioNewOrder.play()
        const allNotif = clone(store.getters['notification/getNotification'])
        allNotif.splice(0, 0, payload)
        app.$auth.fetchUser()
        store.dispatch('notification/setNotificationList', allNotif)
      })

      userChannel.on('notification:mark_unpaid', (payload) => {
        audioNewOrder.play()
        const allNotif = clone(store.getters['notification/getNotification'])
        allNotif.splice(0, 0, payload)
        store.dispatch('notification/setNotificationList', allNotif)
        app.$auth.fetchUser()
        store.dispatch(
          'subscription/checkSubscriptionMiddleware',
          app.$auth.user.detail
        )
      })

      userChannel.on('notification:unread_count', (payload) => {
        store.dispatch('notification/setUnread', payload.unread_count)
      })

      store.dispatch('notification/fetchUnread')
    },
    setupPageSession(id) {
      store.dispatch('page/setActiveCurrentOnlineUsersOnEditor', {})
      const audioNewJoin = new Audio('/assets/audio/new-page-session.wav')
      const pageChannel = this.socket.channel(`page:${id}`, {})

      pageChannel
        .join()
        .receive('ok', () => {})
        .receive('error', (resp) => {
          pageChannel.leave()
        })

      pageChannel.on('presence_state', (payload) => {
        const obj = {}

        Object.keys(payload).forEach((x) => {
          payload[x].metas.forEach((y) => {
            obj[y.phx_ref] = y
          })
        })

        store.dispatch('page/setActiveCurrentOnlineUsersOnEditor', obj)
      })

      pageChannel.on('presence_diff', (payload) => {
        if (Object.keys(payload.joins).length > 0) {
          const theKey = Object.keys(payload.joins)[0]
          const phxContent = payload.joins[theKey].metas[0]
          const phxRef = phxContent.phx_ref

          const currentState = cloneDeep(
            store.getters['page/getCurrentOnlineUsersOnEditor']
          )

          currentState[phxRef] = phxContent
          audioNewJoin.play()

          store.dispatch(
            'page/setActiveCurrentOnlineUsersOnEditor',
            currentState
          )
        } else {
          const theKey = Object.keys(payload.leaves)[0]

          const currentState = cloneDeep(
            store.getters['page/getCurrentOnlineUsersOnEditor']
          )

          delete currentState[payload.leaves[theKey].metas[0].phx_ref]

          store.dispatch(
            'page/setActiveCurrentOnlineUsersOnEditor',
            currentState
          )
        }
      })

      return pageChannel
    },
    leavePageSession(pageChannel) {
      pageChannel.leave()
    }
  }
  inject('notif', socketPlugins)
}
