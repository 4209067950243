//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isListBusinessShow: false,
      isBussines: true
    }
  },
  computed: {
    ...mapGetters('business', ['getBusiness']),
    getUserHasBusiness() {
      return !!this.$auth.user.detail?.current_business
    },
    getBusinessDetail() {
      return this.$auth.user.currentBusiness || {}
    }
  },
  watch: {
    '$route.hash'() {
      this.isListBusinessShow = false
    }
  }
}
