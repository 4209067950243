var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full min-w-[300px] max-w-[300px] bg-white"},[_c('div',{staticClass:"sticky top-[40px] flex items-center border-b-2 border-gray-200 bg-white py-[16px] pl-[16px] pr-[21px]"},[_c('p',{staticClass:"font-[700] text-black-1100 text-[16px] leading-[18px]"},[_vm._v("\n      Maul Landing Page\n    ")]),_vm._v(" "),_c('div',{staticClass:"ml-auto flex items-center"},[_c('SclvIcon',{staticClass:"text-black-200 cursor-pointer text-[24px]",attrs:{"icon":"ic-pencil"}}),_vm._v(" "),_c('SclvIcon',{staticClass:"ml-[10px] cursor-pointer text-[24px]",attrs:{"icon":"ic-double-arrow-left-large"}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex w-full space-x-[24px] border-b-2 border-gray-200 px-[16px] pt-[16px]"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.tabPosition = 0}}},[_c('p',{class:((_vm.tabPosition === 0
            ? 'text-black-1100 font-[700]'
            : 'text-black-200 font-[500]') + " text-[12px] leading-[18px]")},[_vm._v("\n        Navigator\n      ")]),_vm._v(" "),(_vm.tabPosition === 0)?_c('div',{staticClass:"bg-primary-gradient-200 mx-[6px] mt-[10px] h-[6px] rounded-t-full"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.tabPosition = 1}}},[_c('p',{class:((_vm.tabPosition === 1
            ? 'text-black-1100 font-[700]'
            : 'text-black-200 font-[500]') + " text-[12px] leading-[18px]")},[_vm._v("\n        Komponen\n      ")]),_vm._v(" "),(_vm.tabPosition === 1)?_c('div',{staticClass:"bg-primary-gradient-200 mx-[6px] mt-[10px] h-[6px] rounded-t-full"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.tabPosition = 2}}},[_c('p',{class:((_vm.tabPosition === 2
            ? 'text-black-1100 font-[700]'
            : 'text-black-200 font-[500]') + " text-[12px] leading-[18px]")},[_vm._v("\n        Pengaturan\n      ")]),_vm._v(" "),(_vm.tabPosition === 2)?_c('div',{staticClass:"bg-primary-gradient-200 mx-[6px] mt-[10px] h-[6px] rounded-t-full"}):_vm._e()])]),_vm._v(" "),(_vm.tabPosition === 0)?_c('div',{staticClass:"mt-[11px] space-y-[13px] px-[17px]"},_vm._l((_vm.navigations),function(navigation,i){return _c('div',{key:i,staticClass:"border-black-150 rounded border-2"},[_c('div',{class:("flex w-full items-center " + (navigation.isLocked
            ? 'py-[12px] pl-[9px] pr-[15px]'
            : 'py-[6px] pl-[6px] pr-[15px]') + " bg-gray-75 " + (navigation.isCollapsed ? 'rounded-t' : 'rounded'))},[(navigation.isLocked)?_c('SclvIcon',{staticClass:"text-primary text-[20px]",attrs:{"icon":"ic-lock-closed"}}):_c('div',{staticClass:"relative flex space-x-[10px] rounded bg-gray-400 py-[7px] px-[10px]"},[_c('SclvIcon',{staticClass:"text-black-200 rotate-[180deg] cursor-pointer text-[20px]",attrs:{"icon":"ic-narrow-down"}}),_vm._v(" "),_c('div',{staticClass:"bg-gray-75 absolute top-0 left-[23px] h-full w-[1px]"}),_vm._v(" "),_c('SclvIcon',{staticClass:"text-black-200 cursor-pointer text-[20px]",attrs:{"icon":"ic-narrow-down"}})],1),_vm._v(" "),_c('p',{class:((navigation.isLocked ? 'ml-[13px]' : 'ml-[10px]') + " font-[500] text-1100 text-[14px] leading-[19px]")},[_vm._v("\n          "+_vm._s(navigation.title)+"\n        ")]),_vm._v(" "),_c('div',{class:("ml-auto flex items-center space-x-[16px] " + (navigation.isCollapsed ? 'pr-[4px]' : ''))},[_c('SclvIcon',{staticClass:"text-black-200 cursor-move text-[20px]",attrs:{"icon":"ic-three-dots"}}),_vm._v(" "),_c('SclvIcon',{class:((navigation.isCollapsed ? 'text-[14px]' : 'text-[20px]') + " text-black-200 cursor-pointer"),attrs:{"icon":navigation.isCollapsed ? 'ic-collapse-line' : 'ic-expand-line'},on:{"click":function($event){navigation.isCollapsed = !navigation.isCollapsed}}})],1)],1),_vm._v(" "),(navigation.isCollapsed)?_c('div',{staticClass:"relative space-y-[10px] rounded-b bg-white px-[24px] pt-[24px] pb-[56px]"},[_vm._l((navigation.components),function(component,j){return _c('div',{key:j},[_c('div',{staticClass:"hover:bg-container flex w-full cursor-default items-center rounded py-[8px] px-[6px]",on:{"click":function($event){component.type === 'columns'
                ? (component.isCollapsed = !component.isCollapsed)
                : null}}},[(component.type === 'columns')?_c('SclvIcon',{staticClass:"text-black-200 text-[8px]",attrs:{"icon":component.isCollapsed
                  ? 'ic-chevron-down'
                  : 'ic-arrow-right-full'}}):_vm._e(),_vm._v(" "),_c('SclvIcon',{class:("text-black-200 text-[16px] " + (component.type === 'columns' ? 'ml-[6px]' : '')),attrs:{"icon":_vm.convertTypeToIcon(component.type)}}),_vm._v(" "),_c('p',{staticClass:"font-[400] text-black-950 ml-[4px] text-[14px] leading-[19px]"},[_vm._v("\n              "+_vm._s(component.name)+"\n            ")])],1),_vm._v(" "),(component.isCollapsed)?_c('div',{staticClass:"mt-[10px] space-y-[10px] pl-[16px]"},_vm._l((component.components),function(component1,k){return _c('div',{key:k,staticClass:"hover:bg-container flex w-full cursor-default items-center rounded py-[8px] px-[6px]"},[_c('SclvIcon',{staticClass:"text-black-200 text-[16px]",attrs:{"icon":_vm.convertTypeToIcon(component1.type)}}),_vm._v(" "),_c('p',{staticClass:"font-[400] text-black-950 ml-[4px] text-[14px] leading-[19px]"},[_vm._v("\n                "+_vm._s(component1.name)+"\n              ")])],1)}),0):_vm._e()])}),_vm._v(" "),_c('div',{staticClass:"bg-primary absolute bottom-[16px] right-[16px] flex h-[32px] w-[32px] cursor-pointer items-center rounded-full shadow-xl"},[_c('SclvIcon',{staticClass:"mx-auto mt-[1px] text-[12px] text-white",attrs:{"icon":"ic-plus"}})],1)],2):_vm._e()])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }