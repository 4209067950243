import {
  mapMutationsHelper,
  mapGettersHelper
} from '@/utils/helpers.js'

export const state = () => ({
  gtm: [],
  isAllGtmLoaded: false
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, queries = {}) {
    const { page = 1, search = '' } = queries
    try {
      const resGtm = await this.$axios.$get(`/gtm/?page=${page}&name=${search}`)
      commit('SET_GTM', resGtm.data.results)
      commit('SET_IS_ALL_GTM_LOADED', resGtm.data.next === null)
      return resGtm
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, id) {
    try {
      const resFetchById = await this.$axios.$get(`/gtm/${id}/`)
      return resFetchById
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    const { name, containerId } = payload
    try {
      const resStoreData = await this.$axios.$post('/gtm/', {
        name,
        container_id: containerId
      })
      return resStoreData
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    const { id, name, containerId } = payload
    try {
      const resUpdate = await this.$axios.$put(`/gtm/${id}/`, {
        name,
        container_id: containerId
      })
      return resUpdate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit }, id) {
    try {
      await this.$axios.$delete(`/gtm/${id}/`)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
