import {
  mapMutationsHelper,
  mapGettersHelper,
  encodeQueryData,
  isEmpty
} from '@/utils/helpers.js'

export const state = () => ({
  contacts: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    try {
      const resContacts = await this.$axios.$get(`/customer/${queries}`)
      commit('SET_CONTACTS', resContacts.data.results)
      return resContacts
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchById({ commit }, id) {
    try {
      const res = await this.$axios.$get(`/customer/${id}/`)
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
