import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  isEmpty,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  member: [],
  loading: false,
  location: [],
  warehouse: [],
  metaWarehouse: {},
  metaWarehousePartner: {},
  retrieveWarehouse: [],
  warehousePartner: [],
  retrieveWarehousePartner: [],
  warehouseCombined: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state()),
  getSortedWarehouse(state) {
    const warehouse = [...state.warehouse]
    return warehouse
  }
}

export const actions = {
  // ++Start Warehouse Section++ //

  // Create Warehouse
  async createWarehouse(_, payload) {
    try {
      const {
        name,
        address,
        subdistrictModel,
        isSameCityDelivery,
        sameCityDeliveryFee,
        warehouseAdmins,
        isOpen
      } = payload
      const resCreateWarehouse = await this.$axios.$post(`/warehouse/`, {
        name,
        is_open: isOpen,
        warehouse_admins: warehouseAdmins,
        warehouse_address: {
          location: subdistrictModel,
          address
        },
        is_same_city_delivery: isSameCityDelivery,
        same_city_delivery_fee: sameCityDeliveryFee
      })
      return resCreateWarehouse.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Get All Warehouse
  async fetchAllWarehouse({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) commit('SET_WAREHOUSE', [])
    const { page, pageSize, itemsLength } = state.metaWarehouse
    if (itemsLength === state.warehouse?.length) {
      return state.warehouse
    }

    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })

    const querie = encodeQueryData(initQuery)
    const url = `/warehouse/?${querie}`
    try {
      const resWarehouse = await this.$axios.$get(url)
      const allWarehouse = [...state.warehouse, ...resWarehouse.data.results]
      commit('SET_WAREHOUSE', allWarehouse)
      const newMeta = decodeQueryData(resWarehouse.data.next?.split('?')?.[1])

      if (newMeta) {
        commit('SET_META_WAREHOUSE', {
          itemsLength:
            resWarehouse.data.count || resWarehouse.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return resWarehouse
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Retrieve Warehouse
  async retrieveWarehouse({ commit }, idWarehouse) {
    const url = `warehouse/${idWarehouse}/`
    try {
      const retrieveWarehouse = await this.$axios.$get(url)
      commit('SET_RETRIEVE_WAREHOUSE', retrieveWarehouse.data)
      return retrieveWarehouse.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Update Warehouse
  async updateWarehouse(_, { idWarehouse, ...payload }) {
    try {
      const {
        name,
        address,
        subdistrictModel,
        isSameCityDelivery,
        sameCityDeliveryFee,
        warehouseAdmins,
        isOpen
      } = payload
      const resUpdateWarehouse = await this.$axios.$put(
        `warehouse/${idWarehouse}/`,
        {
          name,
          is_open: isOpen,
          warehouse_admins: warehouseAdmins,
          warehouse_address: {
            location: subdistrictModel,
            address
          },
          is_same_city_delivery: isSameCityDelivery,
          same_city_delivery_fee: sameCityDeliveryFee
        }
      )
      return resUpdateWarehouse.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credenstials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Partial Update Warehouse
  async partialUpdateWarehouse(_, { idWarehouse, ...payload }) {
    try {
      const {
        name,
        address,
        subdistrictModel,
        isSameCityDelivery,
        sameCityDeliveryFee,
        isOpen
      } = payload
      const resPartialUpdateWarehouse = await this.$axios.$patch(
        `warehouse/${idWarehouse}/`,
        {
          name,
          is_open: isOpen,
          warehouse_address: {
            location: subdistrictModel,
            address
          },
          is_same_city_delivery: isSameCityDelivery,
          same_city_delivery_fee: sameCityDeliveryFee
        }
      )
      return resPartialUpdateWarehouse.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credenstials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Delete Warehouse
  async destroyWarehouse(_, idWarehouse) {
    try {
      const resDestroyWarehouse = await this.$axios.$delete(
        `warehouse/${idWarehouse}/`
      )
      return resDestroyWarehouse
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Create Warehouse Partner
  async createWarehousePartner(_, { idWarehouse, uniqueId }) {
    const url = `warehouse/${idWarehouse}/warehouse-partner/`
    try {
      const resCreateWarehouse = await this.$axios.$post(url, {
        unique_id: uniqueId
      })
      return resCreateWarehouse
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // reset Warehouse
  resetWarehouse({ commit }) {
    commit('SET_WAREHOUSE_PARTNER', [])
    commit('SET_META_WAREHOUSE_PARTNER', {})
  },

  // Get All Warehouse Partner
  async fetchAllWarehousePartner(
    { commit, state },
    { idWarehouse, isFirst = false }
  ) {
    if (isFirst) commit('SET_WAREHOUSE_PARTNER', [])
    const { page, pageSize } = state.metaWarehousePartner
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize
    })
    const queries = encodeQueryData(initQuery)
    const url = `/warehouse/${idWarehouse}/warehouse-partner/?${queries}`
    try {
      const resWarehousePartner = await this.$axios.$get(url)
      const allWarehousePartner = [
        ...state.warehousePartner,
        ...resWarehousePartner.data.results
      ]
      commit('SET_WAREHOUSE_PARTNER', allWarehousePartner)
      const newMeta = decodeQueryData(
        resWarehousePartner.data.next?.split('?')?.[1]
      )
      if (newMeta) {
        commit('SET_META_WAREHOUSE_PARTNER', {
          itemsLength:
            resWarehousePartner.data.count ||
            resWarehousePartner.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return allWarehousePartner
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Fetch Warehouse Partner Not Paginated
  async fetchWarehousePartnerNotPaginated({ commit }, idWarehouse) {
    const url = `warehouse/${idWarehouse}/warehouse-partner/`
    try {
      const retrieveWarehouse = await this.$axios.$get(url)
      commit('SET_WAREHOUSE_PARTNER', retrieveWarehouse.data.results)
      return retrieveWarehouse.data.results
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Retrieve Warehouse Partner
  async retrieveWarehousePartner(
    { commit },
    { idWarehouse, idWarehousePartner }
  ) {
    const url = `warehouse/${idWarehouse}/warehouse-partner/${idWarehousePartner}/`
    try {
      const retrieveWarehouse = await this.$axios.$get(url)
      commit('SET_RETRIEVE_WAREHOUSE', retrieveWarehouse.data.results)
      return retrieveWarehouse.data.results
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Update Warehouse Partner
  async updateWarehousePartner(
    _,
    { idWarehouse, idWarehousePartner, isComply }
  ) {
    try {
      const resUpdateWarehousePartner = await this.$axios.$patch(
        `warehouse/${idWarehouse}/warehouse-partner/${idWarehousePartner}/`,
        {
          is_comply: isComply
        }
      )
      return resUpdateWarehousePartner.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credenstials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Delete Warehouse Partner
  async destroyWarehousePartner(_, { idWarehouse, idWarehousePartner }) {
    try {
      const resDestroyWarehousePartner = await this.$axios.$delete(
        `warehouse/${idWarehouse}/warehouse-partner/${idWarehousePartner}/`
      )
      return resDestroyWarehousePartner
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchNotPaginated({ commit }) {
    const url = `/warehouse/`
    try {
      const warehouseAll = await this.$axios.$get(url)
      commit('SET_WAREHOUSE', warehouseAll.data.results)
      return warehouseAll.data.results
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // ++End Warehouse Section++ //

  // ++Start Shared Warehouse++ //

  resetState({ commit }) {
    commit('SET_WAREHOUSE', [])
    commit('SET_WAREHOUSE_PARTNER', [])
    commit('SET_META_WAREHOUSE', {
      page: 0
    })
    commit('SET_META_WAREHOUSE_PARTNER', {
      page: 0
    })
  },

  // Fetch Location
  async fetchLocation({ commit }, { query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/location/${queries}`
    try {
      const resSubdistrictList = await this.$axios.$get(url)

      commit('SET_LOCATION', resSubdistrictList.data.results)
      commit('SET_LOADING', false)
      return resSubdistrictList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },

  // Fetch Location by id
  async fetchLocationById({ commit }, id) {
    const url = `/location/${id}/`
    try {
      const resSubdistrictById = await this.$axios.$get(url)
      return resSubdistrictById.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // ++End Shared Warehouse++ //

  // ++Start Warehouse Business++ //
  async fetchBusinessByUniqueId(_, uniqueId) {
    const url = `business-search/?unique_id=${uniqueId}`
    try {
      const res = await this.$axios.$get(url)
      return res.data.results
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
