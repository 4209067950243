import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  loading: false,
  reason: [],
  warehouse: [],
  products: [],
  productByWarehouse: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async storeData(store, payload) {
    try {
      // eslint-disable-next-line camelcase
      const {
        variant,
        warehouse,
        qty,
        notes,
        createdAtInput,
        quantityType,
        category,
        type
      } = payload
      const change = type === 'add' ? qty - 0 : qty * -1
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/inventory-flow/`,
        {
          variant_id: variant,
          warehouse_id: warehouse,
          change: Math.floor(Number(change)),
          quantity_type: quantityType,
          category,
          notes,
          created_at_input: createdAtInput
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async conversion(_, payload) {
    try {
      // eslint-disable-next-line camelcase
      const {
        variant,
        warehouse,
        change,
        category,
        notes,
        createdAtInput,
        quantityType,
        warehouseFrom,
        quantityTypeFrom
      } = payload
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/inventory-flow/`,
        {
          variant_id: variant,
          warehouse_id: warehouse,
          change: Math.floor(Number(change)),
          quantity_type: quantityType,
          category,
          notes,
          created_at_input: createdAtInput,
          warehouse_id_from: warehouseFrom,
          quantity_type_from: quantityTypeFrom
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllReason({ commit }, type) {
    try {
      const res = await this.$axios.$get(`inventory/reason/${type}/`)
      commit('SET_REASON', res.data.results)
      return res.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllWarehouse({ commit }) {
    try {
      const res = await this.$axios.$get(`inventory/warehouse/`)
      commit('SET_WAREHOUSE', res.data.results)
      return res.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllProduct({ commit }) {
    try {
      const res = await this.$axios.$get(`inventory/products/`)
      commit('SET_PRODUCTS', res.data.results)
      return res.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchProductByWarehouse({ commit }, warehouseId) {
    try {
      const res = await this.$axios.$get(
        `/products/?simplified=true&warehouse_id=${warehouseId}`
      )
      commit('SET_PRODUCTS_BY_WAREHOUSE', res.data.results)
      return res.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
