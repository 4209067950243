//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ToastItem from './Item.vue'

export default {
  name: 'GlobalToast',
  components: {
    ToastItem
  },
  computed: {
    getToast() {
      return this.$store.getters['common/toast/getToast']
    }
  }
}
