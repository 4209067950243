import base64Encoder from '~/utils/base64Encoder'

export const refreshToken = async (axios, $cookies, $auth, redirect) => {
  try {
    const res = await axios.$post(`/auth/jwt/refresh/`, {
      refresh: $cookies.get('rt')
    })

    $cookies.set('at', res.access, {
      path: '/',
      maxAge: 1000 * 3600 * 24 * 30
    })
    $cookies.set('rt', res.refresh, {
      path: '/',
      maxAge: 1000 * 3600 * 24 * 30
    })

    return res
  } catch (err) {
    await $auth.logout()
    if (process.client) {
      location.href = '/login'
    } else {
      redirect('/login')
    }
  }
}

export default function ({
  $axios,
  app,
  $cookies,
  $auth,
  redirect,
  route,
  req
}) {
  let getNewTokenRequest = null

  $axios.onRequest((config) => {
    const businessId = app.$auth?.user?.currentBusiness?.id || 0
    const addedParams = {
      b_id: businessId ? base64Encoder.encode(businessId) : undefined
    }
    config.params = { ...config.params, ...addedParams }
    if ($cookies.get('at')) {
      config.headers.Authorization = `Bearer ${$cookies.get('at')}`
    }
    if (process.server) {
      config.headers['X-Forwarded-For'] =
        req?.headers['x-forwarded-for'] || req?.socket?.remoteAddress || null
      console.log(
        `server request x-forwarded-for:`,
        req?.headers['x-forwarded-for'] || req?.socket?.remoteAddress || null
      )
    }
    return config
  })

  $axios.onResponseError(async (error) => {
    const res = error.response?.data
    const originalConfig = error.config

    if (
      typeof res?.message !== 'object' &&
      (res?.message?.includes('token not valid') ||
        res?.status === 'Unauthorized') &&
      !res?.message?.includes('WRONG password')
    ) {
      if (!getNewTokenRequest) {
        getNewTokenRequest = refreshToken($axios, $cookies, $auth, redirect)
      }
      const newToken = await getNewTokenRequest
      originalConfig.headers.Authorization = `Bearer ${newToken.access}`
      getNewTokenRequest = null

      if (process.server) {
        redirect({
          path: route.fullPath,
          query: {
            ...route.query,
            reloadedTime: new Date().getTime()
          }
        })
      } else {
        return $axios(originalConfig)
      }
    }

    return Promise.reject(error)
  })
}
