import snakeCase from 'lodash/snakeCase'
import {
  mapMutationsHelper,
  mapGettersHelper
} from '@/utils/helpers.js'

export const state = () => ({
  role: [],
  businessRole: [],
  currentBusinessRole: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }) {
    const url = `role/`
    try {
      const resRoleList = await this.$axios.$get(url)
      commit('SET_ROLE', resRoleList.data.results)
      return resRoleList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchBusinessRole({ commit }) {
    const url = `business_role/`
    try {
      const resBusinessRoleList = await this.$axios.$get(url)
      commit('SET_BUSINESS_ROLE', resBusinessRoleList.data)
      return resBusinessRoleList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchAllByModelName({ commit }, modelName) {
    const url = `/business-user/me/business-role/?model_name=${modelName}`
    try {
      const resByModelName = await this.$axios.$get(url)
      commit('SET_ROLE', resByModelName.data.results)
      return resByModelName
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchCurrentBusinessRole({ commit }) {
    const url = `/business-user/me/current-business-role/`
    try {
      const resCurrentBusinessRole = await this.$axios.$get(url)
      commit(
        'SET_CURRENT_BUSINESS_ROLE',
        resCurrentBusinessRole.data.business_role_model
      )
      return resCurrentBusinessRole
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchById(store, roleId) {
    const url = `role/${roleId}/`
    try {
      const resRoleDetail = await this.$axios.$get(url)
      return resRoleDetail.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async storeData(store, payload) {
    try {
      const { name, permissions, description } = payload
      const codeName = snakeCase(name)
      const resAddRole = await this.$axios.$post('role/', {
        name: codeName,
        display_name: name,
        description,
        business: this.$auth.user.currentBusiness.id,
        permissions
      })
      return resAddRole
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async updateData(store, payload) {
    try {
      const { roleId, name, businessRole, description } = payload
      const resEditRole = await this.$axios.$put(`role/${roleId}/`, {
        name,
        description,
        business_roles: businessRole
      })
      return resEditRole
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async switchRole(store, payload) {
    try {
      const { modelName, businessRoleCodename } = payload
      const resSwitchRole = await this.$axios.$put(
        `business-user/me/switch-business-role/`,
        {
          model_name: modelName,
          business_role: businessRoleCodename
        }
      )
      return resSwitchRole
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async delete(store, roleId) {
    try {
      const resDeleteRole = await this.$axios.$delete(`role/${roleId}/`)
      return resDeleteRole
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
