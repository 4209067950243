import {
  mapMutationsHelper,
  mapGettersHelper
} from '@/utils/helpers.js'

export const state = () => ({
  loading: false,
  publicBundle: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchByStore({ commit }, { idStore }) {
    commit('SET_LOADING', true)
    const url = `/v1/store/${idStore}/bundles/`
    try {
      const resPublicBundleList = await this.$prodApi.get(url)
      commit('SET_PUBLIC_BUNDLE', resPublicBundleList.data)
      commit('SET_LOADING', false)
      return resPublicBundleList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  }
}
