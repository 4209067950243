import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  marketingProgram: null,
  pointLogs: [],
  metaPointLogs: {},
  newsFeeds: [],
  metaNewsFeed: {},
  packageData: [],
  metaPackageData: {},
  rewardData: [],
  metaRewardData: {},
  itemRedemptionData: [],
  metaItemRedemptionData: {},
  memberData: [],
  metaMemberData: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  // GET MARKETING PRODUCT DATA
  async fetchMarketingProgramById({ commit }, id) {
    try {
      const res = await this.$axios.$get(`/marketing-program/${id}/`)
      commit('SET_MARKETING_PROGRAM', res.data)
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async createMarketingProgram({ commit }, store) {
    try {
      const res = await this.$axios.$post(`/marketing-program/`, {
        store
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  // CRUD MARKETING NEWS FEED
  async createMarketingNewsFeed({ commit }, payload) {
    try {
      const { id, ...restPayload } = payload
      const res = await this.$axios.$post(
        `/marketing-program/${id}/news-feed/`,
        restPayload
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchMarketingNewsFeedList(
    { state, commit },
    { id, query = {}, isFirst = false } = {}
  ) {
    if (isFirst) {
      commit('SET_NEWS_FEEDS', [])
      commit('SET_META_NEWS_FEED', {})
    }
    const { page, pageSize } = state.metaNewsFeed
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/marketing-program/${id}/news-feed/?${queries}`
    const resNewsFeedData = await this.$axios.$get(url)
    const allNewsFeedData = [
      ...state.newsFeeds,
      ...resNewsFeedData.data.results
    ]
    commit('SET_NEWS_FEEDS', allNewsFeedData)
    let newMeta = {
      itemsLength:
        resNewsFeedData.data.count || resNewsFeedData.data.results.length
    }
    const nextMeta = resNewsFeedData.data.next
      ? decodeQueryData(resNewsFeedData.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_NEWS_FEED', newMeta)
    return {
      totalCount: resNewsFeedData.data.count,
      currentData: allNewsFeedData
    }
  },
  async retrieveMarketingNewsFeedById({ commit }, { marketingId, id } = {}) {
    try {
      const res = await this.$axios.$get(
        `/marketing-program/${marketingId}/news-feed/${id}/`
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async updateMarketingNewsFeedById(
    { commit },
    { marketingId, id, ...payload } = {}
  ) {
    try {
      const res = await this.$axios.$patch(
        `/marketing-program/${marketingId}/news-feed/${id}/`,
        payload
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async deleteMarketingNewsFeedById({ commit }, { marketingId, id } = {}) {
    try {
      const res = await this.$axios.$delete(
        `/marketing-program/${marketingId}/news-feed/${id}/`
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // CRUD MARKETING PACKAGE
  async createMarketingPackage({ commit }, payload) {
    try {
      const { id, ...restPayload } = payload
      const res = await this.$axios.$post(
        `/marketing-program/${id}/marketing-package/`,
        restPayload
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchMarketingPackageList(
    { state, commit },
    { id, query = {}, isFirst = false } = {}
  ) {
    if (isFirst) {
      commit('SET_PACKAGE_DATA', [])
      commit('SET_META_PACKAGE_DATA', {})
    }
    const { page, pageSize } = state.metaPackageData
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/marketing-program/${id}/marketing-package/?${queries}`
    const resPackageData = await this.$axios.$get(url)
    const allPackageData = [
      ...state.packageData,
      ...resPackageData.data.results
    ]
    commit('SET_PACKAGE_DATA', allPackageData)
    let newMeta = {
      itemsLength:
        resPackageData.data.count || resPackageData.data.results.length
    }
    const nextMeta = resPackageData.data.next
      ? decodeQueryData(resPackageData.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_PACKAGE_DATA', newMeta)
    return {
      totalCount: resPackageData.data.count,
      currentData: allPackageData
    }
  },
  async retrieveMarketingPackageById({ commit }, { marketingId, id } = {}) {
    try {
      const res = await this.$axios.$get(
        `/marketing-program/${marketingId}/marketing-package/${id}/`
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async updateMarketingPackageById(
    { commit },
    { marketingId, id, ...payload } = {}
  ) {
    try {
      const res = await this.$axios.$patch(
        `/marketing-program/${marketingId}/marketing-package/${id}/`,
        payload
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async deleteMarketingPackageById({ commit }, { marketingId, id } = {}) {
    try {
      const res = await this.$axios.$delete(
        `/marketing-program/${marketingId}/marketing-package/${id}/`
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // CRUD MEMBERSHIP REWARD
  async createMembershipReward({ commit }, { id, ...restPayload }) {
    try {
      const res = await this.$axios.$post(
        `/marketing-program/${id}/reward-item/`,
        restPayload
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchMembershipRewardList(
    { state, commit },
    { id, query = {}, isFirst = false } = {}
  ) {
    if (isFirst) {
      commit('SET_REWARD_DATA', [])
      commit('SET_META_REWARD_DATA', {})
    }
    const { page, pageSize } = state.metaRewardData
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/marketing-program/${id}/reward-item/?${queries}`
    const resRewardData = await this.$axios.$get(url)
    const allRewardData = [...state.rewardData, ...resRewardData.data.results]
    commit('SET_REWARD_DATA', allRewardData)
    let newMeta = {
      itemsLength: resRewardData.data.count || resRewardData.data.results.length
    }
    const nextMeta = resRewardData.data.next
      ? decodeQueryData(resRewardData.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_REWARD_DATA', newMeta)
    return {
      totalCount: resRewardData.data.count,
      currentData: allRewardData
    }
  },
  async retrieveMembershipRewardById({ commit }, { marketingId, id } = {}) {
    try {
      const res = await this.$axios.$get(
        `/marketing-program/${marketingId}/reward-item/${id}/`
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async updateMembershipRewardById(
    { commit },
    { marketingId, id, ...payload } = {}
  ) {
    try {
      const res = await this.$axios.$patch(
        `/marketing-program/${marketingId}/reward-item/${id}/`,
        payload
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async deleteMembershipRewardById({ commit }, { marketingId, id } = {}) {
    try {
      const res = await this.$axios.$delete(
        `/marketing-program/${marketingId}/reward-item/${id}/`
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // CRU ITEM REDEMPTION
  async createItemRedemption({ commit }, { id, ...restPayload }) {
    try {
      const res = await this.$axios.$post(
        `/marketing-program/${id}/item-redemption/`,
        restPayload
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchItemRedemptionList(
    { state, commit },
    { id, query = {}, isFirst = false } = {}
  ) {
    if (isFirst) {
      commit('SET_ITEM_REDEMPTION_DATA', [])
      commit('SET_META_ITEM_REDEMPTION_DATA', {})
    }
    const { page, pageSize } = state.metaItemRedemptionData
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/marketing-program/${id}/item-redemption/?${queries}`
    const resItemRedemptionData = await this.$axios.$get(url)
    const allItemRedemptionData = [
      ...state.itemRedemptionData,
      ...resItemRedemptionData.data.results
    ]
    await commit('SET_ITEM_REDEMPTION_DATA', allItemRedemptionData)
    let newMeta = {
      itemsLength:
        resItemRedemptionData.data.count ||
        resItemRedemptionData.data.results.length
    }
    const nextMeta = resItemRedemptionData.data.next
      ? decodeQueryData(resItemRedemptionData.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_ITEM_REDEMPTION_DATA', newMeta)
    return {
      totalCount: resItemRedemptionData.data.count,
      currentData: allItemRedemptionData
    }
  },
  async retrieveItemRedemptionById({ commit }, { marketingId, id } = {}) {
    try {
      const res = await this.$axios.$get(
        `/marketing-program/${marketingId}/item-redemption/${id}/`
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async updateItemRedemptionById(
    { commit },
    { marketingId, id, ...payload } = {}
  ) {
    try {
      const res = await this.$axios.$patch(
        `/marketing-program/${marketingId}/item-redemption/${id}/`,
        payload
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // CRUD MARKETING MEMBER
  async inviteNewMember({ commit }, { id, ...restPayload }) {
    try {
      const res = await this.$axios.$post(
        `/marketing-program/${id}/marketing-member/`,
        restPayload
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchMemberList(
    { commit, state },
    { id, query = {}, isFirst = false } = {}
  ) {
    if (isFirst) {
      commit('SET_MEMBER_DATA', [])
      commit('SET_META_MEMBER_DATA', {})
    }
    const { page, pageSize } = state.metaMemberData
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/marketing-program/${id}/marketing-member/?${queries}`
    const resMemberData = await this.$axios.$get(url)
    const allMemberData = [...state.memberData, ...resMemberData.data.results]
    await commit('SET_MEMBER_DATA', allMemberData)
    let newMeta = {
      itemsLength: resMemberData.data.count || resMemberData.data.results.length
    }
    const nextMeta = resMemberData.data.next
      ? decodeQueryData(resMemberData.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_MEMBER_DATA', newMeta)
    return {
      totalCount: resMemberData.data.count,
      currentData: allMemberData
    }
  },
  async retrieveMemberById({ commit }, { marketingId, id } = {}) {
    try {
      const res = await this.$axios.$get(
        `/marketing-program/${marketingId}/marketing-member/${id}/`
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchPointLogs(
    { state, commit, dispatch },
    { id, query = {}, isFirst = false } = {}
  ) {
    if (isFirst) {
      commit('SET_POINT_LOGS', [])
      commit('SET_META_POINT_LOGS', {})
    }
    const { page, pageSize } = state.metaPointLogs
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/marketing-program/${id}/point-log/?${queries}`
    const resPointLogs = await this.$axios.$get(url)
    const allPointLogs = [...state.pointLogs, ...resPointLogs.data.results]
    commit('SET_POINT_LOGS', allPointLogs)
    let newMeta = {
      itemsLength: resPointLogs.data.count || resPointLogs.data.results.length
    }
    const nextMeta = resPointLogs.data.next
      ? decodeQueryData(resPointLogs.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_POINT_LOGS', newMeta)
    return {
      totalCount: resPointLogs.data.count,
      currentData: allPointLogs
    }
  },

  async promoteLeader({ commit }, payload) {
    const { marketingId, memberId } = payload
    try {
      const res = await this.$axios.$patch(
        `/marketing-program/${marketingId}/`,
        {
          leader: memberId
        }
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async demoteLeader({ commit }, marketingId) {
    try {
      const res = await this.$axios.$patch(
        `/marketing-program/${marketingId}/`,
        {
          leader: null
        }
      )
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
