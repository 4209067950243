import Vue from 'vue'
import {
  // Directives
  VTooltip,
  VClosePopper,
  // Components
  Dropdown,
  Tooltip
  // Menu,
} from 'floating-vue'

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popper', VClosePopper)

Vue.component('FloatingVueDropdown', Dropdown)
Vue.component('FloatingVueTooltip', Tooltip)
// Vue.component('FloatingVueMenu', Menu)
