import {
  mapMutationsHelper,
  mapGettersHelper
} from '@/utils/helpers.js'

export const state = () => ({
  bundles: [],
  metaBundles: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  setBundles({ commit }, bundles) {
    commit('SET_BUNDLES', bundles)
  },

  setMetaBundles({ commit }, metaBundles) {
    commit('SET_META_BUNDLES', metaBundles)
  },

  async fetchById({ commit }, payload) {
    const { orderId, bundleId } = payload
    const url = `/order/${orderId}/bundle/${bundleId}/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
