//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    contentPadding: {
      type: String,
      default: 'p-[8px]'
    },
    triggerWrapperClass: {
      type: String,
      default: 'flex'
    }
  },
  computed: {
    fixedAttrs() {
      return {
        placement: 'bottom',
        distance: 10,
        ...this.$attrs
      }
    }
  }
}
