import {
  mapMutationsHelper,
  mapGettersHelper,
  isEmpty,
  encodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  paymentAccounts: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAllByStoreId({ commit }, payload) {
    const { storeId, query } = payload
    try {
      let queries = ''
      if (!isEmpty(query)) {
        queries = `?${encodeQueryData(query)}`
      }
      const resFetchAllPaymentAccounts = await this.$axios.$get(
        `/store/${storeId}/payment-accounts/${queries}`
      )
      commit('SET_PAYMENT_ACCOUNTS', resFetchAllPaymentAccounts.data.results)
      return resFetchAllPaymentAccounts
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
