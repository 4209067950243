import {
  mapMutationsHelper,
  mapGettersHelper,
  isEmpty,
  encodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  loading: false,
  city: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchByProvince({ commit }, { idProvince, query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/v1/location/province/${idProvince}/city${queries}`
    try {
      const resCityList = await this.$prodApi.get(url)
      commit('SET_CITY', resCityList.data)
      commit('SET_LOADING', false)
      return resCityList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchByProvinceV2({ commit }, { idProvince, query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/v2/location/province/${idProvince}/city${queries}`
    try {
      const resCityList = await this.$prodApi.get(url)
      commit('SET_CITY', resCityList.data)
      commit('SET_LOADING', false)
      return resCityList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchByProvinceReal({ commit }, { provinceId, query }) {
    commit('SET_LOADING', true)
    try {
      let queries = ''
      if (!isEmpty(query)) {
        queries = `?${encodeQueryData(query)}`
      }
      const url = `/location/province/${provinceId}/city/${queries}`
      const resCity = await this.$axios.$get(url)
      commit('SET_CITY', resCity.data)
      commit('SET_LOADING', false)
      return resCity
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  }
}
