//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    changedCheck: {
      type: Boolean,
      default: true
    },
    isPrevent: {
      type: Boolean,
      default: true
    },
    method: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    disabledLeaveConfirmation: {
      type: Boolean,
      default: false
    },
    noWatchChanged: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isFormChanged: {
      get() {
        return this.$store.getters['common/getFormChanged']
      },
      set(val) {
        this.$store.dispatch('common/setFormChanged', val)
      }
    }
  },
  watch: {
    isFormChanged(val) {
      this.formChanged(val)
    }
  },
  mounted() {
    if (!this.noWatchChanged) {
      this.watchChangedState()
    }
  },
  destroyed() {
    this.$store.dispatch('common/setFormChanged', false)
    // window.removeEventListener('beforeunload', this.beforeWindowUnload, false)
    window.onbeforeunload = () => null
  },
  methods: {
    async submit(e) {
      if (this.isPrevent && e) {
        e.preventDefault()
      }

      if (this.$refs.observer.flags.valid) {
        await this.$store.dispatch('common/setFormChanged', false)
        // window.removeEventListener(
        //   'beforeunload',
        //   this.beforeWindowUnload,
        //   false
        // )
        window.onbeforeunload = () => null
        if (
          !this.changedCheck ||
          (this.changedCheck && this.$refs.observer.flags.changed)
        ) {
          const res = await this.$asyncEmit('submit')
          if (res) {
            this.$emit('submit-success')
          }
        } else if (
          this.$listeners &&
          !this.$listeners['submit-success'] &&
          this.changedCheck
        ) {
          // Handling old format form
          await this.$asyncEmit('submit')
        } else {
          this.$emit('submit-success')
        }
      } else {
        await this.$refs.observer.validate()
      }
    },
    /** @public */
    validate() {
      return this.$refs.observer.validate()
    },
    /** @public */
    reset() {
      return this.$refs.observer.reset()
    },
    watchChangedState() {
      this.$watch(
        () => this.$refs.observer.flags.changed,
        (isChanged) => {
          if (!this.disabledLeaveConfirmation) this.isFormChanged = isChanged
        }
      )
    },
    formChanged(isChanged) {
      if (isChanged) {
        // window.addEventListener('beforeunload', this.beforeWindowUnload, false)
        window.onbeforeunload = () => false
      } else {
        window.onbeforeunload = () => null
        // window.removeEventListener(
        //   'beforeunload',
        //   this.beforeWindowUnload,
        //   false
        // )
      }
    },
    confirmLeave() {
      return window.confirm(
        'Do you really want to leave? you have unsaved changes!'
      )
    },
    confirmStayInDirtyForm() {
      return !this.confirmLeave()
    },
    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    }
  }
}
