export default async function ({ $auth, $cookies, redirect, route }) {
  const guestRoutes = ['/forget', '/login', '/new-password']

  const publicRoutes = [
    '/activation',
    '/invitation',
    '/join',
    '/lottie-list',
    '/new-email',
    '/register',
    '/balance/download-invoice',
    '/order/public',
    '/other-setting/billing/download-invoice'
  ]

  const isPublicRoute = publicRoutes.some((publicRoute) => {
    return (
      route.path === publicRoute || route.path.startsWith(publicRoute + '/')
    )
  })

  if (
    [...guestRoutes, ...publicRoutes].includes(route.path) ||
    isPublicRoute ||
    route.path === '/'
  ) {
    return
  }

  if (!$cookies.get('at')) {
    redirect('/login')
  }

  if (!$auth.user) {
    await $auth.fetchUser()
  }
}
