//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import { onChangeCompanyBusiness } from '@/utils/helpers.js'

export default {
  name: 'ErrorScreen',
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 500,
        message: ''
      })
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      isCancel: false,
      isSuccess: true,
      isPermissionListModalShown: false,
      titleChangePlanConfirmModal: 'Berhenti Berlangganan',
      isAuthModalShown: false,
      nextPlan: null,
      publicRouteNames: [
        'order-public-secretSlug',
        'order-public-secretSlug-success',
        'balance-download-invoice-secret',
        'other-setting-billing-download-invoice-secret'
      ]
    }
  },
  head() {
    const { statusCode, message } = this.error
    let errorMessage = ''

    if (statusCode === 500) {
      errorMessage = 'Internal Server Error'
    } else {
      errorMessage = `${statusCode} - ${
        statusCode === 404 ? 'Page Not Found' : message
      }`
    }

    return {
      bodyAttrs: {
        class: 'bg-gray'
      },
      title: statusCode ? errorMessage : message
    }
  },
  computed: {
    ...mapGetters('common', [
      'getNavbarShow',
      'getMainHeaderShow',
      'getBusinessAddModalShow',
      'getBusinessModalCancelShow',
      'getBusinessModalSuccessShow',
      'getBusinessListSelectorShow'
    ]),
    ...mapGetters('subscription', [
      'getPricePlanShow',
      'getChangePlanConfirmShow'
    ]),
    getPermissions() {
      try {
        const permissionsList = cloneDeep(
          this.$auth.user.account.role.permissions_list
        )
        return permissionsList
      } catch {
        return []
      }
    },
    getUserHasBusiness() {
      return !!this.$auth.user.detail?.current_business
    },
    isBusinessShow: {
      get() {
        return this.getBusinessListSelectorShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessListSelectorShow', val)
      }
    },
    isAddModalShow: {
      get() {
        return this.getBusinessAddModalShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessAddModalShow', val)
      }
    },
    isSuccessBusiness: {
      get() {
        return this.getBusinessModalSuccessShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessModalSuccess', val)
      }
    },
    isCancelModalShow: {
      get() {
        return this.getBusinessModalCancelShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessModalCancel', val)
      }
    },
    isPricePlanModalShow: {
      get() {
        return this.getPricePlanShow
      },
      set(val) {
        this.$store.dispatch('subscription/setPricePlanShow', val)
      }
    },
    isChangePlanConfirmModalShow: {
      get() {
        return this.getChangePlanConfirmShow
      },
      set(val) {
        this.$store.dispatch('subscription/setChangePlanConfirmShow', val)
      }
    },
    currentPlan() {
      return (
        this.$auth?.user?.detail.current_business_subscription
          ?.current_pricing_plan || null
      )
    }
  },
  watch: {
    // error: {
    //   handler() {
    //     if (process.browser) {
    //       const refreshToken = localStorage.getItem('auth._refresh.customLocal')
    //       if (!refreshToken || refreshToken === 'false') {
    //         location.reload()
    //       }
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // },
    '$route.path'(val, oldVal) {
      if (oldVal !== val) {
        this.$store.dispatch('common/setDesktopHeaderColor', '')
      }
    }
  },
  destroyed() {
    document.querySelector('#sclv-modal-wrapper').innerHTML = ''
  },
  async mounted() {
    try {
      await this.$store.dispatch(
        'subscription/fetchById',
        this.$auth.user.currentBusiness.subscription.id
      )
    } catch (err) {
      console.log('failed to fetch subscription detail')
    }
    // if (this.error.statusCode === 402) {
    //   const resSubsListOrder = await this.$store.dispatch(
    //     'subscription/fetchAll',
    //     {
    //       isFirst: true,
    //       pageSize: 1
    //     }
    //   )
    //   console.log(resSubsListOrder)
    this.isPricePlanModalShow = false
    setTimeout(() => {
      if (
        ![
          'setting-billing',
          'setting-business',
          'setting-profile',
          'setting-profile-edit',
          'setting-profile-change-password'
        ].includes(this.$route.name) &&
        this.getPermissions.includes('edit_business')
      ) {
        this.$store.dispatch(
          'subscription/checkSubscriptionMiddleware',
          this.$auth.user.detail
        )
      }
    }, 100)
    // }
  },
  methods: {
    onChangeCompanyBusiness,
    addBusinessSucceed() {
      this.isAddModalShow = false
      this.isSuccess = true
      this.onChangeCompanyBusiness()
    },
    yesCancel() {
      this.isCancel = false
      this.isAddModalShow = false
    },
    noCancel() {
      this.isCancel = false
      this.isBusinessAddModelShow = true
    },
    showCancel() {
      this.isCancel = true
    },
    showChangePlanConfirmModal(nextPlan) {
      this.nextPlan = nextPlan
      this.titleChangePlanConfirmModal = 'Pilih Paket Subscription'
      this.isChangePlanConfirmModalShow = true
    },
    async changePlan() {
      this.$nuxt.$loading.start(true)
      try {
        let resUpdateSubscription = null
        const payloadPayment = {
          idSubscription:
            this.$auth.user?.detail.current_business_subscription?.id,
          pricingPlanCode: this.nextPlan.code
        }
        if (this.$auth.user?.detail.current_business_subscription) {
          resUpdateSubscription = await this.$store.dispatch(
            'subscription/partialUpdateSubscription',
            {
              idSubscription:
                this.$auth.user?.detail.current_business_subscription?.id,
              pricingPlanCode: this.nextPlan.code
            }
          )
        } else {
          resUpdateSubscription = await this.$store.dispatch(
            'subscription/storeData',
            payloadPayment
          )
        }
        let titleToast = 'dipilih'
        if (
          this.currentPlan !== null &&
          this.currentPlan.price > this.nextPlan.price
        ) {
          titleToast = 'diturunkan'
        } else if (
          this.currentPlan !== null &&
          this.currentPlan.price > this.nextPlan.price
        ) {
          titleToast = 'ditingkatkan'
        }
        this.$toast.showMessage({
          message: 'Paket Berlangganan Berhasil ' + titleToast,
          color: 'success'
        })

        if (
          resUpdateSubscription?.latest_subscription_order?.status === 'open'
        ) {
          setTimeout(() => {
            window
              .open(
                resUpdateSubscription.latest_subscription_order
                  .xendit_invoice_url,
                '_blank'
              )
              .focus()
          })
        }
        await this.$auth.fetchUser()
        await this.$store.dispatch('subscription/fetchAll', {
          isFirst: true
        })
        this.isChangePlanConfirmModalShow = false
        this.isPricePlanModalShow = false
        this.$router.push('/setting/billing')
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.$nuxt.$loading.finish()
      }
    },
    async checkForSubsPlanCreated(newSubsId) {
      for (let loopCount = 0; loopCount < 10; loopCount++) {
        loopCount++
        const resNewSubPlan = await this.$store.dispatch(
          'subscription/fetchById',
          newSubsId
        )
        if (resNewSubPlan.current_pricing_plan) {
          loopCount = 10
          return Promise.resolve('current pricing plan created!') // Resolve the promise with a success message
        }
        if (loopCount > 10) {
          loopCount = 10
          return Promise.reject(new Error(`Error: Too much loop`))
        }
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, 1000)
        })
      }
    },
    onCloseModalPricePlan() {
      this.isPricePlanModalShow = false
      this.$router.push('/setting/billing')
    }
  }
}
