import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaJoinReq: {},
  joinReq: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { isFirst = false, ...query } = {}) {
    if (isFirst) {
      commit('SET_JOIN_REQ', [])
      commit('SET_META_JOIN_REQ', {})
    }
    const { page, pageSize } = state.metaJoinReq
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 20 : pageSize,
      is_registering: true,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `business-user/?${queries}`
    try {
      const resJoinReqList = await this.$axios.$get(url)
      const allJoinReqList = [...state.joinReq, ...resJoinReqList.data.results]
      commit('SET_JOIN_REQ', allJoinReqList)
      let newMeta = {
        itemsLength:
          resJoinReqList.data.count || resJoinReqList.data.results.length
      }
      const nextMeta = resJoinReqList.data.next
        ? decodeQueryData(resJoinReqList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_JOIN_REQ', newMeta)
      return {
        totalCount: resJoinReqList.data.count,
        currentData: allJoinReqList
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async storeData(store, payload) {
    try {
      const { fullname, email, password, invitationCode, page } = payload
      const resCreateJoinReq = await this.$axios.$post(
        'business-user/register/',
        {
          fullname,
          email,
          password,
          invitation_code: invitationCode,
          page
        }
      )
      return resCreateJoinReq.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
