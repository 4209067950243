import clone from 'lodash/clone'
import filter from 'lodash/filter'
import map from 'lodash/map'
import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaProduct: {},
  product: [],
  metaProductPartner: {},
  productPartner: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state()),
  getListActiveProduct(state) {
    const filteredIsCheckedVariant = map(state.product, (prod) => {
      let listProduct = clone(prod.variants)
      listProduct = filter(listProduct, (vari) => vari.is_checked)
      return {
        ...prod,
        variants: listProduct
      }
    })
    return filter(filteredIsCheckedVariant, (prod) => prod.variants.length > 0)
  }
}

export const actions = {
  async retrieveNexus({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/product/${id}/`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async updateNexus({ commit }, payload) {
    const { id, productBirdsendSequences, productMailketingLists } = payload
    try {
      const url = `${this.$config.apiUrlV2}/product/${id}/`
      const res = await this.$axios.$patch(url, {
        product_birdsend_sequences: productBirdsendSequences,
        product_mailketing_lists: productMailketingLists
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchAll(
    { commit, state },
    { query, isFirst = false, cancelToken } = {}
  ) {
    if (isFirst) {
      commit('SET_PRODUCT', [])
      commit('SET_META_PRODUCT', {})
    }
    const { page, pageSize } = state.metaProduct
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/product/?${queries}`
    try {
      const resProductList = await this.$axios.$get(url, {
        cancelToken
      })
      const allProduct = [...state.product, ...resProductList.data.results]
      commit('SET_PRODUCT', allProduct)
      let newMeta = {
        itemsLength:
          resProductList.data.count || resProductList.data.results.length
      }
      const nextMeta = resProductList.data.next
        ? decodeQueryData(resProductList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_PRODUCT', newMeta)
      return { totalCount: resProductList.data.count, currentData: allProduct }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllSimplified({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_PRODUCT', [])
      commit('SET_META_PRODUCT', [])
    }
    const { page, pageSize } = state.metaProduct
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/product/simplified/?${queries}`
    try {
      const resProductList = await this.$axios.$get(url)
      const allProduct = [...state.product, ...resProductList.data.results]
      commit('SET_PRODUCT', allProduct)
      let newMeta = {
        itemsLength:
          resProductList.data.count || resProductList.data.results.length
      }
      const nextMeta = resProductList.data.next
        ? decodeQueryData(resProductList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_PRODUCT', newMeta)
      return {
        totalCount: resProductList.data.count,
        currentData: allProduct
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, idProduct) {
    const url = `product/${idProduct}/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async delete(_store, idProduct) {
    try {
      await this.$axios.$delete(`product/${idProduct}/`)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchRelations({ commit }, idProduct) {
    try {
      const res = await this.$axios.$get(`product/${idProduct}/show-relations/`)
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchAllProductPartner(
    { commit, state },
    { idProduct, isFirst = false }
  ) {
    if (isFirst) {
      commit('SET_PRODUCT_PARTNER', [])
      commit('SET_META_PRODUCT_PARTNER', {})
    }
    const { page, pageSize } = state.metaProductPartner
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize
    })
    const queries = encodeQueryData(initQuery)
    const url = `/product/${idProduct}/shared/?${queries}`
    try {
      const resProductPartner = await this.$axios.$get(url)
      const allProductPartner = [
        ...state.productPartner,
        ...resProductPartner.data.results
      ]
      commit('SET_PRODUCT_PARTNER', allProductPartner)
      const newMeta = decodeQueryData(
        resProductPartner.data.next?.split('?')?.[1]
      )
      if (newMeta) {
        commit('SET_META_PRODUCT_PARTNER', {
          itemsLength:
            resProductPartner.data.count ||
            resProductPartner.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return resProductPartner.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Create Product Partner
  async createProductPartner(_, { idProduct, uniqueId }) {
    const url = `product/${idProduct}/shared/`
    try {
      const resCreateProductPartner = await this.$axios.$post(url, {
        partner_unique_id: uniqueId
      })
      return resCreateProductPartner
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Delete Product Partner
  async destroyProductPartner(_store, { idProduct, idProductPartner }) {
    try {
      const resDestroyProductPartner = await this.$axios.$delete(
        `product/${idProduct}/shared/${idProductPartner}/`
      )
      return resDestroyProductPartner
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async storeData(store, payload) {
    try {
      const {
        name,
        description,
        isPublished,
        isPriceAfterTax,
        isInventory,
        itemType,
        isMultiple,
        option1Name,
        option2Name,
        option3Name,
        variants,
        labels,
        stores,
        warehouses
      } = payload
      const fixedVariant = variants.map(
        ({
          sku,
          price,
          cogsPrice,
          resellerPrice,
          weight,
          isChecked,
          option1Value,
          option2Value,
          option3Value,
          images,
          specialUuid,
          selfFileUrls
        }) => ({
          sku,
          price,
          cogs: cogsPrice,
          reseller_price: resellerPrice,
          weight: weight === null ? 0 : weight,
          option1_value: option1Value === '' ? null : option1Value,
          option2_value: option2Value === '' ? null : option2Value,
          option3_value: option3Value === '' ? null : option3Value,
          is_checked: isChecked,
          images,
          self_file_urls: selfFileUrls,
          metadata: {
            special_uuid: specialUuid
          }
        })
      )
      const resAddProduct = await this.$axios.$post('/product/', {
        name,
        description,
        is_inventory: isInventory,
        item_type: itemType,
        is_price_after_tax: isPriceAfterTax,
        is_multiple: isMultiple,
        is_published: isPublished,
        option1_name: option1Name === '' ? null : option1Name,
        option2_name: option2Name === '' ? null : option2Name,
        option3_name: option3Name === '' ? null : option3Name,
        variants: fixedVariant,
        labels,
        stores,
        warehouses
      })
      return resAddProduct.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update(store, { idProduct, ...payload }) {
    try {
      const {
        name,
        description,
        isPublished,
        isPriceAfterTax,
        isInventory,
        itemType,
        isProductSharing,
        isMultiple,
        option1Name,
        option2Name,
        option3Name,
        variants,
        labels,
        stores,
        warehouses
      } = payload
      const fixedVariant = variants.map(
        ({
          id,
          isChecked,
          sku,
          price,
          cogsPrice,
          resellerPrice,
          weight,
          option1Value,
          option2Value,
          option3Value,
          images,
          specialUuid,
          selfFileUrls
        }) => ({
          id,
          is_checked: isChecked,
          sku,
          price,
          cogs: cogsPrice,
          reseller_price: resellerPrice,
          weight: weight === null ? 0 : weight,
          option1_value: option1Value === '' ? null : option1Value,
          option2_value: option2Value === '' ? null : option2Value,
          option3_value: option3Value === '' ? null : option3Value,
          images,
          self_file_urls: selfFileUrls,
          metadata: {
            special_uuid: specialUuid
          }
        })
      )
      const resUpdateProduct = await this.$axios.$put(
        `/product/${idProduct}/`,
        {
          name,
          description,
          is_published: isPublished,
          is_price_after_tax: isPriceAfterTax,
          item_type: itemType,
          is_inventory: isInventory,
          is_product_sharing: isProductSharing,
          is_multiple: isMultiple,
          option1_name: option1Name === '' ? null : option1Name,
          option2_name: option2Name === '' ? null : option2Name,
          option3_name: option3Name === '' ? null : option3Name,
          variants: fixedVariant,
          labels,
          stores,
          warehouses
        }
      )
      return resUpdateProduct
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async patch(store, { idProduct, ...payload }) {
    try {
      const {
        name,
        description,
        isPublished,
        isPriceAfterTax,
        isInventory,
        itemType,
        isProductSharing,
        isMultiple,
        option1Name,
        option2Name,
        option3Name,
        variants,
        labels
      } = payload
      const fixedVariant =
        variants?.map(
          ({
            id,
            isChecked,
            sku,
            price,
            cogsPrice,
            resellerPrice,
            weight,
            option1Value,
            option2Value,
            option3Value,
            images,
            specialUuid
          }) => ({
            id,
            is_checked: isChecked,
            sku,
            price,
            cogs: cogsPrice,
            reseller_price: resellerPrice,
            weight,
            option1_value: option1Value === '' ? null : option1Value,
            option2_value: option2Value === '' ? null : option2Value,
            option3_value: option3Value === '' ? null : option3Value,
            images,
            metadata: {
              special_uuid: specialUuid
            }
          })
        ) || undefined
      const resPatchProduct = await this.$axios.$patch(
        `/product/${idProduct}/`,
        {
          name,
          description,
          is_published: isPublished,
          is_price_after_tax: isPriceAfterTax,
          item_type: itemType,
          is_inventory: isInventory,
          is_product_sharing: isProductSharing,
          is_multiple: isMultiple,
          option1_name: option1Name === '' ? null : option1Name,
          option2_name: option2Name === '' ? null : option2Name,
          option3_name: option3Name === '' ? null : option3Name,
          variants: fixedVariant,
          labels
        }
      )
      return resPatchProduct
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateIsPublished({ commit }, payload) {
    try {
      const { idProduct, isPublished } = payload
      const url = `/product/${idProduct}/`
      const resUpdateStatus = await this.$axios.$patch(url, {
        is_published: isPublished
      })
      return resUpdateStatus
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async deleteIndicator(store, { idProduct, ...payload }) {
    try {
      const { option1Value, option2Value, option3Value } = payload
      const resDeleteIndicator = await this.$axios.$put(
        `/product/${idProduct}/delete_category/`,
        {
          option1_value: option1Value || [],
          option2_value: option2Value || [],
          option3_value: option3Value || []
        }
      )
      return resDeleteIndicator
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async newCategory(store, { idProduct, ...payload }) {
    try {
      const { optionName, defaultValue } = payload
      const resNewCategory = await this.$axios.$put(
        `/product/${idProduct}/create_category/`,
        {
          option_name: optionName,
          default_value: defaultValue
        }
      )
      return resNewCategory
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadDigitalProduct({ commit }, payload) {
    try {
      const { variant, file } = payload
      const formData = new FormData()
      formData.append('variant', variant)
      formData.append('file', file)
      const res = await this.$axios.$post('/digital-product-file/', formData)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async deleteDigitalProduct({ commit }, id) {
    try {
      await this.$axios.$delete(`/digital-product-file/${id}/`)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  setProducts({ commit }, payload) {
    commit('SET_PRODUCT', payload)
  },

  setProductPartners({ commit }, payload) {
    commit('SET_PRODUCT_PARTNER', payload)
  },

  setMetaProducts({ commit }, payload) {
    commit('SET_META_PRODUCT', payload)
  },

  setMetaProductPartners({ commit }, payload) {
    commit('SET_META_PRODUCT_PARTNER', payload)
  }
}
