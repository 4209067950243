import {
  mapMutationsHelper,
  mapGettersHelper,
  encodeQueryData,
  isEmpty
} from '@/utils/helpers.js'

export const state = () => ({
  addresses: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    try {
      const resAddresses = await this.$axios.$get(
        `/customer-address/${queries}`
      )
      commit('SET_ADDRESSES', resAddresses.data.results)
      return resAddresses
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchById({ commit }, id) {
    try {
      const resFetchById = await this.$axios.$post(`/customer-address/${id}`)
      return resFetchById
    } catch (error) {
      if (error.response.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response.status === 502) {
        throw new Error('Network Error')
      }
    }
  },

  async storeData({ commit }, payload) {
    try {
      const { customer, name, phone, location, address } = payload
      const resStoreDataAddress = await this.$axios.$post(
        `/customer-address/`,
        {
          customer,
          name,
          phone,
          location,
          address
        }
      )
      return resStoreDataAddress
    } catch (error) {
      if (error.response.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response.status === 502) {
        throw new Error('Network Error')
      }
    }
  },

  async update({ commit }, payload) {
    try {
      const { id, customer, name, phone, location, address } = payload
      const resUpdateAddress = await this.$axios.$put(
        `/customer-address/${id}/`,
        {
          customer,
          name,
          phone,
          location,
          address
        }
      )
      return resUpdateAddress
    } catch (error) {
      if (error.response.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response.status === 502) {
        throw new Error('Network Error')
      }
    }
  },

  async delete({ commit }, id) {
    try {
      const res = await this.$axios.$delete(`/customer-address/${id}/`)
      return res
    } catch (error) {
      if (error.response.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response.status === 502) {
        throw new Error('Network Error')
      }
    }
  }
}
