import {
  mapMutationsHelper,
  mapGettersHelper,
  isEmpty,
  encodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  loading: false,
  province: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, { query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/v1/location/province${queries}`
    try {
      const resProvinceList = await this.$prodApi.get(url)
      commit('SET_PROVINCE', resProvinceList.data)
      commit('SET_LOADING', false)
      return resProvinceList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchAllV2({ commit }, { query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/v2/location/province${queries}`
    try {
      const resProvinceList = await this.$prodApi.get(url)
      commit('SET_PROVINCE', resProvinceList.data)
      commit('SET_LOADING', false)
      return resProvinceList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchAllReal({ commit }, { query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/location/province/${queries}`
    try {
      const resProvince = await this.$axios.$get(url)
      commit('SET_PROVINCE', resProvince.data)
      commit('SET_LOADING', false)
      return resProvince
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  }
}
