import clone from 'lodash/clone'
import { mapGettersHelper } from '@/utils/helpers.js'

export const state = () => ({
  toast: []
})

export const mutations = {
  showMessage(state, payload) {
    const s = state
    s.toast.push({
      message: payload.message,
      color: payload.color,
      toastId: payload.toastId,
      callback: payload.callback
    })
  },
  SET_TOAST(state, payload) {
    const s = state
    s.toast = payload
  }
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  closeToast({ state, commit }, toastId) {
    const tempToast = clone(state.toast)
    const idToDelete = tempToast.findIndex(
      (toastNa) => toastNa.toastId === toastId
    )
    if (idToDelete > -1) {
      tempToast.splice(idToDelete, 1)
      commit('SET_TOAST', tempToast)
    }
  }
}
