var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'NuxtLink' : 'button',{ref:"button",tag:"components",staticClass:"inline-flex font-medium focus:outline-none",class:[
    _vm.disabled
      ? ((!_vm.text ? 'bg-gray-150 ' : ("" + (_vm.forceBgGrey ? 'bg-gray-150' : ''))) + " text-black-750 cursor-default")
      : _vm.color || 'text-gray-600',
    _vm.text
      ? ''
      : ("min-w-[80px] transform items-center justify-center py-[10px] px-4 text-center transition duration-100 ease-in-out " + _vm.rounded),
    {
      'bg-gray-100': !_vm.text && !_vm.color,
      'text-sm': _vm.small,
      'px-2': !_vm.text && _vm.small
    }
  ],attrs:{"to":_vm.to,"disabled":_vm.disabled,"type":!_vm.to && _vm.type},on:{"click":_vm.onClick,"blur":_vm.onBlur}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }