import { mapMutationsHelper, mapGettersHelper } from '@/utils/helpers.js'

export const state = () => ({})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, storeId) {
    try {
      const res = await this.$axios.$get(`/store/${storeId}/custom-audiences/`)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
