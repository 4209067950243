import {
  mapMutationsHelper,
  mapGettersHelper,
  initQueryParams,
  decodeQueryData,
  encodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  formDisplay: {},
  formDisplays: [],
  form: {},
  forms: [],
  metaForm: {},
  isAllFormsLoaded: false,
  isAllFormDisplaysLoaded: false
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAllNew({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_FORMS', [])
      commit('SET_META_FORM', {})
    }
    const { page, pageSize } = state.metaForm
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/form/?${queries}`
    try {
      const resFormList = await this.$axios.$get(url)
      const allForms = [...state.forms, ...resFormList.data.results]
      commit('SET_FORMS', allForms)
      let newMeta = {
        itemsLength: resFormList.data.count || resFormList.data.results.length
      }
      const nextMeta = resFormList.data.next
        ? decodeQueryData(resFormList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_FORM', newMeta)
      return { totalCount: resFormList.data.count, currentData: allForms }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAll({ commit }, queries = {}) {
    const { page = 1, search = '' } = queries
    try {
      const resForms = await this.$axios.$get(
        `/form/?page=${page}&name=${search}`
      )
      commit('SET_FORMS', resForms.data.results)
      commit('SET_IS_ALL_FORMS_LOADED', resForms.data.next === null)
      return resForms
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchSingle({ commit }, id) {
    try {
      const resForm = await this.$axios.$get(`/form/${id}/`)
      commit('SET_FORM', resForm.data)
      return resForm
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchFormDisplay({ commit }, payload) {
    const { id, formDisplayId } = payload
    try {
      const resFormDisplay = await this.$axios.$get(
        `/form/${id}/form-display/${formDisplayId}/`
      )
      commit('SET_FORM_DISPLAY', resFormDisplay.data)
      return resFormDisplay
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllFormDisplays({ commit }, queries = {}) {
    const { id, pageSize = 6, page = 1 } = queries
    try {
      const resFormDisplays = await this.$axios.$get(
        `/form/${id}/form-display/?page_size=${pageSize}&page=${page}`
      )
      commit('SET_FORM_DISPLAYS', resFormDisplays.data.results)
      commit(
        'SET_IS_ALL_FORM_DISPLAYS_LOADED',
        resFormDisplays.data.next === null
      )
      return resFormDisplays
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchPublic({ commit }, payload) {
    const { subdomain, slug } = payload
    try {
      const resPublic = await this.$axios.$get(
        `/form/public/${subdomain}/${slug}`
      )
      commit('SET_FORM', resPublic.data)
      commit('SET_FORM_DISPLAY', resPublic.data.current_form_display)
      return resPublic
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // async fetchPublicV3({ commit }, payload) {
  //   const { subdomain, slug } = payload
  //   try {
  //     const resPublic = await this.$apiV3.$get(
  //       `/form/public/${subdomain}/${slug}/`
  //     )
  //     commit('SET_FORM', resPublic.data)
  //     commit('SET_FORM_DISPLAY', resPublic.data.current_form_display)
  //     return resPublic
  //   } catch (error) {
  //     if (error.response?.status === 401) {
  //       throw new Error('Bad Credentials')
  //     } else if (error.response?.status === 502) {
  //       throw new Error('Network Error')
  //     }
  //     throw error
  //   }
  // },

  resetFormDisplay({ commit }) {
    commit('SET_FORM_DISPLAY', {})
  },

  setForm({ commit }, form) {
    commit('SET_FORM', form)
  }
}
