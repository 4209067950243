import {
  mapMutationsHelper,
  mapGettersHelper
} from '@/utils/helpers.js'

export const state = () => ({
  channels: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, storeId) {
    try {
      const resFetchAllChannels = await this.$axios.$get(
        `/store/${storeId}/channels/`
      )
      commit('SET_CHANNELS', resFetchAllChannels.data.results)
      return resFetchAllChannels
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    try {
      const { storeId, name, utmSource, utmCampaign, utmMedium, utmTerm, utmContent, advertiser } = payload
      const res = await this.$axios.$post(`/store/${storeId}/channels/`, {
        name,
        utm_source: utmSource,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_term: utmTerm,
        utm_content: utmContent,
        advertiser
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    try {
      const { storeId, id, name, utmSource, utmCampaign, utmMedium, utmTerm, utmContent, advertiser } = payload
      const res = await this.$axios.$put(`/store/${storeId}/channels/${id}/`, {
        name,
        utm_source: utmSource,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_term: utmTerm,
        utm_content: utmContent,
        advertiser
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit }, payload) {
    try {
      const { storeId, id } = payload
      await this.$axios.$delete(`/store/${storeId}/channels/${id}/`)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
