//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

export default {
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isListShow: false,
      isPaginationDone: false,
      isLoadingSearch: false,
      canAddBusiness: true
    }
  },
  async fetch() {
    const { store } = this.$nuxt.context
    await store.dispatch('business/resetBusiness')
  },
  computed: {
    ...mapGetters('business', ['getBusiness', 'getMetaBusiness']),
    getUserHasBusiness() {
      return !!this.$auth.user.detail?.current_business
    },
    getBusinessDetail() {
      return this.$auth.user.currentBusiness || {}
    }
  },
  methods: {
    addClick() {
      this.isListShow = false
      this.$emit('add-click')
    },
    async onView({ isVisible }) {
      if (isVisible && !this.isPaginationDone) {
        await this.$store.dispatch('business/fetchAll')
      }
      if (this.getBusiness.length === this.getMetaBusiness.itemsLength) {
        this.isPaginationDone = true
      }
    },
    seachTypeHandler(val) {
      this.isPaginationDone = true
      this.isLoadingSearch = true
      this.$store.dispatch('business/resetBusiness')
      this.searchDebounced(val)
    },
    searchDebounced: debounce(async function (val) {
      await this.search(val)
      this.isLoadingSearch = false
      this.isPaginationDone =
        this.getBusiness.length === this.getMetaBusiness.itemsLength
    }, 500),
    async search(val) {
      await this.$store.dispatch('business/fetchAll', {
        business_name: val
      })
    },
    async changeBusiness(idBusiness) {
      this.$nuxt.$loading.start(true)
      try {
        if (typeof window !== 'undefined') {
          localStorage.removeItem('statisticQuery')
          localStorage.removeItem('newDashboardQuery')
        }
        this.$router.push({
          path: this.$route.path,
          query: {}
        })
        await this.$store.dispatch('business/changeBusiness', idBusiness)
        this.isListShow = false
        this.$asyncEmit('change')
      } catch (error) {
        this.$toast.showMessage({
          message: this.$errorHandlerMessage(error),
          color: 'danger'
        })
        this.$nuxt.$loading.finish()
        if (error.response.data.code === 409) {
          this.$router.go()
        }
      }
    }
  }
}
