import {
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaNotification: {},
  notification: [],
  unread: 0,
  newOrder: 0
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll(
    { commit, state },
    { query, isFirst = false, cancelToken } = {}
  ) {
    if (isFirst) {
      commit('SET_NOTIFICATION', [])
      commit('SET_META_NOTIFICATION', {})
    }
    const { page, pageSize } = state.metaNotification
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    try {
      const url = `${this.$config.apiUrlV2}/notification/?${queries}`
      const resNotifList = await this.$axios.$get(url, {
        cancelToken
      })
      const allNotification = [
        ...state.notification,
        ...resNotifList.data.results
      ]
      commit('SET_NOTIFICATION', allNotification)
      let newMeta = {
        itemsLength: allNotification.length
      }
      newMeta = {
        ...newMeta,
        page: initQuery.page + 1,
        pageSize: initQuery.page_size - 0
      }
      commit('SET_META_NOTIFICATION', newMeta)
      return {
        currentLength: resNotifList.data.results.length,
        totalCount: newMeta.itemsLength,
        currentData: allNotification
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchUnread({ commit, state }) {
    try {
      const url = `${this.$config.apiUrlV2}/notification/unread-count/`
      const resNotifUnread = await this.$axios.$get(url)
      commit('SET_UNREAD', resNotifUnread.data.unread_count)
      return resNotifUnread.data.unread_count
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async markAsRead(_, idNotif) {
    try {
      const url = `${this.$config.apiUrlV2}/notification/${idNotif}/mark-as-read/`
      await this.$axios.$put(url)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async readAll() {
    try {
      const url = `${this.$config.apiUrlV2}/notification/mark-all-as-read/`
      await this.$axios.$post(url)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  newOrder({ commit, state }) {
    commit('SET_NEW_ORDER', state.newOrder + 1)
  },
  resetNewOrder({ commit, state }) {
    commit('SET_NEW_ORDER', 0)
  },
  setUnread({ commit }, payload) {
    commit('SET_UNREAD', payload)
  },
  setNotificationList({ commit }, payload) {
    commit('SET_NOTIFICATION', payload)
  }
}
