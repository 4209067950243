//
//
//
//
//
//
//
//

export default {
  props: {
    loadingText: {
      type: String,
      default: 'Loading more data...'
    },
    isDone: {
      type: Boolean,
      default: false
    },
    root: {
      type: process.server ? Object : HTMLElement,
      default: null
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  watch: {
    isDone(val) {
      if (val) {
        this.isVisible = false
      }
    }
  },
  methods: {
    onChange(entry, unobserve) {
      // After loading Cancel monitoring, optimise performance
      this.isVisible = entry.isIntersecting
      const onUnObserve = () => {
        unobserve()
        this.isVisible = false
      }
      if (!this.isDone) {
        this.$emit('change', {
          entry,
          isVisible: entry.isIntersecting,
          unobserve: onUnObserve
        })
      }
      this.isVisible = entry.isIntersecting
    }
  }
}
