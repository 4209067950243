//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider, validate } from 'vee-validate'
import debounce from 'lodash/debounce'
import clone from 'lodash/clone'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import { emulateTab } from 'emulate-tab'
import scrollIntoView from 'scroll-into-view-if-needed'
import { encodeQueryData, initQueryParams } from '@/utils/helpers.js'

export default {
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    valueField: {
      type: String,
      default: 'value'
    },
    isPaginated: {
      type: Boolean,
      default: true
    },
    textField: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    bg: {
      type: String,
      default: 'bg-gray-150'
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    selectedObject: {
      type: Object,
      default() {
        return null
      }
    },
    prepend: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    rules: {
      type: String,
      default: ''
    },
    immediate: {
      type: Boolean,
      default: false
    },
    hideMessage: {
      type: Boolean,
      default: false
    },
    autoHideMessage: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: undefined
    },
    labelSize: {
      type: String,
      default: 'text-[12px]'
    },
    textSize: {
      type: String,
      default: 'text-[14px]'
    },
    isWithSubText: {
      type: Boolean,
      default: false
    },
    subTextField: {
      type: String,
      default: 'subText'
    },
    small: {
      type: Boolean,
      default: false
    },
    isWithAvatar: {
      type: Boolean,
      default: false
    },
    avatarField: {
      type: String,
      default: null
    },
    avatarRight: {
      type: Boolean,
      default: false
    },
    isSearchable: {
      type: Boolean,
      default: false
    },
    placeholderSearch: {
      type: String,
      default: 'Cari Pilihan'
    },
    searchLoadingText: {
      type: String,
      default: 'Mencari Pilihan ...'
    },
    listLoadingText: {
      type: String,
      default: 'Memuat Pilihan ...'
    },
    urlOptions: {
      type: String,
      default: ''
    },
    searchQuery: {
      type: String,
      default: 'search'
    },
    urlQuery: {
      type: Object,
      default() {
        return {
          page: 1,
          page_size: 25
        }
      }
    },
    noOptionsText: {
      type: String,
      default: 'Tidak Ada Pilihan'
    },
    noResultText: {
      type: String,
      default: 'Pilihan Tidak Ditemukan'
    },
    searchPrepend: {
      type: String,
      default: ''
    },
    plCorrection: {
      type: String,
      default: ''
    },
    searchWrapperClass: {
      type: [String, Array],
      default: 'px-[8px] pt-[8px]'
    },
    listWrapperClass: {
      type: [String, Array],
      default: 'p-[8px]'
    },
    appendListClass: {
      type: [String, Object],
      default: ''
    },
    prependListClass: {
      type: String,
      default: ''
    },
    searchMinChar: {
      type: [String, Number],
      default: 3
    },
    changeAsObject: {
      type: Boolean,
      default: false
    },
    labelColor: {
      type: String,
      default: 'text-gray-800'
    },
    height: {
      type: String,
      default: 'min-h-[38px] max-h-[38px]'
    },
    heightWithSubText: {
      type: String,
      default: 'h-[53px]'
    },
    rounded: {
      type: String,
      default: 'rounded'
    },
    roundedSm: {
      type: String,
      default: 'rounded'
    },
    emptyOptionsOnOpen: {
      type: Boolean,
      default: false
    },
    excludeFromList: {
      type: Array,
      default() {
        return []
      }
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    noSpaceDropdownIcon: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    appendTextOnOpenInMobile: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: 'w-full'
    },
    baseUrl: {
      type: String,
      default: ''
    },
    defaultListHeight: {
      type: String,
      default: 'h-[44px] md:h-auto'
    }
  },
  data() {
    const selectedObj =
      this.value && !this.urlOptions
        ? find(this.options, (opt) => opt[this.valueField] === this.value)
        : this.selectedObject
    let plCorrectionDropdownSelection = this.small ? 'pl-[8px]' : 'pl-[12px]'
    if (this.plCorrection) {
      plCorrectionDropdownSelection = this.plCorrection
    }
    return {
      initialValue: this.value,
      tempValue: this.value,
      isShown: false,
      searchOptions: [],
      selectedOption: selectedObj,
      searchValue: '',
      oldSearchValue: '',
      apiOptions: [],
      metaApi: {
        page: 1,
        pageSize: 25,
        ...this.urlQuery
      },
      isPaginationDone: false,
      isLoadingSearch: false,
      bakOptions: [],
      bakMeta: {
        page: 1,
        pageSize: 25,
        ...this.urlQuery
      },
      plCorrectionDropdownSelection,
      valueUnwatcher: null,
      selectedList: -1,
      stopCursorEv: false,
      containerDropdownHeight: 0,
      containerDropdown: undefined,
      mainStillFocus: false,
      cancelToken: null
    }
  },
  computed: {
    idFix() {
      if (!this.id || (this.name && this.label)) {
        return `${this.name}-${this.label}`
      }
      return this.id
    },
    avatarObject() {
      return this.avatarField || this.textField
    },
    fixedOptions() {
      if (
        this.searchValue?.length <= this.searchMinChar - 1 &&
        !this.urlOptions
      ) {
        return this.options
      } else if (
        this.searchValue?.length <= this.searchMinChar - 1 &&
        this.urlOptions
      ) {
        return this.apiOptions
      }
      return this.searchOptions // either api or static list
    },
    textValue() {
      if (this.tempValue) {
        const selectedOption = find(
          this.fixedOptions,
          (opt) => opt[this.valueField] === this.tempValue
        )
        if (selectedOption) {
          return selectedOption?.[this.textField]
        }
        return this.selectedOption?.[this.textField]
      }
      return this.placeholder
    },
    subTextValue() {
      if (this.tempValue) {
        const selectedOption = find(
          this.fixedOptions,
          (opt) => opt[this.valueField] === this.tempValue
        )
        if (selectedOption) {
          return selectedOption?.[this.subTextField]
        }
        return this.selectedOption?.[this.subTextField]
      }
      return ''
    }
  },
  watch: {
    value(val) {
      this.tempValue = val
      this.$refs.provider.value = val
      validate(val, this.rules, {
        name: this.name || this.label || this.placeholder
      }).then((result) => {
        this.$refs.provider.setFlags({
          valid: result.valid,
          invalid: !result.valid
        })
        this.$refs.provider.setErrors(result.errors)
      })
    },
    tempValue(val) {
      this.$emit('input', val)
    },
    isShown(val) {
      if (val) {
        // document.body.classList.add('overflow-hidden', 'md:overflow-auto')
        this.containerDropdown = this.$refs.containerDropdown
        this.containerDropdownHeight = this.$refs.containerDropdown.offsetHeight
        const selectedExistOnList = findIndex(
          this.fixedOptions,
          (ls) => ls[this.valueField] === this.tempValue
        )
        this.selectedList = selectedExistOnList
        if (this.tempValue && selectedExistOnList > -1) {
          setTimeout(() => {
            scrollIntoView(
              this.$refs[`generatedList${selectedExistOnList}`][0],
              {
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
              }
            )
          }, 100)
        }
      } else {
        this.mainStillFocus = false
        document.body.classList.remove('overflow-hidden', 'md:overflow-auto')
        if (!this.emptyOptionsOnOpen) {
          setTimeout(() => {
            if (this.searchValue.length > this.searchMinChar - 1) {
              this.metaApi[this.searchQuery] = null
              this.metaApi.page = 1
              this.apiOptions = []
              this.isPaginationDone = false
            }
            this.oldSearchValue = ''
            this.searchValue = ''
          }, 100)
        }
        this.$refs.provider.validate()
        this.$refs.provider.setFlags({ touched: true, untouched: false })
      }
    },
    selectedObject(val) {
      this.selectedOption = val
    },
    selectedOption(val) {
      this.$emit('update:selectedObject', val)
    },
    plCorrection(val) {
      this.plCorrectionDropdownSelection = val
    },
    rules(val) {
      if (this.$refs.provider.isRequired) {
        validate(this.value, val, {
          name: this.name || this.label || this.placeholder
        }).then((result) => {
          this.$refs.provider.setFlags({
            valid: result.valid,
            invalid: !result.valid
          })
          // this.$refs.provider.setErrors(result.errors)
        })
      } else {
        // this.$refs.provider.setErrors([])
        this.$refs.provider.setFlags({ valid: true, invalid: false })
      }
    }
  },
  mounted() {
    this.$refs.provider.initialValue = this.value
    this.$refs.provider.value = this.value
    if (this.$refs.provider.isRequired) {
      validate(this.value, this.rules, {
        name: this.name || this.label || this.placeholder
      }).then((result) => {
        this.$refs.provider.setFlags({
          valid: result.valid,
          invalid: !result.valid
        })
        if (this.immediate) {
          this.$refs.provider.setErrors(result.errors)
          this.$refs.provider.validate()
        }
      })
    } else {
      // this.$refs.provider.setErrors([])
      this.$refs.provider.setFlags({ valid: true, invalid: false })
    }
  },
  methods: {
    onClickOuterTrigger() {
      if (!this.disabled) {
        this.isShown = true
      }
    },
    mouseMovePreventer() {
      if (!this.stopCursorEv) this.stopCursorEv = true
      if (!document.body.onmousemove) {
        document.body.onmousemove = () => {
          this.stopCursorEv = false
          document.body.onmousemove = null
        }
      }
    },
    onKeyDownArrow(e) {
      e = e || window.event
      if (this.isShown) {
        if (e.keyCode === 38 && this.selectedList > 0) {
          this.mouseMovePreventer()
          this.selectedList -= 1
          if (this.$refs[`generatedList${this.selectedList}`]?.[0]) {
            scrollIntoView(this.$refs[`generatedList${this.selectedList}`][0], {
              scrollMode: 'if-needed',
              block: 'start',
              inline: 'start'
            })
          }
        } else if (
          e.keyCode === 40 &&
          this.selectedList < this.fixedOptions.length - 1
        ) {
          this.mouseMovePreventer()
          this.selectedList += 1
          if (this.$refs[`generatedList${this.selectedList}`]?.[0]) {
            scrollIntoView(this.$refs[`generatedList${this.selectedList}`][0], {
              scrollMode: 'if-needed',
              block: 'end',
              inline: 'end'
            })
          }
        } else if (e.keyCode === 13 && this.selectedList > -1) {
          this.selectValue(this.fixedOptions[this.selectedList])
        }
      } else if (e.keyCode === 40) {
        this.isShown = true
      }
    },
    onApplyShow() {
      this.$emit('apply-show')
      this.stopCursorEv = false
      document.onkeydown = this.onKeyDownArrow
      setTimeout(() => {
        if (this.isSearchable) {
          this.$refs.searchField.focus()
        } else {
          this.$refs.mainFocus.focus()
        }
      }, 50)
      this.mainStillFocus = false
    },
    onHide() {
      this.$refs.outerTrigger?.focus()
    },
    onClickInnerTrigger() {
      setTimeout(() => {
        this.isShown = false
      }, 0)
      this.$refs.outerTrigger?.focus()
    },
    prevFocus() {
      setTimeout(() => {
        this.isShown = false
      }, 0)
      this.$refs.outerTrigger?.focus()
      emulateTab.backwards()
    },
    nextFocus() {
      setTimeout(() => {
        this.isShown = false
      }, 0)
      this.$refs.outerTrigger.focus()
      emulateTab()
    },
    outerTriggerFocusIn() {
      this.mainStillFocus = true
      document.onkeydown = null
      this.isShown = true
    },
    outerTriggerFocusOut() {
      if (this.isShown) {
        document.onkeydown = this.onKeyDownArrow
      }
    },
    seachOptionsTypeHandler(e) {
      const val = e.target.value
      this.searchValue = val
      if (this.urlOptions) {
        this.bakMeta = clone(this.metaApi)
        this.metaApi[this.searchQuery] =
          val.length > this.searchMinChar - 1 ? val : null
        this.metaApi.page = 1
      }
      if (val.length > this.searchMinChar - 1) {
        this.isLoadingSearch = true
        this.searchOptionsListDebounced(val)
        this.oldSearchValue = val
      } else if (this.oldSearchValue.length > val.length && val.length) {
        this.searchOptionsListDebounced(null, true)
        this.isLoadingSearch = true
        this.apiOptions = []
      }
      this.$emit('search', val)
    },
    seachOptionsTypeHandlerKeydown(e) {
      if ([38, 40].includes(e.keyCode)) {
        e.preventDefault()
      }
    },
    searchOptionsListDebounced: debounce(async function (val, abort) {
      if (abort) {
        this.apiOptions = []
        this.oldSearchValue = ''
        this.isPaginationDone = false
        this.isLoadingSearch = false
        return
      }
      await this.searchOptionsList(val)
      this.selectedList = -1
      this.isLoadingSearch = false
    }, 500),
    async searchOptionsList(val) {
      if (this.urlOptions) {
        const newList = await this.fetchOptions()
        this.searchOptions = newList
      } else {
        const newList = await this.findOptions(val)
        this.searchOptions = newList
      }
    },
    onChangeSelect() {
      this.$emit('change', this.tempValue)
    },
    setSelectedList(val) {
      this.selectedList = val
    },
    selectValue(valueObj) {
      if (valueObj?.[this.valueField] !== this.tempValue) {
        this.selectedOption = valueObj
        this.tempValue = valueObj?.[this.valueField]
        this.$refs.provider.setFlags({ pristine: false })
        this.$emit(
          'change',
          this.changeAsObject
            ? this.selectedOption
            : valueObj?.[this.valueField]
        )
      }
      this.$refs.outerTrigger.focus()
      this.isShown = false
    },
    async onView({ isVisible }) {
      if (
        isVisible &&
        !this.isPaginationDone &&
        (!this.emptyOptionsOnOpen ||
          (this.emptyOptionsOnOpen &&
            this.searchValue?.length >= this.searchMinChar))
      ) {
        const newList = await this.fetchOptions()
        if (
          this.searchValue?.length <= this.searchMinChar - 1 &&
          this.urlOptions
        ) {
          this.apiOptions = [...this.apiOptions, ...newList]
        } else {
          this.searchOptions = [...this.searchOptions, ...newList]
        }
      }
    },
    findOptions(valToFind) {
      const searchInObject = (obj, search) => {
        return Object.values(obj).some((value) => {
          if (typeof value === 'object' && value !== null) {
            return searchInObject(value, search)
          }
          return (
            typeof value === 'string' &&
            value.toLowerCase().includes(search.toLowerCase())
          )
        })
      }

      return this.options.filter((x) => searchInObject(x, valToFind))
    },
    async fetchOptions() {
      const { page, pageSize } = this.metaApi
      const newQuery = {
        ...this.urlQuery,
        page,
        page_size: pageSize
      }
      newQuery[this.searchQuery] = this.metaApi[this.searchQuery]
      const initQuery = initQueryParams(newQuery)
      const queries = encodeQueryData(initQuery)
      const url = `${this.baseUrl || ''}${this.urlOptions}?${queries}`
      if (this.cancelToken) {
        this.cancelToken.cancel('Fetch select data canceled due to new request')
      }
      try {
        this.cancelToken = this.$axios.CancelToken.source()
        const resOptionsList = await this.$axios.$get(url, {
          cancelToken: this.cancelToken.token
        })
        if (this.isPaginated) {
          const newMeta = {
            ...this.metaApi,
            page: this.metaApi.page - 0 + 1
          }
          this.metaApi.page = newMeta.page - 0
          this.metaApi.pageSize = newMeta.page_size - 0
          this.isPaginationDone =
            resOptionsList.data.results.length === 0 ||
            (newMeta.page === 2 &&
              resOptionsList.data.results.length < newMeta.pageSize)
          return resOptionsList.data.results
        } else {
          this.isPaginationDone = true
          return resOptionsList.data
        }
      } catch (e) {
        if (e.response?.status === 404) {
          this.isPaginationDone = true
        }
        // eslint-disable-next-line no-console
        console.error(e)
        return []
      }
    }
  }
}
