import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  stores: [],
  metaStore: [],
  storeCount: 0,
  store: {},
  isAllDataLoaded: false,
  loading: false
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_STORES', [])
      commit('SET_META_STORE', [])
      commit('SET_STORE_COUNT', 0)
      commit('SET_STORE', {})
      commit('SET_IS_ALL_DATA_LOADED', false)
      commit('SET_LOADING', false)
    }

    const { page, pageSize } = state.metaStore
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/business-user/me/store/?${queries}`
    try {
      const resStoresList = await this.$axios.$get(url)
      const allStores = [...state.stores, ...resStoresList.data.results]
      commit('SET_STORE_COUNT', resStoresList.data.count)
      commit('SET_STORES', allStores)
      let newMeta = {
        itemsLength:
          resStoresList.data.count || resStoresList.data.results.length
      }
      const nextMeta = resStoresList.data.next
        ? decodeQueryData(resStoresList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_STORE', newMeta)
      return { totalCount: resStoresList.data.count, currentData: allStores }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
