import {
  mapMutationsHelper,
  mapGettersHelper,
  isEmpty,
  encodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  loading: false,
  subdistrict: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, { query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/v1/location/subdistrict${queries}`
    try {
      const resSubdistrictList = await this.$prodApi.get(url)
      commit('SET_SUBDISTRICT', await resSubdistrictList.data)
      commit('SET_LOADING', false)
      return resSubdistrictList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchById(state, idLocation) {
    const url = `location/${idLocation}/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async fetchByCity({ commit }, { idCity, query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/v1/location/city/${idCity}/subdistrict${queries}`
    try {
      const resSubdistrictList = await this.$prodApi.get(url)
      commit('SET_SUBDISTRICT', resSubdistrictList.data)
      commit('SET_LOADING', false)
      return resSubdistrictList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchAllV2({ commit }, { query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/v2/location/subdistrict${queries}`
    try {
      const resSubdistrictList = await this.$prodApi.get(url)
      commit('SET_SUBDISTRICT', await resSubdistrictList.data)
      commit('SET_LOADING', false)
      return resSubdistrictList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchByCityV2({ commit }, { idCity, query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/v2/location/city/${idCity}/subdistrict${queries}`
    try {
      const resSubdistrictList = await this.$prodApi.get(url)
      commit('SET_SUBDISTRICT', resSubdistrictList.data)
      commit('SET_LOADING', false)
      return resSubdistrictList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchAllReal({ commit }, { query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/location/${queries}`
    try {
      const resSubdistrict = await this.$axios.$get(url)
      commit('SET_SUBDISTRICT', await resSubdistrict.data.results)
      commit('SET_LOADING', false)
      return resSubdistrict
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },
  async fetchByCityReal({ commit }, { cityId, query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/location/city/${cityId}/subdistrict/${queries}`
    try {
      const resSubdistrict = await this.$axios.$get(url)
      commit('SET_SUBDISTRICT', resSubdistrict.data)
      commit('SET_LOADING', false)
      return resSubdistrict
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  }
}
