export default function ({ $axios, error: nuxtError }) {
  $axios.onError((error) => {
    if (error.message.includes('have expired') && process.browser) {
      window.onbeforeunload = () => null
      location.reload()
      return
    }
    const code = parseInt(error.response && error.response.status)
    const newError = error
    switch (code) {
      case 401:
        newError.message = 'Bad Credentials'
        Object.assign(newError, { statusCode: code })
        break
      case 502:
        newError.message = 'Network Error'
        Object.assign(newError, { statusCode: code })
        break
    }
    throw error
  })
}
