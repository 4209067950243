export const actions = {
  async upload({ commit }, image) {
    try {
      const formData = new FormData()
      formData.append('image', image)
      const resUpload = await this.$axios.$post('/image/', formData)
      return resUpload
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadPublic({ commit }, image) {
    try {
      const formData = new FormData()
      formData.append('image', image)
      const resUploadPublic = await this.$axios.$post(
        '/public-image/',
        formData
      )
      return resUploadPublic
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadKYC({ commit }, file) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const resUploadKYC = await this.$axios.$post('/kyc-file/', formData)
      return resUploadKYC
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
