import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  subscriptionOrders: [],
  metaSubscriptionOrders: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_SUBSCRIPTION_ORDERS', [])
      commit('SET_META_SUBSCRIPTION_ORDERS', {})
    }

    const { page, pageSize } = state.metaSubscriptionOrders
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/subscription-order/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const data = [...state.subscriptionOrders, ...res.data.results]
      commit('SET_SUBSCRIPTION_ORDERS', data)
      let newMeta = {
        itemsLength: res.data.count || res.data.results.length
      }
      const nextMeta = res.data.next
        ? decodeQueryData(res.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_SUBSCRIPTION_ORDERS', newMeta)
      return { totalCount: res.data.count, currentData: data }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    try {
      const { id, status } = payload
      const res = await this.$axios.$put(`/subscription-order/${id}/`, {
        status
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
