var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1 flex-col justify-between"},[_c('div',{staticClass:"relative overflow-visible"},[_c('div',{staticClass:"overflow-visible",class:{
        'overflow-x-auto': _vm.scrollX
      },style:(("min-height: " + (_vm.fixedOptions.perPage * _vm.rowHeight + 49) + "px;"))},[_c('table',{staticClass:"w-full text-[14px]",class:{
          'b-spacing border-separate': _vm.isBSpacing,
          'table-fixed': _vm.fixedLayout
        }},[_c('thead',{class:{
            hidden: !_vm.isTableHeaderShow,
            'md:table-header-group': _vm.isTableHeaderShowMd,
            'md:hidden': !_vm.isTableHeaderShowMd
          }},[_c('tr',_vm._l((_vm.computedHeaders),function(header,index){return _c('th',{key:("tableHeader-" + index),staticClass:"text-[12px] font-medium",class:[
                header.textColor || 'text-black-500',
                header.align || 'text-left',
                header.headerClass,
                !header.width && "max-w-0",
                {
                  'relative left-0 md:sticky': header.freeze && header.width
                },
                header.padding || 'px-[16px] py-[10px]'
              ],style:(header.width && ("width: " + (header.width) + "px"))},[_vm._t(("header-" + (header.value)),function(){return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('SclvIcon',{attrs:{"icon":header.icon}})]})],2)}),0)]),_vm._v(" "),_c('tbody',{staticClass:"space-y-[8px]",class:{
            'divide-y-2 divide-gray-400': _vm.isRowDivider
          }},[(_vm.loading)?_vm._t("loading",function(){return [_c('tr',[_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"relative mx-auto mb-[16px] flex h-[100px] w-[100px] items-center justify-center",class:[_vm.loadingMt]},[_c('div',{staticClass:"double-bounce absolute left-0 top-0 h-full w-full bg-white opacity-20",class:{ 'rounded-full': _vm.isRounded }}),_vm._v(" "),_c('div',{staticClass:"double-bounce double-bounce2 absolute left-0 top-0 h-full w-full bg-white opacity-50",class:{ 'rounded-full': _vm.isRounded }}),_vm._v(" "),_c('img',{staticClass:"z-[100] h-[90px] w-[90px]",attrs:{"src":require('~/assets/logo/logo.svg'),"alt":"Loading..."}})]),_vm._v(" "),_c('span',{staticClass:"relative block w-full border-b-2 border-transparent px-3 text-center text-gray-800"},[_vm._v("\n                  "+_vm._s(_vm.loadingText)+"\n                ")])])])]}):(_vm.itemsBody.length === 0)?_vm._t("no-data",function(){return [_c('tr',[_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('span',{staticClass:"relative block w-full px-[16px] py-[20px] text-center text-gray-800",class:("px-[" + _vm.sizeMobileCard + "]")},[_vm._v("\n                  "+_vm._s(_vm.noDataText)+"\n                ")])])])]}):_vm._t("body",function(){return _vm._l((_vm.itemsBody),function(item,index){return _c('tr',{key:("tableBodyRow-" + index),staticClass:"text-black-700 table-row",on:{"click":function (e) { return _vm.onTrClick(item, e); }}},_vm._l((_vm.computedHeaders),function(header,i){return _c('td',{key:("tableBodyCol-" + i),staticClass:"px-[10px] md:px-[16px]",class:[
                  _vm.rowPy,
                  header.class,
                  header.freeze && header.width ? 'sticky left-0' : '',
                  i === 0 && _vm.isRounded && 'rounded-l',
                  i === 0 && _vm.hideLastColMobile && _vm.isRounded && 'rounded-r',
                  i === _vm.computedHeaders.length - 1 &&
                    _vm.isRounded &&
                    'rounded-r',
                  i !== 0 &&
                    !_vm.showAllColumns &&
                    (_vm.hideLastColMobile || i !== _vm.computedHeaders.length - 1) &&
                    'hidden md:table-cell',
                  header.color || 'md:bg-gray-150 bg-white'
                ],style:(header.width && ("width: " + (header.width) + "px"))},[_vm._t(header.value,function(){return [_vm._v("\n                  "+_vm._s(item[header.value])+"\n                ")]},null,{
                    item: item,
                    value: item[header.value],
                    index: index
                  })],2)}),0)})})],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }