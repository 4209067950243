
import { mapGetters } from 'vuex'

export default {
  name: 'FormMixin',
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.getFormChanged) {
      this.showFormChangedPromt(next)
      next(false)
    } else {
      // Navigate to next view
      next()
    }
  },
  asyncData({ store }) {
    store.dispatch('common/setFormChanged', false)
  },
  computed: {
    ...mapGetters('common', ['getFormChanged'])
  },
  methods: {
    showFormChangedPromt(next) {
      this.$store.dispatch('common/setFormChangedModalShow', true)
      this.$store.dispatch('common/setFormChangedNextObj', next)
    }
  }
}
