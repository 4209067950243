export default (ctx, inject) => {
  const auth = {
    get user() {
      return ctx.store.getters['user/getUser']
    },
    fetchUser: async () => {
      try {
        const res = await ctx.store.dispatch('user/fetchDetail')
        // eslint-disable-next-line camelcase
        let { current_business_user, ...detailUser } = res
        // eslint-disable-next-line camelcase
        let { business, ...account } = current_business_user || {}
        if (!business) {
          await ctx.store.dispatch('business/setBusiness', [])
          const allBusiness = await ctx.store.dispatch('business/fetchAll', {
            page: 1
          })
          if (allBusiness[0]) {
            await ctx.store.dispatch(
              'business/changeBusiness',
              allBusiness[0].id
            )
            const newResUser = await ctx.store.dispatch('user/fetchDetail')
            const {
              // eslint-disable-next-line camelcase
              current_business_user: new_current_business_user,
              ...newDetailUser
            } = newResUser
            detailUser = newDetailUser
            // eslint-disable-next-line camelcase
            current_business_user = new_current_business_user
            const { business: newBusiness, ...newAccount } =
              // eslint-disable-next-line camelcase
              new_current_business_user || {}
            business = newBusiness
            account = newAccount
          }
        }
        let invitationStatus = account.is_verified ? 'accepted' : 'invited'
        if (account.is_registering) {
          invitationStatus = 'registering'
        }
        await ctx.store.dispatch('user/setUser', {
          currentBusiness: {
            idMember: account.id,
            invitationStatus,
            businessPhone: account.business_phone,
            isUserBusinessActive: !account.is_locked,
            ...business
          },
          detail: detailUser,
          account
        })
      } catch (err) {
        await ctx.store.dispatch('user/setUser', null)
      }
    },
    logout: () => {
      ctx.$cookies.set(`at`, '', {
        path: '/',
        maxAge: 1000 * 3600 * 24 * 30
      })
      ctx.$cookies.set(`rt`, '', {
        path: '/',
        maxAge: 1000 * 3600 * 24 * 30
      })
      ctx.$notif?.socket?.disconnect()
    }
  }

  inject('auth', auth)
}
