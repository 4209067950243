import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  isEmpty,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  payment: [],
  metaPayment: [],
  retrievePayment: [],
  service: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { isFirst = false, ...query } = {}) {
    const { page, pageSize } = state.metaPayment
    if (isFirst) {
      commit('SET_PAYMENT', [])
      commit('SET_META_PAYMENT', {})
    }
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/payment/?${queries}`
    try {
      const resPayment = await this.$axios.$get(url)
      const allPayment = [...state.payment, ...resPayment.data.results]
      commit('SET_PAYMENT', allPayment)
      const newMeta = decodeQueryData(resPayment.data.next?.split('?')?.[1])
      if (newMeta) {
        commit('SET_META_PAYMENT', {
          itemsLength: resPayment.data.count || resPayment.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return allPayment
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchRetrieve({ commit }, paymentId) {
    const url = `/payment/${paymentId}/`
    try {
      const resPaymentList = await this.$axios.$get(url)
      commit('SET_RETRIEVE_PAYMENT', resPaymentList.data)
      return resPaymentList.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAndUpdate({ commit }) {
    const url = `/payment/`
    try {
      commit('SET_PAYMENT', [])
      commit('SET_META_PAYMENT', {
        page: 1
      })
      const resPaymentList = await this.$axios.$get(url)
      commit('SET_PAYMENT', resPaymentList.data.results)
      return resPaymentList.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchPG({ commit }) {
    const url = `/payment/pg-account/`
    try {
      const resPaymentPg = await this.$axios.$get(url)
      return resPaymentPg
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchService({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/financial-entity/${queries}`
    try {
      const resService = await this.$axios.$get(url)
      commit('SET_SERVICE', resService.data.results)
      return resService.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async add(_store, payload) {
    const url = `/payment/`
    try {
      const {
        method,
        financialEntity,
        accountHolder,
        accountNumber,
        xenditApiKey,
        xenditCallbackToken,
        xenditVaBankCodes,
        xenditEwallets,
        isXenditQris,
        isXenditInvoice
      } = payload
      const resAddPayment = await this.$axios.$post(url, {
        method,
        financial_entity: financialEntity,
        account_holder: accountHolder,
        account_number: accountNumber,
        xendit_api_key: xenditApiKey,
        xendit_callback_token: xenditCallbackToken,
        xendit_va_bank_codes: xenditVaBankCodes,
        xendit_ewallets: xenditEwallets,
        is_xendit_qris: isXenditQris,
        is_xendit_invoice: isXenditInvoice
      })
      return resAddPayment
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async edit(_store, { paymentId, ...payload }) {
    try {
      const url = `/payment/${paymentId}/`
      const {
        method,
        financialEntity,
        accountHolder,
        accountNumber,
        xenditApiKey,
        xenditCallbackToken,
        xenditVaBankCodes,
        xenditEwallets,
        isXenditQris,
        isXenditInvoice
      } = payload
      const finalPayload = {
        method,
        financial_entity: financialEntity,
        account_holder: accountHolder,
        account_number: accountNumber,
        xendit_api_key: xenditApiKey,
        xendit_callback_token: xenditCallbackToken,
        xendit_va_bank_codes: xenditVaBankCodes,
        xendit_ewallets: xenditEwallets,
        is_xendit_qris: isXenditQris,
        is_xendit_invoice: isXenditInvoice
      }
      const resEditPayment = await this.$axios.$put(url, finalPayload)
      return resEditPayment
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete(_store, paymentId) {
    try {
      const resDeletePayment = await this.$axios.$delete(
        `payment/${paymentId}/`
      )
      return resDeletePayment.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchAllCustom({ commit }, query) {
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/payment/${queries}`
    try {
      const resPayment = await this.$axios.$get(url)
      commit('SET_PAYMENT', resPayment.data.results)
      return resPayment
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  resetPayment({ commit }) {
    commit('SET_PAYMENT', [])
  }
}
