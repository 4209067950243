export const actions = {
  async fetchById({ commit }, idVariant) {
    const url = `variant/${idVariant}/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async storeData(store, payload) {
    try {
      const {
        sku,
        productId,
        price,
        weight,
        cogsPrice,
        option1Value,
        option2Value,
        option3Value
      } = payload
      const resStoreVariant = await this.$axios.$post('variants/', {
        sku,
        product: productId,
        cogs: cogsPrice || undefined,
        price,
        weight,
        option1_value: option1Value,
        option2_value: option2Value,
        option3_value: option3Value
      })
      return resStoreVariant.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async deleteData(store, idSubproduct) {
    try {
      await this.$axios.$delete(`variants/${idSubproduct}/`)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async update(store, { idVariant, ...payload }) {
    try {
      const {
        option1Value,
        option2Value,
        option3Value,
        sku,
        price,
        weight,
        cogsPrice
      } = payload
      const resUpdateVariant = await this.$axios.$put(
        `/variants/${idVariant}/`,
        {
          option1_value: option1Value,
          option2_value: option2Value,
          option3_value: option3Value,
          sku,
          price,
          weight,
          cogs: cogsPrice
        }
      )
      return resUpdateVariant
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
