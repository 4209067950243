import {
  mapMutationsHelper,
  mapGettersHelper,
  initQueryParams,
  encodeQueryData,
  decodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  bundles: [],
  metaBundles: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  setBundles({ commit }, bundles) {
    commit('SET_BUNDLES', bundles)
  },

  setMetaBundles({ commit }, metaBundles) {
    commit('SET_META_BUNDLES', metaBundles)
  },

  async fetchAllByStoreId(
    { commit, state },
    { storeId, query, isFirst = false } = {}
  ) {
    if (isFirst) commit('SET_BUNDLES', [])
    const { page, pageSize } = state.metaBundles
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/store/${storeId}/bundle/?${queries}`
    try {
      const resBundleList = await this.$axios.$get(url)
      const allBundle = [...state.bundles, ...resBundleList.data.results]
      commit('SET_BUNDLES', allBundle)
      let newMeta = {
        itemsLength:
          resBundleList.data.count || resBundleList.data.results.length
      }
      const nextMeta = resBundleList.data.next
        ? decodeQueryData(resBundleList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_BUNDLES', newMeta)
      return { totalCount: resBundleList.data.count, currentData: allBundle }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, payload) {
    const { storeId, bundleId } = payload
    const url = `/store/${storeId}/bundle/${bundleId}/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
