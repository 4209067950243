//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { onChangeCompanyBusiness, mappingCurrency } from '@/utils/helpers.js'

export default {
  name: 'MainSidebar',
  filters: {
    mappingCurrency
  },
  data() {
    const mainMenu = [
      {
        name: 'Dashboard',
        icon: 'home-sclvico',
        link: '/dashboard',
        hasPermission: true
      },
      {
        name: 'Product',
        icon: 'ic-cube',
        link: '/products',
        hasPermission: false
      },
      {
        name: 'Bundle',
        icon: 'cube-collection-2-sclvico',
        link: '/bundles',
        hasPermission: false
      },
      {
        name: 'Landing Page',
        icon: 'doc-sclvico',
        link: '/pages',
        hasPermission: false
      },
      {
        name: 'Order',
        icon: 'ic-orders',
        link: '/order',
        hasPermission: false
      },
      {
        name: 'Store',
        icon: 'ic-store',
        link: '/store',
        hasPermission: false
      },
      {
        name: 'Customer',
        icon: 'user-group-sclvico',
        link: '/customer',
        hasPermission: false
      },
      {
        name: 'Inventory',
        icon: 'ic-cube-collection',
        link: '/inventory',
        hasPermission: false
      },
      {
        name: 'Analytics',
        icon: 'bar-chart-sclvico',
        link: '/analytics',
        hasPermission: true
      },
      {
        name: 'Ongkir',
        icon: 'ic-shipping',
        link: '/shipping-fee',
        hasPermission: true
      },
      {
        name: 'Setting',
        icon: 'ic-config',
        link: '/setting',
        hasPermission: true
      },
      {
        name: 'Support',
        icon: 'support-sclvico',
        link: '/support',
        hasPermission: true
      },
      {
        name: 'Tutorial',
        icon: 'information-sclvico',
        link: '/tutorial',
        hasPermission: true
      }
    ]
    return {
      isCancel: false,
      mainMenu,
      breakpointWatcher: null,
      canViewBalance: false,
      businessCountAlert: {
        check: false,
        maxBusiness: 1,
        ownedBusinessCount: 0
      },
      isLoadingBalance: true,
      businessCount: this.$auth.user.detail?.business_count ?? 0,
      canEditBusiness: false
    }
  },
  async fetch() {
    if (
      this.$auth?.user?.currentBusiness?.invitationStatus !== 'invited'
      // this.$auth?.user?.currentBusiness?.isUserBusinessActive
    ) {
      this.canEditBusiness = await this.$store.dispatch(
        'permission/hasPermission',
        { user: this.$auth.user, permission: 'edit_business' }
      )
      this.mainMenu.map(async (menuList, i) => {
        if (menuList.name === 'Product') {
          const canViewProducts = await this.$store.dispatch(
            'permission/hasPermission',
            { user: this.$auth.user, permission: 'view_product' }
          )
          this.mainMenu[i].hasPermission = canViewProducts
        } else if (menuList.name === 'Bundle') {
          const canViewProducts = await this.$store.dispatch(
            'permission/hasPermission',
            { user: this.$auth.user, permission: 'view_bundle' }
          )
          this.mainMenu[i].hasPermission = canViewProducts
        } else if (menuList.name === 'Inventory') {
          const canViewInventoryBalance = await this.$store.dispatch(
            'permission/hasPermission',
            { user: this.$auth.user, permission: 'view_inventory_flow' }
          )
          const canViewInventoryHistory = await this.$store.dispatch(
            'permission/hasPermission',
            { user: this.$auth.user, permission: 'view_inventory_flow' }
          )
          this.mainMenu[i].hasPermission =
            (canViewInventoryBalance || canViewInventoryHistory) &&
            this.$auth.user.detail.current_business_subscription
              ?.current_pricing_plan?.subscription_plan
              ?.is_physical_product_allowed
        } else if (menuList.name === 'Order') {
          const canViewOrderList = await this.$store.dispatch(
            'permission/hasPermission',
            { user: this.$auth.user, permission: 'view_order' }
          )
          this.mainMenu[i].hasPermission = canViewOrderList
        } else if (menuList.name === 'Customer') {
          const canViewCusList = await this.$store.dispatch(
            'permission/hasPermission',
            { user: this.$auth.user, permission: 'view_customer' }
          )
          this.mainMenu[i].hasPermission = canViewCusList
        } else if (menuList.name === 'Store') {
          const canViewStoreList = await this.$store.dispatch(
            'permission/hasPermission',
            { user: this.$auth.user, permission: 'list_store' }
          )
          this.mainMenu[i].hasPermission = canViewStoreList
        } else if (menuList.name === 'Landing Page') {
          const canViewWebStudio = await this.$store.dispatch(
            'permission/hasPermission',
            { user: this.$auth.user, permission: ['view_form', 'view_page'] }
          )
          this.mainMenu[i].hasPermission = canViewWebStudio
        } else if (menuList.name === 'Ongkir') {
          this.mainMenu[i].hasPermission =
            this.$auth.user.detail.current_business_subscription?.current_pricing_plan?.subscription_plan?.is_physical_product_allowed
        }
      })
      this.canViewBalance = await this.$store.dispatch(
        'permission/hasPermission',
        { user: this.$auth.user, permission: ['view_business_transaction'] }
      )
    }
  },
  fetchKey: 'dashboard-sidebar',
  computed: {
    mini: {
      get() {
        return this.$store.getters['common/getSidebarMini']
      },
      set(val) {
        this.$store.dispatch('common/setSidebarMini', val)
      }
    },
    isBusinessAddModelShow: {
      get() {
        return this.getBusinessAddModalShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessAddModalShow', val)
      }
    },
    ...mapGetters('business', ['getOwnedBusinessCount']),
    ...mapGetters('permission', ['getPagePermission']),
    ...mapGetters('notification', ['getUnread']),
    ...mapGetters('common', ['getBusinessAddModalShow', 'getSidebarKey']),
    ...mapGetters('balance', ['getCurrentBalance', 'getOnHoldBalance']),
    getUserHasBusiness() {
      return !!this.$auth.user.detail?.current_business
    },
    getBusinessDetail() {
      return this.$auth.user.currentBusiness || {}
    },
    getIsOwner() {
      return (
        (this.$auth.user.currentBusiness || {}).owner.id ===
        this.$auth.user.detail.id
      )
    },
    fixedBalance() {
      return parseInt(this.getCurrentBalance) + parseInt(this.getOnHoldBalance)
    },
    isBusinessActive() {
      return this.$auth?.user?.currentBusiness?.isUserBusinessActive
    }
  },
  watch: {
    getSidebarKey() {
      this.$fetch()
    }
  },
  mounted() {
    if (this.$mq === 'md' || this.$mq === 'sm') {
      this.mini = true
    } else {
      this.mini = false
    }
    this.breakpointWatcher = this.$watch('$mq', (val) => {
      if (val === 'md' || val === 'sm') {
        this.mini = true
      } else {
        this.mini = false
      }
    })
    this.$store
      .dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: ['view_business_transaction']
      })
      .then((canViewBalance) => {
        if (!canViewBalance) {
          this.isLoadingBalance = false
          return
        }
        this.$store
          .dispatch('balance/fetchBalance')
          .catch(() => {
            this.$toast.showMessage({
              message: 'Gagal memuat data balance',
              color: 'danger'
            })
          })
          .finally(() => {
            this.isLoadingBalance = false
          })
      })
  },
  methods: {
    onChangeCompanyBusiness,
    daysDifference(dateString) {
      const inputDate = new Date(dateString)
      const today = new Date()

      const differenceInTime = inputDate - today

      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      )

      return differenceInDays < 0 ? 0 : differenceInDays
    },
    onAddClick() {
      this.isBusinessAddModelShow = true
      this.$emit('add-business')
    },
    yesCancel() {
      this.isCancel = false
      this.isBusinessAddModelShow = false
    },
    noCancel() {
      this.isCancel = false
    },
    showCancel() {
      this.isCancel = true
    },
    navigatePage(evt, navigate) {
      navigate(evt)
    },
    callScalev() {
      this.businessCountAlert.check = false
      open('https://t.me/scalev_id', '_blank')
    }
  }
}
