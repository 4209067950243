import {
  mapGettersHelper,
  mapMutationsHelper,
  initQueryParams,
  encodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  discountCodes: [],
  discountCodesMeta: {
    page: 1,
    pageSize: 25
  }
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_DISCOUNT_CODES', [])
      commit('SET_DISCOUNT_CODES_META', {
        page: 1,
        pageSize: 25
      })
    }
    const { page, pageSize } = state.discountCodesMeta
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/discount-code/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const allDiscountCodes = [...state.discountCodes, ...res.data.results]
      commit('SET_DISCOUNT_CODES', allDiscountCodes)
      const nextMeta = {
        page: page + 1,
        pageSize
      }
      commit('SET_DISCOUNT_CODES_META', nextMeta)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/discount-code/${id}`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    try {
      const {
        isEnabled,
        code,
        appliedTo,
        amountType,
        percentage,
        isMaxAmount,
        maxAmount,
        amount,
        isUsageLimit,
        usageLimit,
        isExpiry,
        expiryTime,
        isLimitedToPages,
        pageIds,
        isMinimumRevenue,
        minimumRevenue,
        isLimitedToPaymentMethods,
        paymentMethods
      } = payload
      const url = `${this.$config.apiUrlV2}/discount-code/`
      const res = await this.$axios.$post(url, {
        is_enabled: isEnabled,
        code,
        applied_to: appliedTo,
        amount_type: amountType,
        percentage,
        is_max_amount: isMaxAmount,
        max_amount: maxAmount,
        amount,
        is_usage_limit: isUsageLimit,
        usage_limit: usageLimit,
        is_expiry: isExpiry,
        expiry_time: expiryTime,
        is_limited_to_pages: isLimitedToPages,
        page_ids: pageIds,
        is_minimum_revenue: isMinimumRevenue,
        minimum_revenue: minimumRevenue,
        is_limited_to_payment_methods: isLimitedToPaymentMethods,
        payment_methods: paymentMethods
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    try {
      const {
        id,
        isEnabled,
        isUsageLimit,
        usageLimit,
        isExpiry,
        expiryTime,
        isLimitedToPages,
        pageIds,
        isMinimumRevenue,
        minimumRevenue,
        isLimitedToPaymentMethods,
        paymentMethods
      } = payload
      const url = `${this.$config.apiUrlV2}/discount-code/${id}`
      const res = await this.$axios.$patch(url, {
        is_enabled: isEnabled,
        is_usage_limit: isUsageLimit,
        usage_limit: usageLimit,
        is_expiry: isExpiry,
        expiry_time: expiryTime,
        is_limited_to_pages: isLimitedToPages,
        page_ids: pageIds,
        is_minimum_revenue: isMinimumRevenue,
        minimum_revenue: minimumRevenue,
        is_limited_to_payment_methods: isLimitedToPaymentMethods,
        payment_methods: paymentMethods
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/discount-code/${id}`
      await this.$axios.$delete(url)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
