import {
  mapGettersHelper,
  mapMutationsHelper,
  initQueryParams,
  encodeQueryData,
  decodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  facebookCustomAudiences: [],
  facebookAdAccounts: [],
  metaFacebookAdAccounts: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async retrieve({ commit }) {
    try {
      const url =
        this.$config.envApp === 'development'
          ? `${this.$config.apiUrlV2}/business/fb/`
          : `/business/${this.$auth.user.currentBusiness.id}/fb/`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async set({ commit }, payload) {
    const { accessToken } = payload
    try {
      const url =
        this.$config.envApp === 'development'
          ? `${this.$config.apiUrlV2}/business/fb-login/`
          : `/business/${this.$auth.user.currentBusiness.id}/fb-login/`
      const res = await this.$axios.$patch(url, {
        fb_access_token: accessToken
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async syncAdAccount({ commit }) {
    try {
      const url =
        this.$config.envApp === 'development'
          ? `${this.$config.apiUrlV2}/business/sync-fb-adaccounts/`
          : `/business/${this.$auth.user.currentBusiness.id}/sync-fb-adaccounts/`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllCustomAudienceByAccountId({ commit }, payload) {
    commit('SET_FACEBOOK_CUSTOM_AUDIENCES', [])
    const { accountId } = payload
    const url =
      this.$config.envApp === 'development'
        ? `${this.$config.apiUrlV2}/business/fb-customaudiences/?account_id=${accountId}`
        : `/business/${this.$auth.user.currentBusiness.id}/fb-customaudiences/?account_id=${accountId}`
    try {
      const res = await this.$axios.$get(url)
      commit('SET_FACEBOOK_CUSTOM_AUDIENCES', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  resetAdAccountsAndPages({ commit }) {
    commit('SET_META_FACEBOOK_AD_ACCOUNTS', {})
    commit('SET_FACEBOOK_AD_ACCOUNTS', [])
  },

  async fetchAllAdAccounts({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_META_FACEBOOK_AD_ACCOUNTS', {})
      commit('SET_FACEBOOK_AD_ACCOUNTS', [])
    }
    const { page, pageSize } = state.metaFacebookAdAccounts
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url =
      this.$config.envApp === 'development'
        ? `${this.$config.apiUrlV2}/business/fb-adaccounts/?${queries}`
        : `/business/${this.$auth.user.currentBusiness.id}/fb-adaccounts/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const addAdAccounts = [...state.facebookAdAccounts, ...res.data.results]
      commit('SET_FACEBOOK_AD_ACCOUNTS', addAdAccounts)
      let newMeta = {
        itemsLength: res.data.count || res.data.results.length
      }
      const nextMeta = res.data.next
        ? decodeQueryData(res.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_FACEBOOK_AD_ACCOUNTS', newMeta)
      return { totalCount: res.data.count, currentData: addAdAccounts }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async remove({ commit }) {
    try {
      const url =
        this.$config.envApp === 'development'
          ? `${this.$config.apiUrlV2}/business/fb-logout/`
          : `/business/${this.$auth.user.currentBusiness.id}/fb-logout/`
      const res = await this.$axios.$patch(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
