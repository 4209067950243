import {
  mapMutationsHelper,
  mapGettersHelper
} from '@/utils/helpers.js'

export const state = () => ({
  businessRole: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchBusinessRole({ commit }) {
    const url = `business_role/`
    try {
      const resBusinessRoleList = await this.$axios.$get(url)
      commit('SET_BUSINESS_ROLE', resBusinessRoleList.data)
      return resBusinessRoleList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
