import {
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaBalanceHistories: {},
  balanceHistories: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_META_BALANCE_HISTORIES', {})
      commit('SET_BALANCE_HISTORIES', [])
    }
    const { page, pageSize } = state.metaBalanceHistories
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/business/balance-history/?${queries}`
    try {
      const resBalanceHistories = await this.$axios.$get(url)
      const combinedBalanceHistories = [
        ...state.balanceHistories,
        ...resBalanceHistories.data.results
      ]
      commit('SET_BALANCE_HISTORIES', combinedBalanceHistories)
      let newMeta = {
        itemsLength:
          resBalanceHistories.data.count ||
          resBalanceHistories.data.results.length
      }
      const nextMeta =
        resBalanceHistories.data.count > combinedBalanceHistories.length
          ? { page: initQuery.page + 1, page_size: initQuery.page_size }
          : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_BALANCE_HISTORIES', newMeta)
      return {
        totalCount: resBalanceHistories.data.count,
        currentData: combinedBalanceHistories
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async download({ commit }, { query } = {}) {
    const initQuery = initQueryParams({
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/business/balance-history/download/?${queries}`
    try {
      return await this.$axios.get(url)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
