import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaInvitationLink: {},
  invitationLink: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { isFirst = false, ...query } = {}) {
    if (isFirst) {
      commit('SET_INVITATION_LINK', [])
      commit('SET_META_INVITATION_LINK', {})
    }
    const { page, pageSize } = state.metaInvitationLink
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 20 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `invitation-code/?${queries}`
    try {
      const resInvitationLinkList = await this.$axios.$get(url)
      const allInvitationLinkList = [
        ...state.invitationLink,
        ...resInvitationLinkList.data.results
      ]
      commit('SET_INVITATION_LINK', allInvitationLinkList)
      let newMeta = {
        itemsLength:
          resInvitationLinkList.data.count ||
          resInvitationLinkList.data.results.length
      }
      const nextMeta = resInvitationLinkList.data.next
        ? decodeQueryData(resInvitationLinkList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_INVITATION_LINK', newMeta)
      return {
        totalCount: resInvitationLinkList.data.count,
        currentData: allInvitationLinkList
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async storeData(store, payload) {
    try {
      const { role, code } = payload
      const resCreateLinkInvitation = await this.$axios.$post(
        'invitation-code/',
        {
          role,
          code
        }
      )
      return resCreateLinkInvitation.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async checkCode(store, code) {
    try {
      const resCheckLinkInvitation = await this.$axios.$get(
        `/invitation-code/check/${code}/`
      )
      return resCheckLinkInvitation.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async fetchById(store, invitationId) {
    const url = `invitation-code/${invitationId}/`
    try {
      const resInvitationDetail = await this.$axios.$get(url)
      return resInvitationDetail.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async delete(store, invitationId) {
    try {
      const resDeleteInvitation = await this.$axios.$delete(
        `invitation-code/${invitationId}/`
      )
      return resDeleteInvitation.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async checkInvitation(stoire, invitationCode) {
    try {
      const resCheckInvitationCode = await this.$axios.$get(
        `/invitation-code/check/${invitationCode}/`
      )
      return resCheckInvitationCode.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async verifyInvitation(store, invitationCode) {
    try {
      const resVerifyInvitationCode = await this.$axios.$get(
        `/invitation-code/verify/${invitationCode}/`
      )
      return resVerifyInvitationCode.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
