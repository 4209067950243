var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"provider",staticClass:"placeholder-black-400 w-full",class:{ 'mb-[3px]': !_vm.hideMessage },attrs:{"vid":_vm.vid,"name":_vm.name || _vm.label || _vm.placeholder,"rules":_vm.rules,"debounce":_vm.debounce,"mode":_vm.mode,"disabled":_vm.disabled,"immediate":_vm.immediate,"tag":"fieldset"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('div',_vm._b({attrs:{"name":"default"}},'div',{ valid: valid },false),[_vm._t("label",function(){return [(_vm.label)?_c('label',{class:("block font-medium " + _vm.labelSize + " " + _vm.labelColor + " mb-[10px]"),attrs:{"for":_vm.idFix}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.label && _vm.rules.split('|').includes('required'))?_c('span',{staticClass:"text-red-900",domProps:{"textContent":_vm._s('*')}}):_vm._e()]):_vm._e()]}),_vm._v(" "),_c('div',{staticClass:"flex w-full"},[_vm._t("prepend-outer"),_vm._v(" "),_c('div',{staticClass:"focus-within:border-primary relative flex w-full transform flex-wrap items-center overflow-hidden border-2 transition duration-100 ease-in-out",class:[
          _vm.disabled ? 'bg-black-75 text-black-500' : _vm.bg,
          { 'border-red-600': !!errors[0] || _vm.customError },
          _vm.textSize,
          _vm.rounded
        ]},[_vm._t("prepend",function(){return [(_vm.prepend)?_c('div',{staticClass:"flex h-full bg-gray-200 px-3"},[_c('span',{staticClass:"whitespace-no-wrap text-grey-dark flex items-center font-medium leading-normal"},[_vm._v("\n              "+_vm._s(_vm.prepend)+"\n            ")])]):_vm._e()]}),_vm._v(" "),(_vm.isMoney)?_c('CurrencyField',{ref:"moneyField",staticClass:"relative w-px flex-1 flex-auto flex-shrink flex-grow px-[12px] leading-normal focus:outline-none",class:[
            _vm.height,
            _vm.inputClass,
            _vm.disabled ? 'bg-gray-50 text-black opacity-100' : _vm.bg,
            {
              'text-right': _vm.isMoney && !_vm.moneyTextLeft
            }
          ],attrs:{"id":_vm.idFix ? _vm.idFix : undefined,"placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"autocorrect":"off","name":_vm.name,"disabled":_vm.disabled,"readonly":_vm.readonly,"options":_vm.innerCurrencyFieldOptions,"pattern":("" + (!_vm.noPattern ? '*[0-9]' : '')),"inputmode":("" + (!_vm.noPattern ? 'numeric' : ''))},on:{"change":_vm.onChange,"paste":_vm.onPaste,"keydown":_vm.onKeydown,"keyup":_vm.onKeyup,"focus":_vm.onFocus,"blur":_vm.onBlur,"input":_vm.onInputNative},model:{value:(_vm.tempValue),callback:function ($$v) {_vm.tempValue=$$v},expression:"tempValue"}}):_c('input',{ref:"textField",staticClass:"relative w-px flex-1 flex-auto flex-shrink flex-grow leading-normal focus:outline-none",class:[
            _vm.height,
            _vm.inputClass,
            _vm.disabled ? 'bg-gray-50 text-black opacity-100' : _vm.bg,
            _vm.type === 'password' ? 'pl-[12px] pr-[34px]' : 'px-[12px]',
            { 'pt-[6px]': _vm.type === 'file' }
          ],attrs:{"id":_vm.idFix ? _vm.idFix : undefined,"type":_vm.typeFixed,"placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"name":_vm.name,"disabled":_vm.disabled,"readonly":_vm.readonly,"maximum-scale":"1","pattern":_vm.typeFixed === 'number' && !_vm.noPattern ? '*[0-9]' : undefined,"inputmode":_vm.typeFixed === 'number' && !_vm.noPattern ? 'numeric' : undefined,"min":"0","autocorrect":"off","step":"any"},domProps:{"value":_vm.tempValue},on:{"change":_vm.onChange,"keypress":function($event){return _vm.onlyNumber($event)},"paste":_vm.onPaste,"focus":_vm.onFocus,"blur":_vm.onBlur,"keydown":_vm.onKeydown,"keyup":_vm.onKeyup,"input":_vm.onInputNative}}),_vm._v(" "),(_vm.type === 'password')?_c('SclvIcon',{staticClass:"text-black-300 absolute right-[12px] cursor-pointer text-[19px]",attrs:{"icon":_vm.isPasswordShow ? 'ic-no-eye' : 'ic-eye'},on:{"click":_vm.toggleShowPassword}}):_vm._e(),_vm._v(" "),(_vm.type === 'file')?_c('SclvIcon',{staticClass:"text-black-200 absolute right-[12px] cursor-pointer text-[19px]",attrs:{"icon":"document-upload-sclvico"}}):_vm._e(),_vm._v(" "),_vm._t("append",function(){return [(_vm.append)?_c('div',{staticClass:"flex h-full font-medium"},[_c('span',{class:("\n                whitespace-no-wrap\n                text-grey-dark\n                flex\n                items-center\n                px-3\n                leading-normal\n                " + _vm.appendBg + "\n              ")},[_vm._v("\n              "+_vm._s(_vm.append)+"\n            ")])]):_vm._e()]})],2)],2),_vm._v(" "),(
        (!_vm.hideMessage && !_vm.autoHideMessage) ||
        (_vm.autoHideMessage && errors.length > 0) ||
        _vm.customError
      )?_c('span',{staticClass:"break-word mb-[5px] mt-[5px] flex items-center text-red-900",class:[!errors[0] && !_vm.customError && 'h-[3px]', _vm.labelSize]},[(errors[0] || _vm.customError)?_c('SclvIcon',{staticClass:"ml-[1px] mr-[8px] text-[13px] text-red-900",attrs:{"icon":"ic-exclamation"}}):_vm._e(),_vm._v("\n      "+_vm._s(_vm.errorMessageReplace || errors[0] || _vm.customError || ' ')+"\n    ")],1):_vm._e(),_vm._v(" "),(_vm.customSuccess && errors.length === 0)?_c('span',{staticClass:"break-word text-black-200 mb-[5px] mt-[5px] flex items-center",class:[!_vm.customSuccess && 'h-[3px]', _vm.labelSize]},[(_vm.customSuccess)?_c('SclvIcon',{staticClass:"ml-[1px] mr-[8px] text-[13px] text-green-600",attrs:{"icon":"ic-exclamation"}}):_vm._e(),_vm._v("\n      "+_vm._s(_vm.customSuccess)+"\n    ")],1):_vm._e()],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }