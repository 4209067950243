export const actions = {
  async storeData(store, payload) {
    try {
      const {
        storeId,
        channelUniqueId,
        collection,
        name,
        phone,
        subdistrictId,
        address
      } = payload
      const _url = `/store/${storeId}/new-lead/`
      const resAddNewLead = await this.$axios({
        baseURL: this.$config.initialFormApiURL,
        url: _url,
        method: 'post',
        data: {
          channel_unique_id: channelUniqueId,
          collection,
          name,
          phone,
          subdistrict_id: subdistrictId,
          address
        }
      })
      return resAddNewLead.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
