import {
  encodeQueryData,
  initQueryParams,
  decodeQueryData,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  loading: false, // page level loading
  storeLoading: false,
  marketingProgram: undefined,
  stores: [],
  selectedStore: undefined,
  packages: [],
  metaPackages: {},
  rewards: [],
  metaRewards: {},
  pointLogs: [],
  metaPointLogs: {},
  members: [],
  metaMembers: {},
  newsfeed: [],
  metaNewsfeed: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchStores({ commit, dispatch }) {
    try {
      dispatch('setStoreLoading', true)
      const storesResponse = await this.$axios.$get(
        '/store/?is_marketing_program=true'
      )
      commit('SET_STORES', storesResponse.data)
      dispatch('setStoreLoading', false)
      return storesResponse
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchMembers(
    { state, commit, dispatch },
    { marketingProgramPK, query = {}, isFirst = false } = {}
  ) {
    dispatch('setLoading', true)
    if (isFirst) {
      commit('SET_MEMBERS', [])
      commit('SET_META_MEMBERS', {})
    }
    const { page, pageSize } = state.metaMembers
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 10 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    try {
      dispatch('setLoading', true)
      // https://api.dev.scalev.id/v1/marketing-program-as-member/${id}/marketing-member/referred/
      const url = `/marketing-program-as-member/${marketingProgramPK}/marketing-member/?${queries}`
      const anggotaResponse = await this.$axios.$get(url)
      const allAnggota = [...state.members, ...anggotaResponse.data.results]
      commit('SET_MEMBERS', allAnggota)
      let newMeta = {
        itemsLength:
          anggotaResponse.data.count || anggotaResponse.data.results.length
      }
      const nextMeta = anggotaResponse.data.next
        ? decodeQueryData(anggotaResponse.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_MEMBERS', newMeta)
      dispatch('setLoading', false)
      return {
        totalCount: anggotaResponse.data.count,
        currentData: allAnggota
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload)
  },
  setStoreLoading({ commit }, payload) {
    commit('SET_STORE_LOADING', payload)
  },
  async fetchMarketingProgram({ commit, dispatch }, payload) {
    const { marketingProgramPK } = payload
    try {
      dispatch('setLoading', true)
      const marketingProgramResponse = await this.$axios.$get(
        `/marketing-program-as-member/${marketingProgramPK}/`
      )
      commit('SET_MARKETING_PROGRAM', marketingProgramResponse.data)
      dispatch('setLoading', false)
      return marketingProgramResponse
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  setSelectedStore({ commit }, payload) {
    commit('SET_SELECTED_STORE', payload)
  },
  async fetchRewards(
    { state, commit, dispatch },
    { marketingProgramPK, query = {}, isFirst = false } = {}
  ) {
    dispatch('setLoading', true)
    if (isFirst) {
      commit('SET_REWARDS', [])
      commit('SET_META_REWARDS', {})
    }
    const { page, pageSize } = state.metaRewards
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 10 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/marketing-program-as-member/${marketingProgramPK}/reward-item/?${queries}`
    const resRewards = await this.$axios.$get(url)
    const allRewards = [...state.rewards, ...resRewards.data.results]
    commit('SET_REWARDS', allRewards)
    let newMeta = {
      itemsLength: resRewards.data.count || resRewards.data.results.length
    }
    const nextMeta = resRewards.data.next
      ? decodeQueryData(resRewards.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_REWARDS', newMeta)
    dispatch('setLoading', false)
    return {
      totalCount: resRewards.data.count,
      currentData: allRewards
    }
  },
  async redeemReward({ dispatch }, payload) {
    const { marketingProgramPK, rewardItem } = payload
    try {
      dispatch('setLoading', true)
      const redeemResponse = await this.$axios.$post(
        `/marketing-program-as-member/${marketingProgramPK}/item-redemption/`,
        { reward_item: rewardItem }
      )
      dispatch('setLoading', false)
      return redeemResponse
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchPointLogs(
    { state, commit, dispatch },
    { marketingProgramPK, query = {}, isFirst = false } = {}
  ) {
    dispatch('setLoading', true)
    if (isFirst) {
      commit('SET_POINT_LOGS', [])
      commit('SET_META_POINT_LOGS', {})
    }
    const { page, pageSize } = state.metaPointLogs
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 10 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/marketing-program-as-member/${marketingProgramPK}/point-log/?${queries}`
    const resPointLogs = await this.$axios.$get(url)
    const allPointLogs = [...state.pointLogs, ...resPointLogs.data.results]
    commit('SET_POINT_LOGS', allPointLogs)
    let newMeta = {
      itemsLength: resPointLogs.data.count || resPointLogs.data.results.length
    }
    const nextMeta = resPointLogs.data.next
      ? decodeQueryData(resPointLogs.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_POINT_LOGS', newMeta)
    dispatch('setLoading', false)
    return {
      totalCount: resPointLogs.data.count,
      currentData: allPointLogs
    }
  },
  async fetchPackages(
    { state, commit, dispatch },
    { marketingProgramPK, query = {}, isFirst = false } = {}
  ) {
    dispatch('setLoading', true)
    if (isFirst) {
      commit('SET_PACKAGES', [])
      commit('SET_META_PACKAGES', {})
    }
    const { page, pageSize } = state.metaPackages
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 10 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/marketing-program-as-member/${marketingProgramPK}/marketing-package/?${queries}`
    const resPackages = await this.$axios.$get(url)
    const allPackages = [...state.packages, ...resPackages.data.results]
    commit('SET_PACKAGES', allPackages)
    let newMeta = {
      itemsLength: resPackages.data.count || resPackages.data.results.length
    }
    const nextMeta = resPackages.data.next
      ? decodeQueryData(resPackages.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_PACKAGES', newMeta)
    dispatch('setLoading', false)
    return {
      totalCount: resPackages.data.count,
      currentData: allPackages
    }
  },
  async fetchNewsfeed(
    { state, commit, dispatch },
    { marketingProgramPK, query = {}, isFirst = false } = {}
  ) {
    dispatch('setLoading', true)
    if (isFirst) {
      commit('SET_NEWSFEED', [])
      commit('SET_META_NEWSFEED', {})
    }
    const { page, pageSize } = state.metaNewsfeed
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 10 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    try {
      dispatch('setLoading', true)
      const url = `/marketing-program-as-member/${marketingProgramPK}/news-feed/?${queries}`
      const newsfeedResponse = await this.$axios.$get(url)
      const allNewsfeed = [...state.newsfeed, ...newsfeedResponse.data.results]
      commit('SET_NEWSFEED', allNewsfeed)
      let newMeta = {
        itemsLength:
          newsfeedResponse.data.count || newsfeedResponse.data.results.length
      }
      const nextMeta = newsfeedResponse.data.next
        ? decodeQueryData(newsfeedResponse.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_NEWSFEED', newMeta)
      dispatch('setLoading', false)
      return {
        totalCount: newsfeedResponse.data.count,
        currentData: allNewsfeed
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchNewsDetails(
    { state, commit, dispatch },
    { marketingProgramPK, itemID } = {}
  ) {
    try {
      //      dispatch('setLoading', true)
      const url = `/marketing-program-as-member/${marketingProgramPK}/news-feed/${itemID}/`
      const detailsResponse = await this.$axios.$get(url)
      console.debug({ detailsResponse })
      const details = detailsResponse.data
      //      dispatch('setLoading', false)
      return {
        data: details
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async inviteNewMember({ commit }, { id, ...restPayload }) {
    try {
      const res = await this.$axios.$post(
        `/marketing-program-as-member/${id}/marketing-member/`,
        restPayload
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async resendInvitation(
    { state, commit, dispatch },
    { marketingProgramPK, email } = {}
  ) {
    try {
      const resResendInvitiation = await this.$axios.$post(
        `marketing-program-as-member/${marketingProgramPK}/marketing-member/resend-invitation/`,
        {
          email
        }
      )
      return resResendInvitiation.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async cancelInvitation(
    { state, commit, dispatch },
    { marketingProgramPK, id } = {}
  ) {
    try {
      const cancelInvitation = await this.$axios.$delete(
        `marketing-program-as-member/${marketingProgramPK}/marketing-member/${id}/`
      )
      return cancelInvitation.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
