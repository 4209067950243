//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  props: {
    dataAlert: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isShown: false,
      isPaginationDone: false,
      isPause: true,
      isFetchNotification: false
    }
  },
  computed: {
    ...mapGetters('notification', [
      'getUnread',
      'getNotification',
      'getMetaNotification'
    ])
  },
  watch: {
    async isShown(val) {
      if (val) {
        this.isFetchNotification = true
        await this.fetchNotification()
        this.isPause = false
        this.isFetchNotification = false

        if (this.$mq === 'sm') {
          document.body.classList.add('overflow-hidden')
        }
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    }
  },
  mounted() {
    document.addEventListener('showMobileNotification', () => {
      this.isShown = true
    })
  },
  methods: {
    fetchNotification() {
      return Promise.all([
        this.$store.dispatch('notification/fetchAll', { isFirst: true }),
        this.$store.dispatch('notification/fetchUnread')
      ])
    },
    onClick() {
      this.isShown = false
    },
    async readAll() {
      this.isFetchNotification = true
      await this.$store.dispatch('notification/readAll')
      await this.fetchNotification()
      this.isPause = false
      this.isFetchNotification = false
    },
    async onView({ isVisible }) {
      if (isVisible && !this.isPaginationDone) {
        const resPage = await this.$store.dispatch('notification/fetchAll')
        if (resPage.currentLength === 0) {
          this.isPaginationDone = true
        }
      }
    }
  }
}
