//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import { onChangeCompanyBusiness } from '@/utils/helpers.js'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      canAddBusiness: true,
      isPaginationDone: false,
      isShow: this.value,
      isLoadingSearch: false
    }
  },
  computed: {
    ...mapGetters('business', ['getBusiness', 'getMetaBusiness']),
    getBusinessDetail() {
      return this.$auth.user.currentBusiness || {}
    }
  },
  watch: {
    value(val) {
      this.isShow = val
      if (val) {
        this.$refs.businessBottomSheet.open()
      }
    },
    isShow(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    onChangeCompanyBusiness,
    goToProfile() {
      this.$router.push('/setting/profile')
      this.isShow = false
    },
    async onView({ isVisible }) {
      if (isVisible && !this.isPaginationDone) {
        await this.$store.dispatch('business/fetchAll')
      }
      if (this.getBusiness.length === this.getMetaBusiness.itemsLength) {
        this.isPaginationDone = true
      }
    },
    close() {
      this.$refs.businessBottomSheet.close()
    },
    onClose() {
      setTimeout(() => {
        this.isShow = false
      }, 300)
    },
    showAddBusinessModal() {
      this.close()
      this.$store.dispatch('common/setBusinessAddModalShow', true)
    },
    async changeBusiness(idBusiness) {
      this.close()
      this.$nuxt.$loading.start(true)
      try {
        if (typeof window !== 'undefined') {
          localStorage.removeItem('statisticQuery')
          localStorage.removeItem('newDashboardQuery')
        }
        this.$router.push({
          path: this.$route.path,
          query: {}
        })
        await this.$store.dispatch('business/changeBusiness', idBusiness)
        // await this.$nuxt.refresh()
        // await this.$store.dispatch('common/checkBusinessPhone', this.$auth.user.currentBusiness)
        // this.$router.go('/dashboard')
        // if (this.$auth.user.invitation_status === 'invited') {
        //   this.$store.dispatch('common/setInvitationShow', true)
        // } else if (!this.$auth.user.business_phone) {
        //   this.$store.dispatch('common/setBusinessAccountPhoneShow', true)
        // }
        this.onChangeCompanyBusiness()
      } catch (error) {
        this.$toast.showMessage({
          message: this.$errorHandlerMessage(error),
          color: 'error'
        })
      }
      this.$nuxt.$loading.finish()
    },
    async seachTypeHandler(val) {
      if (val.length > 2 || val.length === 0) {
        this.isPaginationDone = true
        this.isLoadingSearch = true
        await this.$store.dispatch('business/resetBusiness')
        await this.searchDebounced(val)
      }
    },
    searchDebounced: debounce(async function (val) {
      await this.search(val)
      this.isLoadingSearch = false
      this.isPaginationDone =
        this.getBusiness.length === this.getMetaBusiness.itemsLength
    }, 500),
    async search(val) {
      await this.$store.dispatch('business/fetchAll', {
        business_name: val
      })
    }
  }
}
