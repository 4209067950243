//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import kebabCase from 'lodash/kebabCase'
import formMixins from '~/mixins/form.vue'
export default {
  mixins: [formMixins],
  data() {
    return {
      accountHolder: '',
      isCancel: false,
      taxRate: '0',
      alert: null,
      username: '',
      phone: '',
      address: '',
      subdistrictId: null,
      typingTimer: null,
      isUsernameAvailable: false,
      isUsernameExist: false,
      businessCountAlert: { check: false, errorMessage: '' }
    }
  },
  watch: {
    accountHolder(val) {
      this.username = kebabCase(val)
    },
    username(val) {
      if (
        val.length > 2 &&
        // eslint-disable-next-line no-useless-escape
        !/[^a-zA-Z0-9\-]/.test(val) &&
        val.substring(0, 1) !== '-' &&
        val.substring(val.length - 1) !== '-'
      ) {
        this.isUsernameAvailable = false
        this.isUsernameExist = false
        clearTimeout(this.typingTimer)
        this.typingTimer = setTimeout(async () => {
          try {
            const res = await this.$store.dispatch(
              'business/checkUsername',
              val
            )
            this.isUsernameAvailable = res.data.is_available
            this.isUsernameExist = !res.data.is_available
          } catch (error) {
            this.$errorHandler(error)
          }
        }, 500)
      }
    }
  },
  methods: {
    onCancel({ accountHolder, username, phone }) {
      this.$store.dispatch('business/setAccountHolder', accountHolder)
      this.$store.dispatch('business/setUsername', username)
      this.$store.dispatch('business/setPhone', phone)
      if (
        this.accountHolder === '' &&
        this.phone === '' &&
        this.username === ''
      ) {
        this.$emit('cancel')
      } else {
        this.$emit('modalCancel')
        this.accountHolder = accountHolder
        this.phone = phone
        this.username = username
      }
    },
    onSuccess() {
      this.$emit('cancel')
    },
    async onSubmit() {
      this.$nuxt.$loading.start(true)
      try {
        // continue adding business
        const payload = {
          accountHolder: this.accountHolder,
          phone: this.phone,
          username: this.username,
          address: this.address,
          location: this.subdistrictId
        }
        await this.$store.dispatch('business/addBusiness', payload)
        // await this.$auth.fetchUser()
        // await this.$store.dispatch('common/checkBusinessPhone', this.$auth.user.currentBusiness)
        this.$emit('submit', payload)
        this.$toast.showMessage({
          message: 'Bisnis baru anda berhasil ditambahkan.',
          color: 'success'
        })
        // this.$router.go('/dashboard') // causing untracking logout in ios devices
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          this.businessCountAlert.check = true
          this.businessCountAlert.errorMessage = error.response.data.message
        } else {
          this.$errorHandler(error)
        }
        this.$nuxt.$loading.finish()
      } finally {
        this.$store.dispatch('common/setBusinessModalSuccess', true)
      }
    }
  }
}
