//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      phone: ''
    }
  },
  computed: {
    getBusinessDetail() {
      return this.$auth.user.currentBusiness || {}
    }
  },
  mounted() {
    if (this.$auth.user.account.business_phone) {
      this.phone = this.$auth.user.account.business_phone
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    async onSubmit() {
      this.$nuxt.$loading.start(true)
      try {
        const res = await this.$store.dispatch('business/editBusinessAccount', {
          phone: this.phone
        })
        this.phone = res.data.business_phone
        this.$auth.fetchUser()
      } catch (error) {
        this.$errorHandler(error)
        return false
      } finally {
        this.$nuxt.$loading.finish()
      }
    },
    onSubmitSuccess() {
      this.$asyncEmit('submit')
      this.$toast.showMessage({
        message: 'Perubahan nomor telepon kamu berhasil disimpan.',
        color: 'success'
      })
    }
  }
}
