import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  fbPixels: [],
  fbEventsOnLoad: [],
  fbEventsOnSubmit: [],
  isAllFbPixelsLoaded: false,
  standardEvents: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, queries = {}) {
    const { page = 1, search = '' } = queries
    try {
      const resPixels = await this.$axios.$get(
        `/fb-pixel/?page=${page}&name=${search}`
      )
      commit('SET_FB_PIXELS', resPixels.data.results)
      commit('SET_IS_ALL_FB_PIXELS_LOADED', resPixels.data.next === null)
      return resPixels
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllEvents({ commit }) {
    try {
      const res = await this.$axios.$get(`/fb-standard-event/`)
      commit('SET_STANDARD_EVENTS', res.data.results)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, id) {
    try {
      const resFetchById = await this.$axios.$get(`/fb-pixel/${id}/`)
      return resFetchById
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    const { name, pixelId, conversionToken, testEventCode } = payload
    try {
      const resStoreData = await this.$axios.$post('/fb-pixel/', {
        name,
        pixel_id: pixelId,
        conversion_token: conversionToken,
        test_event_code: testEventCode
      })
      return resStoreData
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    const { id, name, pixelId, conversionToken, testEventCode } = payload
    try {
      const resUpdate = await this.$axios.$put(`/fb-pixel/${id}/`, {
        name,
        pixel_id: pixelId,
        conversion_token: conversionToken,
        test_event_code: testEventCode
      })
      return resUpdate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit }, id) {
    try {
      await this.$axios.$delete(`/fb-pixel/${id}/`)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async eventConversion({ commit }, payload) {
    const {
      formId,
      pageId,
      firstName,
      lastName,
      email,
      phone,
      city,
      state,
      subdistrictId,
      ua,
      ip,
      bundles,
      variants,
      bundlePriceOptions,
      eventSourceUrl,
      events,
      externalId,
      fbp,
      fbc
    } = payload
    try {
      const resFbEvent = await this.$axios.$post(`/fb-event/`, {
        page: pageId,
        form: formId,
        user: {
          first_name: firstName,
          last_name: lastName,
          email,
          phone,
          city,
          state,
          location_id: subdistrictId,
          country_code: 'ID',
          ua,
          ip,
          external_id: externalId,
          fbp,
          fbc
        },
        bundles,
        variants,
        bundle_price_options: bundlePriceOptions,
        event_source_url: eventSourceUrl,
        events
      })
      return resFbEvent
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
