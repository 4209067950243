//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTimeAgoOrFormattedDate } from '@/utils/helpers'

export default {
  filters: {
    getTimeAgoOrFormattedDate
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    created: {
      type: String,
      default: null
    },
    idNotif: {
      type: Number,
      default: null
    },
    orderId: {
      type: Number,
      default: null
    },
    id: {
      type: String,
      default: ''
    },
    unread: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    metadata: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  computed: {
    icon() {
      const iconx = {
        new_order: 'shopping-cart-sclvico', // keranjang
        to_confirm: 'cash-sclvico', // uang
        confirmed: 'dollar-sclvico', // dollar
        payment_reminder: 'ic-calendar',
        business_subscription: 'card-sclvico',
        mark_unpaid: 'exclamation-2-sclvico'
      }

      return iconx[this.type] || 'shopping-cart-sclvico'
    },
    iconBgColor() {
      const iconx = {
        new_order: 'bg-yellow-500', // keranjang
        to_confirm: 'bg-primary', // uang
        confirmed: 'bg-green-200', // dollar
        payment_reminder: 'bg-yellow-500',
        business_subscription: 'bg-yellow-500',
        mark_unpaid: 'bg-red-600'
      }

      return iconx[this.type] || 'bg-green-500'
    }
  },
  methods: {
    goToDetail() {
      this.$store.dispatch('notification/markAsRead', this.idNotif)
      this.$emit('click')

      if (this.type === 'payment_reminder') {
        window.open(this.metadata.invoice_url, '_blank')
        return
      }

      if (['business_subscription', 'mark_unpaid'].includes(this.type)) {
        this.$router.push('/setting/billing')
        return
      }

      this.$router.push(`/order/${this.orderId}`)
    }
  }
}
