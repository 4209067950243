//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  head() {
    return {
      link: this.fontLinks
    }
  },
  computed: {
    ...mapGetters('form', ['getForm', 'getIsSuccess']),
    fontLinks() {
      const fontLinks = []
      switch (this.getForm.font_style) {
        case 'Roboto-Slab':
          fontLinks.push({
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;700;900&display=swap'
          })
          break
        case 'Noto-Serif':
          fontLinks.push({
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500;700;900&display=swap'
          })
          break
        case 'Playfair-Display':
          fontLinks.push({
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700;900&display=swap'
          })
          break
        case 'Open-Sans':
          fontLinks.push({
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700;900&display=swap'
          })
          break
        case 'Montserrat':
          fontLinks.push({
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&display=swap'
          })
          break
        case 'Poppins':
          fontLinks.push({
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap'
          })
          break
        case 'Alegreya-Sans':
          fontLinks.push({
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;500;700;900&display=swap'
          })
          break
      }
      return fontLinks
    }
  }
}
