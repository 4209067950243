var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
      !_vm.getIsSuccess && _vm.getForm.font_style !== 'Roboto'
        ? ("font-" + (_vm.getForm.font_style))
        : '',
      !_vm.getIsSuccess && 'min-h-screen w-full pb-[32px] pt-[32px]'
    ],style:(("background-color: #" + (_vm.getForm.background_color)))},[(!_vm.getIsSuccess)?_c('Toast'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex w-full flex-wrap"},[(!_vm.getIsSuccess && _vm.getForm.logo !== null && _vm.getForm.logo !== '')?_c('div',{staticClass:"flex w-full"},[_c('img',{staticClass:"mx-auto h-[100px]",attrs:{"src":_vm.getForm.logo,"alt":"logo"}})]):_vm._e(),_vm._v(" "),(!_vm.getIsSuccess && _vm.getForm.title !== null && _vm.getForm.title !== '')?_c('div',{class:("flex w-full " + (_vm.getForm.logo !== null && _vm.getForm.logo !== '' && 'mt-[16px]'))},[_c('span',{class:("mx-auto text-center font-bold " + (_vm.getForm.font_size === 'normal'
              ? 'text-[23px] md:text-[32px]'
              : _vm.getForm.font_size === 'smaller'
              ? 'text-[21px] md:text-[29px]'
              : 'text-[25px] md:text-[35px]') + " max-w-[520px] px-[16px] " + (_vm.getForm.sidebar_position === ''
              ? 'md:max-w-[760px]'
              : 'md:max-w-[960px]'))},[_vm._v("\n          "+_vm._s(_vm.getForm.title)+"\n        ")])]):_vm._e(),_vm._v(" "),(
          !_vm.getIsSuccess &&
          _vm.getForm.subtitle !== null &&
          _vm.getForm.subtitle !== ''
        )?_c('div',{staticClass:"mt-[8px] flex w-full"},[_c('span',{class:("\n          text-black-300\n          mx-auto\n          text-center\n          font-bold\n          " + (_vm.getForm.font_size === 'normal'
              ? 'text-[14px] md:text-[19px]'
              : _vm.getForm.font_size === 'smaller'
              ? 'text-[12px] md:text-[16px]'
              : 'text-[16px] md:text-[22px]') + "\n          max-w-[520px]\n          px-[16px] " + (_vm.getForm.sidebar_position === ''
              ? 'md:max-w-[760px]'
              : 'md:max-w-[960px]') + "\n        ")},[_vm._v("\n          "+_vm._s(_vm.getForm.subtitle)+"\n        ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"min-w-full",class:[!_vm.getIsSuccess && 'flex md:-mt-[16px]']},[_c('div',{class:[
            !_vm.getIsSuccess &&
              'container mx-auto  max-w-[540px] p-[16px] md:p-[32px]',
            !_vm.getIsSuccess && _vm.getForm.sidebar_position === ''
              ? 'md:max-w-[760px]'
              : !_vm.getIsSuccess
              ? 'md:max-w-[960px]'
              : ''
          ]},[_c('Nuxt')],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }