var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('VueBottomSheet',{ref:"businessBottomSheet",staticClass:"z-50",on:{"closed":_vm.onClose}},[_c('div',{staticClass:"fixed inset-x-0 bottom-0 z-20 flex flex-col items-center rounded-t bg-white"},[_c('div',{staticClass:"mt-[10px] h-[5px] w-[134px] rounded-full bg-gray-200"}),_vm._v(" "),_c('span',{staticClass:"py-[24px] text-[12px] font-medium text-black-500"},[_vm._v("\n        Pilih bisnis kamu\n      ")]),_vm._v(" "),_c('div',{staticClass:"w-full px-[16px]"},[_c('TextField',{attrs:{"placeholder":"Cari Bisnis"},on:{"input":_vm.seachTypeHandler},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"flex h-full items-center bg-gray-150 pr-[14px]"},[_c('SclvIcon',{staticClass:"text-[15px] text-black-200",attrs:{"icon":"ic-search"}})],1)]},proxy:true}])})],1),_vm._v(" "),_c('ul',{staticClass:"flex max-h-[256px] w-full list-none flex-col space-y-[10px] overflow-y-auto px-[24px] font-[12px]"},[_vm._l((_vm.getBusiness),function(business,index){return _c('li',{key:("list-business-item-" + index),staticClass:"flex cursor-pointer items-center justify-between rounded p-[13px]",class:{
            'bg-gray-150': business.isUserBusinessActive,
            'bg-yellow-100': business.invitationStatus === 'invited',
            'bg-red-100':
              !business.isUserBusinessActive &&
              business.invitationStatus !== 'invited'
          },on:{"click":function($event){return _vm.changeBusiness(business.id)}}},[_c('SclvAvatar',{staticClass:"mr-[8px] h-[24px] min-w-[24px] rounded-full border-2 border-white text-[12px] shadow-lg",attrs:{"username":business.account_holder,"src":business.logo,"alt":business.account_holder}}),_vm._v(" "),_c('span',{staticClass:"mr-[8px] flex-1 text-[14px] font-medium"},[_vm._v("\n            "+_vm._s(business.account_holder)+"\n          ")]),_vm._v(" "),(
              !business.isUserBusinessActive &&
              business.invitationStatus !== 'invited'
            )?_c('SclvIcon',{staticClass:"mr-[16px] text-[14px] text-red-900",attrs:{"icon":"ic-exclamation"}}):(business.invitationStatus === 'invited')?_c('SclvIcon',{staticClass:"mr-[16px] text-[14px] text-yellow-900",attrs:{"icon":"ic-question-circle"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"h-[20px] w-[20px] rounded-full border-2 p-[2px]",class:[
              _vm.getBusinessDetail.id === business.id
                ? 'border-primary-100'
                : 'border-black-300'
            ]},[(_vm.getBusinessDetail.id === business.id)?_c('span',{staticClass:"block h-full w-full rounded-full bg-primary-100"}):_vm._e()])],1)}),_vm._v(" "),_c('SclvIntersect',{attrs:{"is-done":_vm.isPaginationDone},on:{"change":_vm.onView}},[_c('div',{staticClass:"flex h-[42px] w-full items-center justify-center"},[_vm._v("\n            Memuat Bisnis...\n          ")])])],2),_vm._v(" "),_c('div',{staticClass:"w-full border-b-2 pb-[20px] pt-[20px] text-center text-[12px]"},[(_vm.canAddBusiness)?_c('SclvButton',{attrs:{"text":"","color":" text-primary "},on:{"click":_vm.showAddBusinessModal}},[_vm._v("\n          + Buat Bisnis Baru\n        ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex w-full cursor-pointer justify-between p-[24px]",on:{"click":_vm.goToProfile}},[(_vm.$auth.user.detail)?_c('div',{staticClass:"flex items-center space-x-[8px]"},[(_vm.$auth.user)?_c('SclvAvatar',{staticClass:"h-[24px] w-[24px] rounded-full border-2 border-white text-[10px] shadow-lg",attrs:{"username":_vm.$auth.user.detail.fullname,"src":_vm.$auth.user.detail.avatar}}):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"text-[14px] font-medium text-black-900"},[_vm._v("\n            "+_vm._s(_vm.$auth.user.detail.fullname)+"\n          ")])],1):_vm._e(),_vm._v(" "),(_vm.$auth.user.account)?_c('div',{staticClass:"flex items-center space-x-[9px] text-[12px]"},[_c('span',{staticClass:"text-black-300"},[_vm._v("\n            "+_vm._s(_vm.$auth.user.account.role ? _vm.$auth.user.account.role.name : '')+"\n          ")])]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }