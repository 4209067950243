//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTransparent: false,
      loading: this.value
    }
  },
  computed: {
    ...mapGetters('common', ['getSidebarMini', 'getIsRouterLoadingEnable'])
  },
  watch: {
    loading(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    start(isTransparent) {
      this.isTransparent =
        typeof isTransparent === 'boolean' ? !!isTransparent : false

      if (this.getIsRouterLoadingEnable) {
        this.loading = true
      }
    },
    finish() {
      setTimeout(() => {
        this.loading = false
        this.$store.dispatch('common/setIsRouterLoadingEnable', true)
      }, 0)
    }
  }
}
