//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      tabPosition: 0,
      navigations: [
        {
          id: 1,
          title: 'Header',
          isLocked: true,
          isCollapsed: false,
          components: []
        },
        {
          id: 2,
          title: 'Seksi Utama',
          isLocked: false,
          isCollapsed: true,
          components: [
            {
              id: 1,
              type: 'ilustration',
              source: 'https://google.com',
              name: 'Ilustrasi Utama'
            },
            {
              id: 2,
              type: 'text',
              source: '<p>HAS <strong>BEGUN</strong></p>',
              name: 'Judul Utama'
            },
            {
              id: 3,
              type: 'columns',
              count: 3,
              name: 'Paket',
              isCollapsed: false,
              components: [
                {
                  id: 1,
                  type: 'text',
                  source: '<p>Heheh One</p>',
                  name: 'Text Kiri'
                },
                {
                  id: 2,
                  type: 'text',
                  source: '<p>Heheh Two</p>',
                  name: 'Text Tengah'
                },
                {
                  id: 3,
                  type: 'text',
                  source: '<p>Heheh Three</p>',
                  name: 'Text Kanan'
                }
              ]
            }
          ]
        }
      ]
    }
  },
  methods: {
    convertTypeToIcon(type) {
      let icon = ''
      switch (type) {
        case 'ilustration':
          icon = 'ic-ilustration'
          break
        case 'text':
          icon = 'ic-text'
          break
        case 'columns':
          icon = 'ic-columns'
      }
      return icon
    }
  }
}
