import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaOrder: {},
  order: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { id, query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_ORDER', [])
      commit('SET_META_ORDER', {})
    }
    const { page, pageSize } = state.metaOrder
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/customer/${id}/order/?${queries}`
    const res = await this.$axios.$get(url)
    const allCustomerOrder = [...state.order, ...res.data.results]
    commit('SET_ORDER', allCustomerOrder)
    let newMeta = {
      itemsLength:
        res.data.count || res.data.results.length
    }
    const nextMeta = res.data.next
      ? decodeQueryData(res.data.next.split('?')?.[1])
      : null
    if (nextMeta) {
      newMeta = {
        ...newMeta,
        page: nextMeta.page - 0,
        pageSize: nextMeta.page_size - 0
      }
    }
    commit('SET_META_ORDER', newMeta)
    return {
      totalCount: res.data.count,
      currentData: allCustomerOrder
    }
  }
}
