import {
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

const getDefaultState = () => {
  return {
    backgroundColor: '#F2F5FA',
    fontStyle: 'Roboto',
    fontSize: 'normal',
    isShowBanner: true,
    title: '',
    subtitle: '',
    logo: '',
    sidebarPosition: 'no_sidebar',
    sidebarContentPosition: ['img', 'desc'],
    sidebarImg: [],
    sidebarDescription: '',
    contentPosition: [
      'img',
      'video',
      'desc',
      'items',
      'form',
      'upsell',
      'summary',
      'button'
    ],
    img: [],
    video: '',
    description: '',
    bundleTitle: 'Pilih Paket',
    bundleType: 'radio',
    isItemsShown: true,
    isBundlePriceShown: false,
    itemsType: 'variant',
    bundle: [],
    variant: [],
    formTitle: 'Lengkapi Data',
    formPosition: ['name', 'phone', 'email', 'address', 'notes'],
    nameLabel: 'Nama Lengkap',
    namePlaceholder: 'Cth: Krisdayanti',
    phoneLabel: 'No. Hp (Whatsapp)',
    phonePlaceholder: 'Cth: 0812000000',
    isEmailInputShown: false,
    emailLabel: 'Email',
    emailPlaceholder: 'Cth: krisdayanti@gmail.com',
    isEmailRequired: false,
    locationType: 'no_location',
    isLocationRequired: false,
    isAddressInputShown: false,
    addressLabel: 'Alamat',
    addressPlaceholder: 'Cth: Jl. Perjalanan No. 1',
    isAddressRequired: false,
    isNotesInputShown: false,
    notesLabel: 'Catatan',
    notesPlaceholder: 'Cth: Saya mau yang warna merah, ya.',
    isNotesRequired: false,
    buttonText: 'Kirimkan',
    buttonFontSize: 'normal',
    buttonColor: '#192434',
    isButtonIcon: false,
    buttonIcon: 'ic-arrow-right-small',
    isSidebarShown: false,
    previewAs: 'desktop',
    formTitleOnSetting: '',
    slug: '',
    seoTitle: '',
    seoDescription: '',
    seoThumbnail: '',
    tagManager: '',
    fbPixels: [],
    fbEventsOnLoad: [],
    fbEventsOnSubmit: [],
    tiktokPixels: [],
    tiktokEventsOnLoad: [],
    tiktokEventsOnSubmit: [],
    storeId: '',
    variantsOrBundlesWithObject: [],
    chatTemplate: '',
    courierLabel: 'Pilih Kurir',
    isCourierSearch: false,
    isCourierShown: false,
    paymentMethodLabel: 'Metode Pembayaran',
    summaryLabel: 'Rincian Pesanan',
    isSummaryShown: false,
    isMultipleItems: false,
    defaultAdSource: 'organic',
    afterSubmitEvent: 'direct_to_whatsapp',
    storeSalesPerson: '',
    otherForm: '',
    customUrl: '',
    handlerAssignment: 'rotator',
    isUpsell: false,
    upsellVariant: '',
    upsellBundle: '',
    upsellType: 'variant',
    upsellDescription: '',
    isPriceStrike: false,
    upsellImg: [],
    imgDuration: 5,
    isImgAutoslide: true,
    sidebarImgDuration: 5,
    isSidebarImgAutoslide: true,
    upsellImgDuration: 5,
    isUpsellImgAutoslide: true,
    isItemsImagesShown: false,
    tabPosition: 0,
    selectedStore: null
  }
}

export const state = () => getDefaultState()

export const mutations = {
  ...mapMutationsHelper(state()),
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  }
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  setBackgroundColor({ commit }, color) {
    commit('SET_BACKGROUND_COLOR', color)
  },
  setFontStyle({ commit }, fontStyle) {
    commit('SET_FONT_STYLE', fontStyle)
  },
  setFontSize({ commit }, fontSize) {
    commit('SET_FONT_SIZE', fontSize)
  },
  setIsShowBanner({ commit }, isShow) {
    commit('SET_IS_SHOW_BANNER', isShow)
  },
  setTitle({ commit }, title) {
    commit('SET_TITLE', title)
  },
  setSubtitle({ commit }, subtitle) {
    commit('SET_SUBTITLE', subtitle)
  },
  setLogo({ commit }, logo) {
    commit('SET_LOGO', logo)
  },
  setSidebarPosition({ commit }, position) {
    commit('SET_SIDEBAR_POSITION', position)
  },
  setSidebarContentPosition({ commit }, contentPosition) {
    commit('SET_SIDEBAR_CONTENT_POSITION', contentPosition)
  },
  setSidebarImg({ commit }, img) {
    commit('SET_SIDEBAR_IMG', img)
  },
  setSidebarDescription({ commit }, desc) {
    commit('SET_SIDEBAR_DESCRIPTION', desc)
  },
  setContentPosition({ commit }, contentPosition) {
    commit('SET_CONTENT_POSITION', contentPosition)
  },
  setImg({ commit }, img) {
    commit('SET_IMG', img)
  },
  setVideo({ commit }, video) {
    commit('SET_VIDEO', video)
  },
  setDescription({ commit }, desc) {
    commit('SET_DESCRIPTION', desc)
  },
  setBundleTitle({ commit }, title) {
    commit('SET_BUNDLE_TITLE', title)
  },
  setBundleType({ commit }, type) {
    commit('SET_BUNDLE_TYPE', type)
  },
  setItemsType({ commit }, itemsType) {
    commit('SET_ITEMS_TYPE', itemsType)
  },
  setIsItemsShown({ commit }, isItemsShown) {
    commit('SET_IS_ITEMS_SHOWN', isItemsShown)
  },
  setIsBundlePriceShown({ commit }, isShown) {
    commit('SET_IS_BUNDLE_PRICE_SHOWN', isShown)
  },
  setBundle({ commit }, bundle) {
    commit('SET_BUNDLE', bundle)
  },
  setVariant({ commit }, variant) {
    commit('SET_VARIANT', variant)
  },
  setFormTitle({ commit }, title) {
    commit('SET_FORM_TITLE', title)
  },
  setFormPosition({ commit }, position) {
    commit('SET_FORM_POSITION', position)
  },
  setNameLabel({ commit }, label) {
    commit('SET_NAME_LABEL', label)
  },
  setNamePlaceholder({ commit }, placeholder) {
    commit('SET_NAME_PLACEHOLDER', placeholder)
  },
  setPhoneLabel({ commit }, label) {
    commit('SET_PHONE_LABEL', label)
  },
  setPhonePlaceholder({ commit }, placeholder) {
    commit('SET_PHONE_PLACEHOLDER', placeholder)
  },
  setIsEmailInputShown({ commit }, isShown) {
    commit('SET_IS_EMAIL_INPUT_SHOWN', isShown)
  },
  setEmailLabel({ commit }, label) {
    commit('SET_EMAIL_LABEL', label)
  },
  setEmailPlaceholder({ commit }, placeholder) {
    commit('SET_EMAIL_PLACEHOLDER', placeholder)
  },
  setIsEmailRequired({ commit }, isRequired) {
    commit('SET_IS_EMAIL_REQUIRED', isRequired)
  },
  setLocationType({ commit }, type) {
    commit('SET_LOCATION_TYPE', type)
  },
  setIsLocationRequired({ commit }, isRequired) {
    commit('SET_IS_LOCATION_REQUIRED', isRequired)
  },
  setIsAddressInputShown({ commit }, isShown) {
    commit('SET_IS_ADDRESS_INPUT_SHOWN', isShown)
  },
  setAddressLabel({ commit }, label) {
    commit('SET_ADDRESS_LABEL', label)
  },
  setAddressPlaceholder({ commit }, placeholder) {
    commit('SET_ADDRESS_PLACEHOLDER', placeholder)
  },
  setIsAddressRequired({ commit }, isRequired) {
    commit('SET_IS_ADDRESS_REQUIRED', isRequired)
  },
  setIsNotesInputShown({ commit }, isShown) {
    commit('SET_IS_NOTES_INPUT_SHOWN', isShown)
  },
  setNotesLabel({ commit }, label) {
    commit('SET_NOTES_LABEL', label)
  },
  setNotesPlaceholder({ commit }, placeholder) {
    commit('SET_NOTES_PLACEHOLDER', placeholder)
  },
  setIsNotesRequired({ commit }, isRequired) {
    commit('SET_IS_NOTES_REQUIRED', isRequired)
  },
  setButtonText({ commit }, text) {
    commit('SET_BUTTON_TEXT', text)
  },
  setButtonFontSize({ commit }, size) {
    commit('SET_BUTTON_FONT_SIZE', size)
  },
  setButtonColor({ commit }, color) {
    commit('SET_BUTTON_COLOR', color)
  },
  setIsButtonIcon({ commit }, isButtonIcon) {
    commit('SET_IS_BUTTON_ICON', isButtonIcon)
  },
  setButtonIcon({ commit }, icon) {
    commit('SET_BUTTON_ICON', icon)
  },
  setIsSidebarShown({ commit }, isShown) {
    commit('SET_IS_SIDEBAR_SHOWN', isShown)
  },
  setPreviewAs({ commit }, previewAs) {
    commit('SET_PREVIEW_AS', previewAs)
  },
  setFormTitleOnSetting({ commit }, title) {
    commit('SET_FORM_TITLE_ON_SETTING', title)
  },
  setSlug({ commit }, slug) {
    commit('SET_SLUG', slug)
  },
  setSeoTitle({ commit }, title) {
    commit('SET_SEO_TITLE', title)
  },
  setSeoDescription({ commit }, description) {
    commit('SET_SEO_DESCRIPTION', description)
  },
  setSeoThumbnail({ commit }, thumbnail) {
    commit('SET_SEO_THUMBNAIL', thumbnail)
  },
  setTagManager({ commit }, tagManager) {
    commit('SET_TAG_MANAGER', tagManager)
  },
  setFbPixels({ commit }, pixels) {
    commit('SET_FB_PIXELS', pixels)
  },
  setFbEventsOnLoad({ commit }, events) {
    commit('SET_FB_EVENTS_ON_LOAD', events)
  },
  setFbEventsOnSubmit({ commit }, events) {
    commit('SET_FB_EVENTS_ON_SUBMIT', events)
  },
  setTiktokPixels({ commit }, pixels) {
    commit('SET_TIKTOK_PIXELS', pixels)
  },
  setTiktokEventsOnLoad({ commit }, events) {
    commit('SET_TIKTOK_EVENTS_ON_LOAD', events)
  },
  setTiktokEventsOnSubmit({ commit }, events) {
    commit('SET_TIKTOK_EVENTS_ON_SUBMIT', events)
  },
  setStoreId({ commit }, id) {
    commit('SET_STORE_ID', id)
  },
  setVariantsOrBundlesWithObject({ commit }, items) {
    commit('SET_VARIANTS_OR_BUNDLES_WITH_OBJECT', items)
  },
  setChatTemplate({ commit }, chatTemplate) {
    commit('SET_CHAT_TEMPLATE', chatTemplate)
  },
  setCourierLabel({ commit }, label) {
    commit('SET_COURIER_LABEL', label)
  },
  setIsCourierSearch({ commit }, isSearch) {
    commit('SET_IS_COURIER_SEARCH', isSearch)
  },
  setIsCourierShown({ commit }, isShown) {
    commit('SET_IS_COURIER_SHOWN', isShown)
  },
  setPaymentMethodLabel({ commit }, label) {
    commit('SET_PAYMENT_METHOD_LABEL', label)
  },
  setSummaryLabel({ commit }, label) {
    commit('SET_SUMMARY_LABEL', label)
  },
  setIsSummaryShown({ commit }, isShown) {
    commit('SET_IS_SUMMARY_SHOWN', isShown)
  },
  setIsMultipleItems({ commit }, isMultiple) {
    commit('SET_IS_MULTIPLE_ITEMS', isMultiple)
  },
  setDefaultAdSource({ commit }, adSource) {
    commit('SET_DEFAULT_AD_SOURCE', adSource)
  },
  setAfterSubmitEvent({ commit }, event) {
    commit('SET_AFTER_SUBMIT_EVENT', event)
  },
  setStoreSalesPerson({ commit }, salesPerson) {
    commit('SET_STORE_SALES_PERSON', salesPerson)
  },
  setOtherForm({ commit }, form) {
    commit('SET_OTHER_FORM', form)
  },
  setCustomUrl({ commit }, url) {
    commit('SET_CUSTOM_URL', url)
  },
  setHandlerAssignment({ commit }, salesPerson) {
    commit('SET_HANDLER_ASSIGNMENT', salesPerson)
  },
  setIsUpsell({ commit }, isUpsell) {
    commit('SET_IS_UPSELL', isUpsell)
  },
  setUpsellVariant({ commit }, variant) {
    commit('SET_UPSELL_VARIANT', variant)
  },
  setUpsellBundle({ commit }, bundle) {
    commit('SET_UPSELL_BUNDLE', bundle)
  },
  setUpsellType({ commit }, type) {
    commit('SET_UPSELL_TYPE', type)
  },
  setUpsellDescription({ commit }, description) {
    commit('SET_UPSELL_DESCRIPTION', description)
  },
  setIsPriceStrike({ commit }, isStrike) {
    commit('SET_IS_PRICE_STRIKE', isStrike)
  },
  setUpsellImg({ commit }, images) {
    commit('SET_UPSELL_IMG', images)
  },
  setImgDuration({ commit }, duration) {
    commit('SET_IMG_DURATION', duration)
  },
  setIsImgAutoslide({ commit }, isAutoslide) {
    commit('SET_IS_IMG_AUTOSLIDE', isAutoslide)
  },
  setSidebarImgDuration({ commit }, duration) {
    commit('SET_SIDEBAR_IMG_DURATION', duration)
  },
  setIsSidebarImgAutoslide({ commit }, isAutoslide) {
    commit('SET_IS_SIDEBAR_IMG_AUTOSLIDE', isAutoslide)
  },
  setUpsellImgDuration({ commit }, duration) {
    commit('SET_UPSELL_IMG_DURATION', duration)
  },
  setIsUpsellImgAutoslide({ commit }, isAutoslide) {
    commit('SET_IS_UPSELL_IMG_AUTOSLIDE', isAutoslide)
  },
  setIsItemsImagesShown({ commit }, isShown) {
    commit('SET_IS_ITEMS_IMAGES_SHOWN', isShown)
  },
  setTabPosition({ commit }, position) {
    commit('SET_TAB_POSITION', position)
  },
  setSelectedStore({ commit }, store) {
    commit('SET_SELECTED_STORE', store)
  },

  async storeData({ commit, state }, payload) {
    const { isPublished, isFromOutsideBuilder, dataFromOutsideBuilder } =
      payload
    try {
      const resStoreData = await this.$axios.$post('/form/', {
        name: !isFromOutsideBuilder
          ? state.formTitleOnSetting
          : dataFromOutsideBuilder.formTitleOnSetting,
        slug: !isFromOutsideBuilder ? state.slug : dataFromOutsideBuilder.slug,
        is_published: !isFromOutsideBuilder ? isPublished : isPublished,
        store: !isFromOutsideBuilder
          ? state.storeId
          : dataFromOutsideBuilder.storeId,
        ad_source: !isFromOutsideBuilder
          ? state.defaultAdSource
          : dataFromOutsideBuilder.defaultAdSource,
        chat_template: !isFromOutsideBuilder
          ? state.chatTemplate
          : dataFromOutsideBuilder.chatTemplate,
        after_submit_event: !isFromOutsideBuilder
          ? state.afterSubmitEvent
          : dataFromOutsideBuilder.afterSubmitEvent,
        store_sales_person: !isFromOutsideBuilder
          ? state.storeSalesPerson
            ? state.storeSalesPerson
            : undefined
          : dataFromOutsideBuilder.storeSalesPerson
          ? dataFromOutsideBuilder.storeSalesPerson
          : undefined,
        custom_url: !isFromOutsideBuilder
          ? state.customUrl
          : dataFromOutsideBuilder.customUrl,
        other_form: !isFromOutsideBuilder
          ? state.otherForm
            ? state.otherForm
            : undefined
          : dataFromOutsideBuilder.otherForm
          ? dataFromOutsideBuilder.otherForm
          : undefined,
        handler_assignment: !isFromOutsideBuilder
          ? state.handlerAssignment
          : dataFromOutsideBuilder.handlerAssignment,
        fb_pixels: !isFromOutsideBuilder
          ? state.fbPixels
          : dataFromOutsideBuilder.fbPixels,
        fb_events_onload: !isFromOutsideBuilder
          ? state.fbEventsOnLoad
          : dataFromOutsideBuilder.fbEventsOnLoad,
        fb_events_onsubmit: !isFromOutsideBuilder
          ? state.fbEventsOnSubmit
          : dataFromOutsideBuilder.fbEventsOnSubmit,
        tiktok_pixels: !isFromOutsideBuilder
          ? state.tiktokPixels
          : dataFromOutsideBuilder.tiktokPixels,
        tiktok_events_onload: !isFromOutsideBuilder
          ? state.tiktokEventsOnLoad
          : dataFromOutsideBuilder.tiktokEventsOnLoad,
        tiktok_events_onsubmit: !isFromOutsideBuilder
          ? state.tiktokEventsOnSubmit
          : dataFromOutsideBuilder.tiktokEventsOnSubmit,
        gtm: !isFromOutsideBuilder
          ? state.tagManager
          : dataFromOutsideBuilder.tagManager,
        meta_title: !isFromOutsideBuilder
          ? state.seoTitle
          : dataFromOutsideBuilder.seoTitle,
        meta_description: !isFromOutsideBuilder
          ? state.seoDescription
          : dataFromOutsideBuilder.seoDescription,
        meta_thumbnail: !isFromOutsideBuilder
          ? state.seoThumbnail
          : dataFromOutsideBuilder.seoThumbnail,
        form_display: {
          logo: !isFromOutsideBuilder
            ? state.logo
            : dataFromOutsideBuilder.logo,
          title: !isFromOutsideBuilder
            ? state.title
            : dataFromOutsideBuilder.title,
          subtitle: !isFromOutsideBuilder
            ? state.subtitle
            : dataFromOutsideBuilder.subtitle,
          sidebar_position: !isFromOutsideBuilder
            ? state.sidebarPosition
            : dataFromOutsideBuilder.sidebarPosition,
          sidebar_img: !isFromOutsideBuilder
            ? state.sidebarImg
            : dataFromOutsideBuilder.sidebarImg,
          font_style: !isFromOutsideBuilder
            ? state.fontStyl
            : dataFromOutsideBuilder.fontStyle,
          font_size: !isFromOutsideBuilder
            ? state.fontSize
            : dataFromOutsideBuilder.fontSize,
          background_color: !isFromOutsideBuilder
            ? state.backgroundColor
            : dataFromOutsideBuilder.backgroundColor,
          is_show_banner: !isFromOutsideBuilder
            ? state.isShowBanner
            : dataFromOutsideBuilder.isShowBanner,
          name_label: !isFromOutsideBuilder
            ? state.nameLabel
            : dataFromOutsideBuilder.nameLabel,
          name_placeholder: !isFromOutsideBuilder
            ? state.namePlaceholder
            : dataFromOutsideBuilder.namePlaceholder,
          phone_label: !isFromOutsideBuilder
            ? state.phoneLabel
            : dataFromOutsideBuilder.phoneLabel,
          phone_placeholder: !isFromOutsideBuilder
            ? state.phonePlaceholder
            : dataFromOutsideBuilder.phonePlaceholder,
          email_label: !isFromOutsideBuilder
            ? state.emailLabel
            : dataFromOutsideBuilder.emailLabel,
          email_placeholder: !isFromOutsideBuilder
            ? state.emailPlaceholder
            : dataFromOutsideBuilder.emailPlaceholder,
          is_email: !isFromOutsideBuilder
            ? state.isEmailInputShown
            : dataFromOutsideBuilder.isEmailInputShown,
          is_email_required: !isFromOutsideBuilder
            ? state.isEmailRequired
            : dataFromOutsideBuilder.isEmailRequired,
          address_label: !isFromOutsideBuilder
            ? state.addressLabel
            : dataFromOutsideBuilder.addressLabel,
          address_placeholder: !isFromOutsideBuilder
            ? state.addressPlaceholder
            : dataFromOutsideBuilder.addressPlaceholder,
          notes_label: !isFromOutsideBuilder
            ? state.notesLabel
            : dataFromOutsideBuilder.notesLabel,
          notes_placeholder: !isFromOutsideBuilder
            ? state.notesPlaceholder
            : dataFromOutsideBuilder.notesPlaceholder,
          items_title: !isFromOutsideBuilder
            ? state.bundleTitle
            : dataFromOutsideBuilder.bundleTitle,
          form_title: !isFromOutsideBuilder
            ? state.formTitle
            : dataFromOutsideBuilder.formTitle,
          button_text: !isFromOutsideBuilder
            ? state.buttonText
            : dataFromOutsideBuilder.buttonText,
          button_font_size: !isFromOutsideBuilder
            ? state.buttonFontSize
            : dataFromOutsideBuilder.buttonFontSize,
          button_color: !isFromOutsideBuilder
            ? state.buttonColor
            : dataFromOutsideBuilder.buttonColor,
          is_button_icon: !isFromOutsideBuilder
            ? state.isButtonIcon
            : dataFromOutsideBuilder.isButtonIcon,
          button_icon: !isFromOutsideBuilder
            ? state.buttonIcon
            : dataFromOutsideBuilder.buttonIcon,
          sidebar_description: !isFromOutsideBuilder
            ? state.sidebarDescription
            : dataFromOutsideBuilder.sidebarDescription,
          main_img: !isFromOutsideBuilder
            ? state.img
            : dataFromOutsideBuilder.img,
          main_video: !isFromOutsideBuilder
            ? state.video
            : dataFromOutsideBuilder.video,
          main_description: !isFromOutsideBuilder
            ? state.description
            : dataFromOutsideBuilder.description,
          location_type: !isFromOutsideBuilder
            ? state.locationType
            : dataFromOutsideBuilder.locationType,
          is_address: !isFromOutsideBuilder
            ? state.isAddressInputShown
            : dataFromOutsideBuilder.isAddressInputShown,
          is_notes: !isFromOutsideBuilder
            ? state.isNotesInputShown
            : dataFromOutsideBuilder.isNotesInputShown,
          is_items_shown: !isFromOutsideBuilder
            ? state.isItemsShown
            : dataFromOutsideBuilder.isItemsShown,
          is_items_required: true,
          is_location_required: !isFromOutsideBuilder
            ? state.isLocationRequired
            : dataFromOutsideBuilder.isLocationRequired,
          is_address_required: !isFromOutsideBuilder
            ? state.isAddressRequired
            : dataFromOutsideBuilder.isAddressRequired,
          is_notes_required: !isFromOutsideBuilder
            ? state.isNotesRequired
            : dataFromOutsideBuilder.isNotesRequired,
          items_type: !isFromOutsideBuilder
            ? state.itemsType
            : dataFromOutsideBuilder.itemsType,
          items_list_type: !isFromOutsideBuilder
            ? state.bundleType
            : dataFromOutsideBuilder.bundleType,
          is_items_price_shown: !isFromOutsideBuilder
            ? state.isBundlePriceShown
            : dataFromOutsideBuilder.isBundlePriceShown,
          sidebar_content_position: !isFromOutsideBuilder
            ? state.sidebarContentPosition
            : dataFromOutsideBuilder.sidebarContentPosition,
          main_content_position: !isFromOutsideBuilder
            ? state.contentPosition
            : dataFromOutsideBuilder.contentPosition,
          form_position: !isFromOutsideBuilder
            ? state.formPosition
            : dataFromOutsideBuilder.formPosition,
          bundles: !isFromOutsideBuilder
            ? state.bundle
            : dataFromOutsideBuilder.bundle,
          variants: !isFromOutsideBuilder
            ? state.variant
            : dataFromOutsideBuilder.variant,
          courier_label: !isFromOutsideBuilder
            ? state.courierLabel
            : dataFromOutsideBuilder.courierLabel,
          is_courier_search: !isFromOutsideBuilder
            ? state.isCourierSearch
            : dataFromOutsideBuilder.isCourierSearch,
          is_courier_shown: !isFromOutsideBuilder
            ? state.isCourierShown
            : dataFromOutsideBuilder.isCourierShown,
          payment_method_label: !isFromOutsideBuilder
            ? state.paymentMethodLabel
            : dataFromOutsideBuilder.paymentMethodLabel,
          summary_label: !isFromOutsideBuilder
            ? state.summaryLabel
            : dataFromOutsideBuilder.summaryLabel,
          is_summary_shown: !isFromOutsideBuilder
            ? state.isSummaryShown
            : dataFromOutsideBuilder.isSummaryShown,
          is_multiple_items: !isFromOutsideBuilder
            ? state.isMultipleItems
            : dataFromOutsideBuilder.isMultipleItems,
          is_upsell: !isFromOutsideBuilder
            ? state.isUpsell
            : dataFromOutsideBuilder.isUpsell,
          upsell_variant: !isFromOutsideBuilder
            ? state.upsellVariant === ''
              ? undefined
              : state.upsellVariant
            : dataFromOutsideBuilder.upsellVariant === ''
            ? undefined
            : dataFromOutsideBuilder.upsellVariant,
          upsell_bundle: !isFromOutsideBuilder
            ? state.upsellBundle === ''
              ? undefined
              : state.upsellBundle
            : dataFromOutsideBuilder.upsellBundle === ''
            ? undefined
            : dataFromOutsideBuilder.upsellVariant,
          upsell_type: !isFromOutsideBuilder
            ? state.upsellType
            : dataFromOutsideBuilder.upsellType,
          upsell_description: !isFromOutsideBuilder
            ? state.upsellDescription
            : dataFromOutsideBuilder.upsellDescription,
          upsell_img: !isFromOutsideBuilder
            ? state.upsellImg
            : dataFromOutsideBuilder.upsellImg,
          is_price_strike: !isFromOutsideBuilder
            ? state.isPriceStrike
            : dataFromOutsideBuilder.isPriceStrike,
          main_img_duration: !isFromOutsideBuilder
            ? state.imgDuration
            : dataFromOutsideBuilder.imgDuration,
          is_main_img_autoslide: !isFromOutsideBuilder
            ? state.isImgAutoslide
            : dataFromOutsideBuilder.isImgAutoslide,
          sidebar_img_duration: !isFromOutsideBuilder
            ? state.sidebarImgDuration
            : dataFromOutsideBuilder.sidebarImgDuration,
          is_sidebar_img_autoslide: !isFromOutsideBuilder
            ? state.isSidebarImgAutoslide
            : dataFromOutsideBuilder.isSidebarImgAutoslide,
          upsell_img_duration: !isFromOutsideBuilder
            ? state.upsellImgDuration
            : dataFromOutsideBuilder.upsellImgDuration,
          is_upsell_img_autoslide: !isFromOutsideBuilder
            ? state.isUpsellImgAutoslide
            : dataFromOutsideBuilder.isUpsellImgAutoslide,
          is_items_images_shown: !isFromOutsideBuilder
            ? state.isItemsImagesShown
            : dataFromOutsideBuilder.isItemsImagesShown
        }
      })
      return resStoreData
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateFormName({ commit, state }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      name: state.formTitleOnSetting,
      is_published: isPublished,
      current_form_display: currentFormDisplay
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdateFormName = await this.$axios.$patch(`/form/${id}/`, body)
      return resUpdateFormName
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateSlug({ commit, state }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      slug: state.slug,
      is_published: isPublished,
      current_form_display: currentFormDisplay
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdateSlug = await this.$axios.$patch(`/form/${id}/`, body)
      return resUpdateSlug
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateSeo({ commit, state }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      is_published: isPublished,
      current_form_display: currentFormDisplay,
      meta_title: state.seoTitle,
      meta_description: state.seoDescription,
      meta_thumbnail: state.seoThumbnail
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdateSlug = await this.$axios.$patch(`/form/${id}/`, body)
      return resUpdateSlug
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateAnalysisPage({ _, state }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      is_published: isPublished,
      current_form_display: currentFormDisplay,
      fb_pixels: state.fbPixels,
      fb_events_onload: state.fbEventsOnLoad,
      tiktok_pixels: state.tiktokPixels,
      tiktok_events_onload: state.tiktokEventsOnLoad,
      gtm: state.tagManager
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdateAnalysis = await this.$axios.$patch(`/form/${id}/`, body)
      return resUpdateAnalysis
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateAnalysisForm({ _, state }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      is_published: isPublished,
      current_form_display: currentFormDisplay,
      fb_events_onsubmit: state.fbEventsOnSubmit,
      tiktok_events_onsubmit: state.tiktokEventsOnSubmit
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdateAnalysis = await this.$axios.$patch(`/form/${id}/`, body)
      return resUpdateAnalysis
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateChatTemplate({ commit, state }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      is_published: isPublished,
      current_form_display: currentFormDisplay,
      chat_template: state.chatTemplate
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdateChatTemplate = await this.$axios.$patch(
        `/form/${id}/`,
        body
      )
      return resUpdateChatTemplate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateDefaultAdSource({ commit, state }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      is_published: isPublished,
      current_form_display: currentFormDisplay,
      default_ad_source: state.defaultAdSource
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdateDefaultAdSource = await this.$axios.$patch(
        `/form/${id}/`,
        body
      )
      return resUpdateDefaultAdSource
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateAfterSubmit({ commit, state }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      is_published: isPublished,
      current_form_display: currentFormDisplay,
      after_submit_event: state.afterSubmitEvent,
      other_form: state.otherForm ? state.otherForm : undefined,
      custom_url: state.customUrl
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdateAfterSubmit = await this.$axios.$patch(
        `/form/${id}/`,
        body
      )
      return resUpdateAfterSubmit
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateHandlerAssignment({ commit, state }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      is_published: isPublished,
      current_form_display: currentFormDisplay,
      store_sales_person: state.storeSalesPerson,
      handler_assignment: state.handlerAssignment
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdateHandlerAssignment = await this.$axios.$patch(
        `/form/${id}/`,
        body
      )
      return resUpdateHandlerAssignment
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async changePublished({ commit }, payload) {
    const { id, currentFormDisplay, isPublished } = payload
    const body = {
      is_published: isPublished,
      current_form_display: currentFormDisplay
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resPublish = await this.$axios.$patch(`/form/${id}/`, body)
      return resPublish
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeDataFormDisplay({ _commit, state }, id) {
    try {
      const resStoreDataFormDisplay = await this.$axios.$post(
        `/form/${id}/form-display/`,
        {
          logo: state.logo,
          title: state.title,
          subtitle: state.subtitle,
          sidebar_position: state.sidebarPosition,
          sidebar_img: state.sidebarImg,
          font_style: state.fontStyle,
          font_size: state.fontSize,
          background_color: state.backgroundColor,
          is_show_banner: state.isShowBanner,
          name_label: state.nameLabel,
          name_placeholder: state.namePlaceholder,
          phone_label: state.phoneLabel,
          phone_placeholder: state.phonePlaceholder,
          email_label: state.emailLabel,
          email_placeholder: state.emailPlaceholder,
          is_email: state.isEmailInputShown,
          is_email_required: state.isEmailRequired,
          address_label: state.addressLabel,
          address_placeholder: state.addressPlaceholder,
          notes_label: state.notesLabel,
          notes_placeholder: state.notesPlaceholder,
          items_title: state.bundleTitle,
          form_title: state.formTitle,
          button_text: state.buttonText,
          button_font_size: state.buttonFontSize,
          button_color: state.buttonColor,
          is_button_icon: state.isButtonIcon,
          button_icon: state.buttonIcon,
          sidebar_description: state.sidebarDescription,
          main_img: state.img,
          main_video: state.video,
          main_description: state.description,
          location_type: state.locationType,
          is_address: state.isAddressInputShown,
          is_notes: state.isNotesInputShown,
          is_items_shown: state.isItemsShown,
          is_items_required: true,
          is_location_required: state.isLocationRequired,
          is_address_required: state.isAddressRequired,
          is_notes_required: state.isNotesRequired,
          items_type: state.itemsType,
          items_list_type: state.bundleType,
          is_items_price_shown: state.isBundlePriceShown,
          sidebar_content_position: state.sidebarContentPosition,
          main_content_position: state.contentPosition,
          form_position: state.formPosition,
          bundles: state.bundle,
          variants: state.variant,
          courier_label: state.courierLabel,
          is_courier_search: state.isCourierSearch,
          is_courier_shown: state.isCourierShown,
          payment_method_label: state.paymentMethodLabel,
          summary_label: state.summaryLabel,
          is_summary_shown: state.isSummaryShown,
          is_multiple_items: state.isMultipleItems,
          is_upsell: state.isUpsell,
          upsell_variant:
            state.upsellVariant === '' ? undefined : state.upsellVariant,
          upsell_bundle:
            state.upsellBundle === '' ? undefined : state.upsellBundle,
          upsell_type: state.upsellType,
          upsell_description: state.upsellDescription,
          is_price_strike: state.isPriceStrike,
          upsell_img: state.upsellImg,
          main_img_duration: state.imgDuration,
          is_main_img_autoslide: state.isImgAutoslide,
          sidebar_img_duration: state.sidebarImgDuration,
          is_sidebar_img_autoslide: state.isSidebarImgAutoslide,
          upsell_img_duration: state.upsellImgDuration,
          is_upsell_img_autoslide: state.isUpsellImgAutoslide,
          is_items_images_shown: state.isItemsImagesShown
        }
      )
      return resStoreDataFormDisplay
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit, state }, payload) {
    const { id, isPublished, currentFormDisplay } = payload
    const body = {
      name: state.formTitleOnSetting,
      slug: state.slug,
      is_published: isPublished,
      store: state.storeId,
      ad_source: 1,
      fb_pixels: state.fbPixels,
      fb_events_onload: state.fbEventsOnLoad,
      fb_events_onsubmit: state.fbEventsOnSubmit,
      tiktok_pixels: state.tiktokPixels,
      tiktok_events_onload: state.tiktokEventsOnLoad,
      tiktok_events_onsubmit: state.tiktokEventsOnSubmit,
      gtm: state.tagManager,
      meta_title: state.seoTitle,
      meta_description: state.seoDescription,
      meta_thumbnail: state.seoThumbnail,
      current_form_display: currentFormDisplay,
      chat_template: state.chatTemplate,
      default_ad_source: state.defaultAdSource,
      after_submit_event: state.afterSubmitEvent,
      store_sales_person: state.storeSalesPerson
        ? state.storeSalesPerson
        : undefined,
      other_form: state.otherForm ? state.otherForm : undefined,
      custom_url: state.custom_url,
      handler_assignment: state.handlerAssignment
    }
    if (!isPublished) {
      delete body.current_form_display
    }
    try {
      const resUpdate = await this.$axios.$put(`/form/${id}/`, body)
      return resUpdate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadImg({ commit }, image) {
    try {
      const formData = new FormData()
      formData.append('image', image)
      const resUploadImg = await this.$axios.$post(
        '/form-main-image/',
        formData
      )
      return resUploadImg
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadSidebarImg({ commit }, image) {
    try {
      const formData = new FormData()
      formData.append('image', image)
      const resUploadSidebarImg = await this.$axios.$post(
        '/form-sidebar-image/',
        formData
      )
      return resUploadSidebarImg
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadLogo({ commit }, logo) {
    try {
      const formData = new FormData()
      formData.append('image', logo)
      const resUploadLogo = await this.$axios.$post(
        '/form-logo-image/',
        formData
      )
      return resUploadLogo
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async uploadSeoThumbnail({ commit }, thumbnail) {
    try {
      const formData = new FormData()
      formData.append('image', thumbnail)
      const resUploadSeoThumbnail = await this.$axios.$post(
        '/form-thumbnail-image/',
        formData
      )
      return resUploadSeoThumbnail
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit }, id) {
    try {
      await this.$axios.$delete(`/form/${id}/`)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  resetEditor({ commit }) {
    commit('RESET_STATE')
  }
}
