import {
  mapMutationsHelper,
  mapGettersHelper,
  initQueryParams,
  encodeQueryData,
  decodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  products: [],
  metaProducts: {}
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  setProducts({ commit }, products) {
    commit('SET_PRODUCTS', products)
  },

  setMetaProducts({ commit }, metaProducts) {
    commit('SET_META_PRODUCTS', metaProducts)
  },

  async fetchAllByStoreId(
    { commit, state },
    { storeId, query, isFirst = false } = {}
  ) {
    if (isFirst) commit('SET_PRODUCTS', [])
    const { page, pageSize } = state.metaProducts
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/store/${storeId}/product/?${queries}`
    try {
      const resProductList = await this.$axios.$get(url)
      const allProduct = [...state.products, ...resProductList.data.results]
      commit('SET_PRODUCTS', allProduct)
      let newMeta = {
        itemsLength:
          resProductList.data.count || resProductList.data.results.length
      }
      const nextMeta = resProductList.data.next
        ? decodeQueryData(resProductList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_PRODUCTS', newMeta)
      return { totalCount: resProductList.data.count, currentData: allProduct }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
