//
//
//
//
//
//
//
//
//
//
//
//

import { CurrencyDirective } from 'vue-currency-input'

export default {
  name: 'CurrencyField',
  directives: {
    currency: CurrencyDirective
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    options: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      numberValue: this.value
    }
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal) {
        this.numberValue = val
        this.$refs.inputRef.$ci.setValue(this.numberValue)
      }
    },
    numberValue(val, oldVal) {
      this.$emit('input', val)
    }
  },
  mounted() {
    this.$refs.inputRef.$ci.setValue(this.numberValue)
  },
  methods: {
    onInput(ev) {
      if (this.options.valueAsInteger) {
        this.numberValue = this.$refs.inputRef.$ci.getValue()
      } else {
        this.numberValue = ev.target.value
      }
    },
    onChange(val) {
      this.$emit('change', val)
    },
    onPaste(ev) {
      this.$emit('paste', ev)
    },
    onBlur(ev) {
      this.$emit('blur', ev)
    }
  }
}
