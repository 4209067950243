import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaStaff: {},
  staff: [],
  invitedStaff: [],
  isHasStaff: true
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { isFirst = false, ...query } = {}) {
    if (isFirst) {
      commit('SET_STAFF', [])
      commit('SET_META_STAFF', {})
    }
    const { page, pageSize } = state.metaStaff
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 20 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `business-user/?${queries}`
    try {
      const resStaffList = await this.$axios.$get(url)
      const allStaffList = [...state.staff, ...resStaffList.data.results]
      commit('SET_STAFF', allStaffList)
      let newMeta = {
        itemsLength: resStaffList.data.count || resStaffList.data.results.length
      }
      const nextMeta = resStaffList.data.next
        ? decodeQueryData(resStaffList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_STAFF', newMeta)
      return { totalCount: resStaffList.data.count, currentData: allStaffList }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async invite(store, payload) {
    try {
      const {
        email,
        businessPhone,
        role,
        accessType,
        stores,
        warehouses,
        financialEntity,
        bankAccountNumber,
        bankAccountHolder
      } = payload
      const resInvitation = await this.$axios.$post('business-user/', {
        email,
        business_phone: businessPhone,
        role,
        access_type: accessType,
        stores,
        warehouses,
        financial_entity: financialEntity,
        bank_account_number: bankAccountNumber,
        bank_account_holder: bankAccountHolder
      })
      return resInvitation.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async fetchById(store, staffId) {
    const url = `business-user/${staffId}/`
    try {
      const resStaffDetail = await this.$axios.$get(url)
      return resStaffDetail.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async update(store, { idStaff, ...payload }) {
    try {
      const {
        role,
        isLocked,
        isRegistering,
        businessPhone,
        accessType,
        stores,
        warehouses,
        financialEntity,
        bankAccountNumber,
        bankAccountHolder
      } = payload
      const resUpdateStaff = await this.$axios.$patch(
        `business-user/${idStaff}/`,
        {
          is_locked: isLocked,
          is_registering: isRegistering,
          business_phone: businessPhone,
          role,
          access_type: accessType,
          stores,
          warehouses,
          financial_entity: financialEntity,
          bank_account_number: bankAccountNumber,
          bank_account_holder: bankAccountHolder
        }
      )
      return resUpdateStaff.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async edit(store, { idStaff, ...payload }) {
    try {
      const {
        role,
        isLocked,
        isRegistering,
        businessPhone,
        accessType,
        stores,
        warehouses,
        financialEntity,
        bankAccountNumber,
        bankAccountHolder
      } = payload
      const resUpdateStaff = await this.$axios.$put(
        `business-user/${idStaff}/`,
        {
          is_locked: isLocked,
          is_registering: isRegistering,
          business_phone: businessPhone,
          role,
          access_type: accessType,
          stores,
          warehouses,
          financial_entity: financialEntity,
          bank_account_number: bankAccountNumber,
          bank_account_holder: bankAccountHolder
        }
      )
      return resUpdateStaff.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async delete(store, idStaff) {
    try {
      const resDeleteStaff = await this.$axios.$delete(
        `business-user/${idStaff}/`
      )
      return resDeleteStaff.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async deleteInvitation(store, { idBusiness, email }) {
    try {
      const resDeleteStaff = await this.$axios.$delete(
        `business/invite/delete/`,
        {
          data: {
            business_id: idBusiness,
            email
          }
        }
      )
      return resDeleteStaff.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async resendInvitation(store, email) {
    try {
      const resResendInvitiation = await this.$axios.$post(
        `business-user/resend-invitation/`,
        {
          email
        }
      )
      return resResendInvitiation.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async cancelInvitation(store, { marketingId, id }) {
    try {
      const cancelInvitation = await this.$axios.$delete(
        `marketing-program/${marketingId}/marketing-member/${id}/`
      )
      return cancelInvitation.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async fetchHasStaff({ commit }) {
    const url = `/business-user/?page=1&page_size=1`
    try {
      const resStaffList = await this.$axios.$get(url)
      // const filteredCheckedStaff = resStaffList.data.results.filter(
      //   (staff) => !staff.is_locked
      // )
      commit('SET_IS_HAS_STAFF', resStaffList.data.count > 1)
      return resStaffList.data.count > 1
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
