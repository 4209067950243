export default async ({ $auth, store, route, error }) => {
  store.dispatch('common/checkNewVer')
  await store.dispatch('permission/setPagePermission', null)
  // Get authorizations for matched routes (with children routes too)
  const authorizationPermission = route.meta.find((meta) => meta?.auth?.role)
  if (authorizationPermission) {
    await store.dispatch(
      'permission/setPagePermission',
      authorizationPermission?.auth?.role
    )
    const hasPermission = await store.dispatch('permission/hasPermission', {
      user: $auth.user,
      permission: authorizationPermission?.auth?.role
    })
    if (hasPermission) {
      return true
    } else {
      return error({
        statusCode: 403,
        message: 'Kamu tidak memiliki hak untuk mengakses halaman ini.'
      })
    }
  } else {
    return true
  }
}
