import { mapMutationsHelper, mapGettersHelper } from '@/utils/helpers.js'

export const state = () => ({
  isEmailNotExist: false,
  isEmailChecking: false,
  emailExistMsg: 'Checking',
  user: null
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  setUser({ commit }, user) {
    commit('SET_USER', user)
  },
  async login({ commit }, payload) {
    const { email, password } = payload
    const url = `/auth/jwt/create/`
    const res = await this.$axios.$post(url, {
      email,
      password
    })
    return res
  },
  async fetchDetail() {
    const url = `/user/me/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async editAccount(store, { fullname, email, wizardState }) {
    try {
      const resEditAccount = await this.$axios.$patch(`/user/me/`, {
        fullname,
        email,
        wizard_state: wizardState
      })
      return resEditAccount
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async deleteAccount(store, payload) {
    try {
      const { password } = payload
      const resDeleteAccount = await this.$axios.$delete('/user/me/', {
        data: {
          password
        }
      })
      return resDeleteAccount
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async newPassword(store, payload) {
    try {
      const { currentPassword, newPassword } = payload
      const resNewPassword = await this.$axios.$post(
        '/user/me/change-password/',
        {
          current_password: currentPassword,
          new_password: newPassword
        }
      )
      return resNewPassword
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async newAvatar(store, avatarFile) {
    try {
      const formData = new FormData()
      formData.append('avatar', avatarFile)
      formData.append('type', 'file')
      const resNewAvatar = await this.$axios.$put(
        `/user/me/upload-avatar/`,
        formData
      )
      return resNewAvatar
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async userDetailFormBuilder(store) {
    const resUserDetail = await this.$prodApi.get('/v1/users/custom_profile/')
    return resUserDetail.data.data.user
  },
  async checkEmail({ commit, state }, email) {
    try {
      commit('SET_IS_EMAIL_CHECKING', true)
      commit('SET_EMAIL_EXIST_MSG', 'Checking')
      const resCheckEmail = await this.$axios.$get(
        `/user-search/check-email/?email=${email}`
      )
      commit('SET_IS_EMAIL_NOT_EXIST', resCheckEmail.data.is_available)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_IS_EMAIL_NOT_EXIST', false)
      throw error
    }
    commit(
      'SET_EMAIL_EXIST_MSG',
      state.isEmailNotExist ? '' : 'Alamat Email sudah terdaftar'
    )
    commit('SET_IS_EMAIL_CHECKING', false)
    return state.isEmailNotExist
  },
  async removeTelegram(_store) {
    try {
      const resEditAccount = await this.$axios.$patch(`/user/me/`, {
        telegram_chat_id: null
      })
      return resEditAccount
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
