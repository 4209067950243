import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaBundle: {},
  bundle: [],
  metaBundlePartner: {},
  bundlePartner: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_META_BUNDLE', {})
      commit('SET_BUNDLE', [])
    }
    const { page, pageSize } = state.metaBundle
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/bundle/?${queries}`
    try {
      const resBundleList = await this.$axios.$get(url)
      const allBundle = [...state.bundle, ...resBundleList.data.results]
      commit('SET_BUNDLE', allBundle)
      let newMeta = {
        itemsLength:
          resBundleList.data.count || resBundleList.data.results.length
      }
      const nextMeta = resBundleList.data.next
        ? decodeQueryData(resBundleList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_BUNDLE', newMeta)
      return { totalCount: resBundleList.data.count, currentData: allBundle }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllSimplified({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_META_BUNDLE', {})
      commit('SET_BUNDLE', [])
    }
    const { page, pageSize } = state.metaBundle
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/bundle/simplified/?${queries}`
    try {
      const resBundleList = await this.$axios.$get(url)
      const allBundle = [...state.bundle, ...resBundleList.data.results]
      commit('SET_BUNDLE', allBundle)
      let newMeta = {
        itemsLength:
          resBundleList.data.count || resBundleList.data.results.length
      }
      const nextMeta = resBundleList.data.next
        ? decodeQueryData(resBundleList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_BUNDLE', newMeta)
      return { totalCount: resBundleList.data.count, currentData: allBundle }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, idBundle) {
    const url = `/bundle/${idBundle}/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async storeData(_, payload) {
    try {
      const {
        name,
        publicName,
        customId,
        description,
        weightBump,
        itemType,
        priceAt,
        bundlelines,
        images,
        labels,
        bundlePriceOptions
      } = payload
      const resAddBundle = await this.$axios.$post('bundle/', {
        name,
        public_name: publicName,
        custom_id: customId === '' ? null : customId,
        description,
        weight_bump: weightBump,
        item_type: itemType,
        price_at: priceAt,
        bundlelines,
        images,
        labels,
        bundle_price_options: bundlePriceOptions
      })
      return resAddBundle.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async storeDataBundleline(_store, payload) {
    try {
      const { bundleId, variant, total, quantity } = payload
      const resAddBundleline = await this.$axios.$post('bundleline/', {
        bundle: bundleId,
        variant,
        total,
        quantity
      })
      return resAddBundleline.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async update(store, payload) {
    try {
      const {
        id,
        name,
        publicName,
        customId,
        description,
        weightBump,
        itemType,
        isBundleSharing,
        bundlelines,
        images,
        labels,
        bundlePriceOptions
      } = payload
      const resUpdateBundle = await this.$axios.put(`bundle/${id}/`, {
        name,
        public_name: publicName,
        custom_id: customId === '' ? null : customId,
        description,
        item_type: itemType,
        weight_bump: weightBump,
        is_bundle_sharing: isBundleSharing,
        bundlelines,
        images,
        labels,
        bundle_price_options: bundlePriceOptions
      })
      return resUpdateBundle.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async patch(store, payload) {
    try {
      const {
        id,
        name,
        publicName,
        customId,
        description,
        weightBump,
        itemType,
        isBundleSharing,
        bundlelines,
        images,
        labels,
        bundlePriceOptions
      } = payload
      const resPatchBundle = await this.$axios.$patch(`bundle/${id}/`, {
        name,
        public_name: publicName,
        custom_id: customId,
        description,
        item_type: itemType,
        weight_bump: weightBump,
        is_bundle_sharing: isBundleSharing,
        bundlelines,
        images,
        labels,
        bundle_price_options: bundlePriceOptions
      })
      return resPatchBundle
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async updateBundleline(store, payload) {
    try {
      const { bundlelineId, price, quantity, bundleId } = payload
      const resUpdateBundleline = await this.$axios.put(
        `bundleline/${bundlelineId}/`,
        {
          price,
          quantity,
          bundle: bundleId
        }
      )
      return resUpdateBundleline.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async delete(store, idBundle) {
    try {
      const resDeleteBundle = await this.$axios.$delete(`bundle/${idBundle}/`)
      return resDeleteBundle.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchRelations({ commit }, idProduct) {
    try {
      const res = await this.$axios.$get(`bundle/${idProduct}/show-relations/`)
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  setBundles({ commit }, payload) {
    commit('SET_BUNDLE', payload)
  },

  setMetaBundles({ commit }, payload) {
    commit('SET_META_BUNDLE', payload)
  },

  // bundle partner

  async fetchAllBundlePartner(
    { commit, state },
    { idBundle, isFirst = false }
  ) {
    if (isFirst) {
      commit('SET_BUNDLE_PARTNER', [])
      commit('SET_META_BUNDLE_PARTNER', {})
    }
    const { page, pageSize } = state.metaBundlePartner
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize
    })
    const queries = encodeQueryData(initQuery)
    const url = `/bundle/${idBundle}/shared/?${queries}`
    try {
      const resBundlePartner = await this.$axios.$get(url)
      const allBundlePartner = [
        ...state.bundlePartner,
        ...resBundlePartner.data.results
      ]
      commit('SET_BUNDLE_PARTNER', allBundlePartner)
      const newMeta = decodeQueryData(
        resBundlePartner.data.next?.split('?')?.[1]
      )
      if (newMeta) {
        commit('SET_META_BUNDLE_PARTNER', {
          itemsLength:
            resBundlePartner.data.count || resBundlePartner.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return resBundlePartner.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Create Bundle Partner
  async createBundlePartner(_, { idBundle, uniqueId }) {
    const url = `bundle/${idBundle}/shared/`
    try {
      const resCreateBundlePartner = await this.$axios.$post(url, {
        partner_unique_id: uniqueId
      })
      return resCreateBundlePartner
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Delete Bundle Partner
  async destroyBundlePartner(_store, { idBundle, idBundlePartner }) {
    try {
      const resDestroyBundlePartner = await this.$axios.$delete(
        `bundle/${idBundle}/shared/${idBundlePartner}/`
      )
      return resDestroyBundlePartner
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  setBundlePartners({ commit }, payload) {
    commit('SET_BUNDLE_PARTNER', payload)
  },

  setMetaBundlePartners({ commit }, payload) {
    commit('SET_META_BUNDLE_PARTNER', payload)
  }
}
