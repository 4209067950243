import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  subscriptionOrders: [],
  metaSubscriptionOrders: {},
  detailSubscription: null,
  pricePlanShow: false,
  changePlanConfirmShow: false
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_SUBSCRIPTION_ORDERS', [])
      commit('SET_META_SUBSCRIPTION_ORDERS', {})
    }

    const { page, pageSize } = state.metaSubscriptionOrders
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/subscription-order/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const data = [...state.subscriptionOrders, ...res.data.results]
      commit('SET_SUBSCRIPTION_ORDERS', data)
      let newMeta = {
        itemsLength: res.data.count || res.data.results.length
      }
      const nextMeta = res.data.next
        ? decodeQueryData(res.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_SUBSCRIPTION_ORDERS', newMeta)
      return { totalCount: res.data.count, currentData: data }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchBySecret({ commit }, secret) {
    const url = `/subscription-order/public/${secret}/`
    try {
      const resPaymentList = await this.$axios.$get(url)
      commit('SET_DETAIL_SUBSCRIPTION', resPaymentList.data)
      return resPaymentList.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchByIdReal({ commit }, subscriptionId) {
    const url = `/subscription-order/${subscriptionId}/`
    try {
      const resPaymentList = await this.$axios.$get(url)
      commit('SET_DETAIL_SUBSCRIPTION', resPaymentList.data)
      return resPaymentList.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, subscriptionId) {
    const url = `${this.$config.apiUrlV2}/business-subscription/${subscriptionId}/`
    try {
      const resPaymentList = await this.$axios.$get(url)
      commit('SET_DETAIL_SUBSCRIPTION', resPaymentList.data)
      return resPaymentList.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData(store, payload) {
    const { pricingPlanCode } = payload
    const url = `${this.$config.apiUrlV2}/business-subscription/`
    try {
      const resPlanUpdate = await this.$axios.$post(url, {
        pricing_plan_code: pricingPlanCode
      })
      return resPlanUpdate.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateSubscription(store, { idSubscription, ...payload }) {
    const { pricingPlanCode } = payload
    const url = `${this.$config.apiUrlV2}/business-subscription/${idSubscription}/`
    try {
      const resPlanUpdate = await this.$axios.$put(url, {
        pricing_plan_code: pricingPlanCode
      })
      return resPlanUpdate.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async partialUpdateSubscription(_store, { idSubscription, ...payload }) {
    const { pricingPlanCode } = payload
    const url = `${this.$config.apiUrlV2}/business-subscription/${idSubscription}/`
    try {
      const resPlanUpdate = await this.$axios.$patch(url, {
        pricing_plan_code: pricingPlanCode
      })
      return resPlanUpdate.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async setPaymentMethodToCard(store) {
    const url = `/stripe/initiate-update-card/`
    try {
      const resPaymentMethodUpdate = await this.$axios.$post(url)
      return resPaymentMethodUpdate.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  setChangePlanConfirmShow({ commit }, payload) {
    commit('SET_CHANGE_PLAN_CONFIRM_SHOW', payload)
  },
  setPricePlanShow({ commit }, payload) {
    commit('SET_PRICE_PLAN_SHOW', payload)
  },
  checkSubscriptionMiddleware({ dispatch }, userDetail) {
    if (
      (!userDetail?.current_business_subscription &&
        !userDetail.current_business_subscription?.current_pricing_plan) ||
      !['active', 'trialing', 'past_due'].includes(
        userDetail.current_business_subscription.status
      )
    ) {
      dispatch('setPricePlanShow', true)
    }
  },
  clearDetailSubscription({ commit }) {
    commit('SET_DETAIL_SUBSCRIPTION', null)
  }
}
