// import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import {
  mapMutationsHelper,
  mapGettersHelper,
  encodeQueryData,
  decodeQueryData,
  initQueryParams
} from '@/utils/helpers.js'

export const state = () => ({
  metaRoles: {},
  roles: [],
  rawTemplate: [],
  template: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchTemplate({ commit }) {
    const url = `/role-permission-template/`
    try {
      const resTemplate = await this.$axios.$get(url)
      commit('SET_RAW_TEMPLATE', resTemplate.data.results[0].permissions)
      const formatTemplate = map(
        resTemplate.data.results[0].permissions,
        (businessRoleMaf, key) => {
          return {
            name: startCase(key),
            code: key,
            model_action_filter_template: map(
              businessRoleMaf,
              (model, key) => ({
                action_options: map(model, (perm, i) => ({
                  codename: perm,
                  name: startCase(perm)
                })),
                business_role: {
                  code: key,
                  name: key === 'retailer' ? 'Seller' : startCase(key)
                }
              })
            )
          }
        }
      )
      commit('SET_TEMPLATE', formatTemplate)
      return formatTemplate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchAll({ commit, state }, query) {
    const { page, pageSize, itemsLength } = state.metaRoles
    if (itemsLength === state.roles?.length) {
      return state.roles
    }
    const initQuery = initQueryParams({
      page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/role/?${queries}`
    try {
      const resRoles = await this.$axios.$get(url)
      const allRoles = [...state.roles, ...resRoles.data.results]
      commit('SET_ROLES', allRoles)
      const newMeta = decodeQueryData(resRoles.data.next?.split('?')?.[1])
      if (newMeta) {
        commit('SET_META_ROLES', {
          itemsLength: resRoles.data.count || resRoles.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return allRoles
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchById(store, roleId) {
    const url = `role/${roleId}/`
    try {
      const resRoleDetail = await this.$axios.$get(url)
      return resRoleDetail.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async storeData(store, payload) {
    try {
      const { name, phone, rolePermission, description } = payload
      const resAddRole = await this.$axios.$post('role/', {
        name,
        phone,
        description,
        role_permission: rolePermission
      })
      return resAddRole
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async updateData(store, payload) {
    try {
      const { roleId, name, rolePermission, description } = payload
      const resEditRole = await this.$axios.$put(`role/${roleId}/`, {
        name,
        description,
        role_permission: rolePermission
      })
      return resEditRole
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async delete(store, roleId) {
    try {
      const resDeleteRole = await this.$axios.$delete(`role/${roleId}/`)
      return resDeleteRole
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
