//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      instance: null
    }
  },
  watch: {
    async icon(val) {
      if (val) {
        try {
          this.instance = await import(`~/assets/icons/${val}.svg?inline`)
        } catch (error) {
          this.instance = null
        }
      }
    }
  },
  async beforeCreate() {
    try {
      this.instance = await import(
        `~/assets/icons/${this.$options.propsData.icon}.svg?inline`
      )
    } catch (error) {
      this.instance = null
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
