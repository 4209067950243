var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inset-x-0 bottom-0 z-[100] border-t-2 border-gray-400 text-[20px] text-gray-800 shadow md:hidden",attrs:{"id":"navbar-mobile"}},[_c('MobileNavbarMore',{staticStyle:{"z-index":"-1"},attrs:{"menu":_vm.moreMenu},on:{"menu-click":_vm.onNavigateMore},model:{value:(_vm.isMoreShow),callback:function ($$v) {_vm.isMoreShow=$$v},expression:"isMoreShow"}}),_vm._v(" "),_c('ul',{staticClass:"z-[100] flex h-[56px] list-none items-center justify-between bg-white"},[_vm._l((_vm.mainMenu),function(menu,index){return [(menu.hasPermission)?_c('NuxtLink',{key:("mobile-nav-menu-" + index),attrs:{"to":menu.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('li',{staticClass:"flex flex-1 justify-center"},[_c('SclvButton',{staticClass:"flex h-[38px] w-full min-w-[38px] flex-col items-center justify-center rounded-full !px-0",class:[
              (isActive ||
                (menu.otherActive &&
                  menu.otherActive.includes(
                    ("/" + (_vm.$route.path.substring(1).split('/')[0]))
                  ))) &&
                'text-primary'
            ],attrs:{"text":"","color":"bg-transparent"},on:{"click":function($event){menu.onClick ? menu.onClick() : _vm.route(menu.link)}}},[_c('SclvIcon',{staticClass:"mb-[2px]",class:{ 'text-[18px]': menu.name === 'Affiliate' },attrs:{"icon":menu.icon}}),_vm._v(" "),_c('span',{staticClass:"text-[10px]",class:[
                (isActive ||
                  (menu.otherActive &&
                    menu.otherActive.includes(
                      ("/" + (_vm.$route.path.substring(1).split('/')[0]))
                    ))) &&
                  'text-primary'
              ]},[_vm._v("\n              "+_vm._s(menu.name)+"\n            ")])],1)],1)]}}],null,true)}):_vm._e()]}),_vm._v(" "),(_vm.moreMenu.length > 0)?_c('div',{staticClass:"flex flex-1 justify-center"},[_c('SclvButton',{staticClass:"flex h-[38px] w-full min-w-[38px] flex-col items-center justify-center rounded-full !px-0",class:[_vm.isMoreShow && 'text-primary'],attrs:{"text":"","color":"bg-transparent"},on:{"click":function($event){_vm.isMoreShow = !_vm.isMoreShow}}},[_c('SclvIcon',{staticClass:"mb-[2px]",attrs:{"icon":"dots-horizontal-sclvico"}}),_vm._v(" "),_c('span',{staticClass:"text-[10px]",class:[_vm.isMoreShow && 'text-primary']},[_vm._v("\n          Menu Lain\n        ")])],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }