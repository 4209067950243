import Vue from 'vue'

const disabledPages = [].map((x) => new RegExp(x))

Vue.mixin({
  beforeRouteLeave(to, from, next) {
    if (disabledPages.find((x) => x.test(to.path))) {
      this.$store.dispatch('common/setIsRouterLoadingEnable', false)
    }
    next()
  }
})
