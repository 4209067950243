//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avatar from 'vue-avatar'
import { ValidationProvider, validate } from 'vee-validate'
import { fileToUrl } from '@/utils/helpers.js'

export default {
  components: {
    Avatar,
    ValidationProvider
  },
  props: {
    value: {
      type: [() => String],
      default: null
    },
    username: {
      type: String,
      default: ''
    },
    changeable: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: null
    },
    immediate: {
      type: Boolean,
      default: false
    },
    hideMessage: {
      type: Boolean,
      default: false
    },
    autoHideMessage: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      size: 50,
      img: this.src || this.value
    }
  },
  computed: {
    tempValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    src(val) {
      this.img = val
    }
  },
  mounted() {
    setTimeout(() => {
      this.size = this.$el.clientHeight
    })
    if (this.changeable) {
      this.$refs.provider.initialValue = this.value
      this.$refs.provider.value = this.value
      if (this.$refs.provider.isRequired) {
        validate(this.value, this.rules).then((result) => {
          this.$refs.provider.setFlags({
            valid: result.valid,
            invalid: !result.valid
          })
          // this.$refs.provider.setErrors(result.errors)
        })
      } else {
        // this.$refs.provider.setErrors([])
        this.$refs.provider.setFlags({ valid: true, invalid: false })
      }
    }
  },
  methods: {
    initialParser(username, defaultParser) {
      let defaultInitial = defaultParser(username)
      if (defaultInitial.length > 2) {
        defaultInitial = `${defaultInitial[0]}${defaultInitial[1]}`
      }
      return defaultInitial
    },
    changeAvatar() {
      this.$refs.fileInput.click()
    },
    async onFileChange(ev) {
      const { valid } = await this.$refs.provider.validate(ev)
      if (valid) {
        this.tempValue = ev.target.files[0]
        this.img = fileToUrl(ev.target.files[0]).url
        this.$refs.fileInput.value = null
      }
    }
  }
}
