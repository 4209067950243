//
//
//
//

export default {
  name: 'Default',
  // watch: {
  //   $route(to, from) {
  //     const refreshToken = localStorage.getItem('auth._refresh.customLocal')
  //     if (!refreshToken || refreshToken === 'false') {
  //       location.reload()
  //     }
  //   }
  // },
  mounted() {
    // window.addEventListener('storage', (event) => {
    //   if (event.storageArea !== localStorage) return
    //   if (event.key === 'auth._refresh.customLocal') {
    //     location.reload()
    //   }
    // })
  }
}
